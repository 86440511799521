import { combineReducers } from 'redux';
import { routerReducer as router } from 'react-router-redux';

import ui from './ui';
import company from './company';
import apiKeys from './api-keys';
import alerts from './alerts';
import auth from './auth';
import user from './user';
import library from './library';
import libraryItems from './library-items';
import libraryGroups from './library-groups';
import taskManagement from './task-management';
import notification from './notification';
import threats from './threat';
import roles from './role';
import groups from './group';
import reports from './reports';
import helps from './help';
import todo from './todo';
import plan from './plan';
import teams from './teams';
import planTemplate from './plan-template';
import planTemplateItems from './plan-template-items';
import dashboard from './dashboard';
import approvalHistory from './approval-history';

const rootReducer = combineReducers({
	ui,
	company,
	apiKeys,
	alerts,
	auth,
	user,
	library,
	libraryItems,
	libraryGroups,
	taskManagement,
	notification,
	threats,
	roles,
	groups,
	helps,
	reports,
	todo,
	plan,
	teams,
	router,
	planTemplate,
	dashboard,
	planTemplateItems,
	approvalHistory,
});

export default rootReducer;
