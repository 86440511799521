import React from 'react';
import PropTypes from 'prop-types';

import Flex, { Col } from '../../Flex';

import classNames from 'classnames';
import cs from './component.module.scss';

class Input extends React.Component {
	timerHandle = null;

	shouldComponentUpdate(nextProps, nextState) {
		if (this.props.value !== nextProps.value) {
			return true;
		}

		if (this.props.field && this.props.field.name && this.props.field.name !== nextProps.field.name) {
			return true;
		}

		return false;
	}

	onChange = (e) => {
		const { name, value } = e.target;
		this.props.onChange(value, name);

		if (typeof this.props.autoTrigger !== 'undefined' && this.props.autoTrigger && this.props.autoTrigger.cb) {
			this.doAutoTrigger(value);
		}
	};

	onKeyPress = (e) => {
		const code = e.which;
		if (code === 13 && e.target.value !== '') {
			this.props.onKeyPress(e);
		}
	};

	doAutoTrigger = (value) => {
		if (this.timerHandle) {
			clearTimeout(this.timerHandle);
			this.timerHandle = null;
		}

		this.doDelayedAutoTrigger(value);
	};

	doDelayedAutoTrigger(value) {
		let lengthToCheck = (this.props.autoTrigger && this.props.autoTrigger.length) || 3;
		if (value.length >= lengthToCheck || value.length === 0) {
			this.timerHandle = setTimeout(() => {
				this.timerHandle = null;
				this.props.autoTrigger.cb.call(undefined, value);
			}, this.props.autoTrigger.delay || 1000);
		}
	}

	render() {
		const { field, value, placeholder, type, readonly, bordered, lg, addon, style } = this.props;

		// console.log("input text being rendered => ",field);
		return (
			<Flex xs={{ align: 'center', height: '100%' }}>
				<Col xs={{ size: addon ? 9 : 12 }}>
					<input
						style={style}
						className={classNames(cs.root, {
							[cs.bordered]: bordered,
							[cs.invalid]: this.props.invalid,
							[cs.lg]: lg,
							[cs.readonly]: readonly,
							[cs.noRightRadius]: addon,
						})}
						name={field.name}
						value={value}
						placeholder={placeholder}
						type={type}
						readOnly={readonly}
						autoComplete={this.props.autoComplete}
						onFocus={this.props.onFocus}
						onBlur={this.props.onBlur}
						onChange={this.onChange}
						onKeyDown={(e) => {
							if (e.which === 13) {
								this.onKeyPress(e);
							} else {
								this.onChange(e);
							}
						}}
					/>
				</Col>
				{!!addon && (
					<Col xs={{ size: 3 }}>
						<div className={cs.inputAddon}>
							<span className="input-group-text">{addon}</span>
						</div>
					</Col>
				)}
			</Flex>
		);
	}
}

Input.propTypes = {
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
	field: PropTypes.shape({
		name: PropTypes.string,
	}),
	style: PropTypes.shape({
		height: PropTypes.string,
	}),
	type: PropTypes.string,
	placeholder: PropTypes.string,
	invalid: PropTypes.string,
	readonly: PropTypes.bool,
	bordered: PropTypes.bool,
	lg: PropTypes.bool,
	addon: PropTypes.string,
	onChange: PropTypes.func,
	onKeyPress: PropTypes.func,
	autoTrigger: PropTypes.shape({
		length: PropTypes.number,
		delay: PropTypes.number,
		cb: PropTypes.func,
	}),
};

Input.defaultProps = {
	placeholder: '',
	type: 'text',
	invalid: null,
	readonly: false,
	bordered: false,
	style: {},
	lg: false,
	field: {
		name: '',
		type: 'String',
	},
	addon: null,
	onChange: () => false,
	onKeyPress: () => false,
	autoTrigger: null,
};

export default Input;
