import React from 'react';
import PropTypes from 'prop-types';
import { List } from 'immutable';
import Icon from 'react-fa';

import { Tab, TabList, TabPanel } from 'react-tabs';
import { Text, Flex, Col, Padding, Tabs, DropDown } from '../';
import { DataList } from '../Data';
import { Input, Textarea, Select2, Button, ButtonLink, Checkbox, Editor, UploadInput } from '../Form';

class SelectionList extends React.PureComponent {
	state = {
		searchForm: {
			search: '',
			searchA: '',
		},
		isChecked: false,
		checkedItems: [],

		assigned: new List(),
		available: new List(),
		totalAvailable: 0,
		totalAssigned: 0,
		limitA: 50,
		offsetA: 0,
		limitAS: 50,
		offsetAS: 0,
		individualSearchAvailable: false,
		individualSearchAssigned: false,
	};

	componentDidMount() {
		let toUpdate = {};

		if (typeof this.props.totalAvailable !== 'undefined') {
			toUpdate.totalAvailable = this.props.totalAvailable;
		}
		if (typeof this.props.totalAssigned !== 'undefined') {
			toUpdate.totalAssigned = this.props.totalAssigned;
		}
		if (typeof this.props.limitA !== 'undefined') {
			toUpdate.limitA = this.props.limitA;
		}
		if (typeof this.props.limitAS !== 'undefined') {
			toUpdate.limitAS = this.props.limitAS;
		}

		if (Object.keys(toUpdate).length > 0) {
			this.setState(toUpdate);
		}
	}

	componentWillReceiveProps(nextProps) {
		// console.log('nextProps-> ',nextProps);
		let toUpdate = {};

		if (typeof nextProps.totalAvailable !== 'undefined') {
			toUpdate.totalAvailable = nextProps.totalAvailable;
		}
		if (typeof nextProps.totalAssigned !== 'undefined') {
			toUpdate.totalAssigned = nextProps.totalAssigned;
		}
		if (typeof nextProps.limitA !== 'undefined') {
			toUpdate.limitA = nextProps.limitA;
		}
		if (typeof nextProps.limitAS !== 'undefined') {
			toUpdate.limitAS = nextProps.limitAS;
		}

		if (Object.keys(toUpdate).length > 0) {
			this.setState(toUpdate);
		}
	}

	onPaginate = (page, limit) => {
		//assigned
		this.setState(
			{
				offsetAS: (page - 1) * limit,
				limitAS: limit,
			},
			() => {
				this.props.searchAssigned(this.state);
				//this.getAssignedRecord({ libraryId: this.state.form.libraryId, });
			},
		);
	};

	onPaginateAvailable = (page, limit) => {
		//available
		this.setState(
			{
				offsetA: (page - 1) * limit,
				limitA: limit,
			},
			() => {
				this.props.searchAvailable(this.state);
				//this.getAvailableRecord({ libraryId: this.state.form.libraryId, });
			},
		);
	};

	toggleIndividualSearchAvailable() {
		this.setState({ individualSearchAvailable: !this.state.individualSearchAvailable });
	}

	toggleIndividualSearchAssigned() {
		this.setState({ individualSearchAssigned: !this.state.individualSearchAssigned });
	}

	render() {
		const {
			searchAssigned,
			searchAvailable,
			assignedData,
			availableData,
			assignedRowRender,
			availableRowRender,
			onTabChange,
			tabIndex,
			extraContent = null,
			fields,
			onReorder,
			individualSearchAvailableCallback = null,
			individualSearchAssignedCallback = null,
			individualSearchFieldsAvailable = {},
			individualSearchFieldsAssigned = {},
		} = this.props;

		if (!assignedData && !availableData) {
			return null;
		}

		return (
			<Flex>
				<Col xs={{ size: 12, top: 30 }}>
					<Tabs selectedIndex={tabIndex} onSelect={onTabChange}>
						<TabList>
							<Tab>Selected Items</Tab>
							<Tab>Choose Items</Tab>
						</TabList>
						<TabPanel>
							<Flex xs={{ justify: 'flex-end' }}>
								<Icon style={{ fontSize: '28px', cursor: 'pointer' }} onClick={() => this.toggleIndividualSearchAvailable()} name="search" />
								{extraContent}
							</Flex>
							<DataList
								data={assignedData}
								isLoaded={this.props.isLoaded || true}
								fields={fields}
								rowRender={assignedRowRender}
								limit={this.state.limitAS}
								onReorder={onReorder}
								individualSearch={this.state.individualSearchAssigned}
								individualSearchFields={individualSearchFieldsAssigned}
								individualSearchCallback={individualSearchAssignedCallback}
								pagination={{
									dataCount: this.state.totalAssigned,
									onPaginate: this.onPaginate,
								}}
							/>
						</TabPanel>
						<TabPanel>
							<Flex xs={{ justify: 'flex-end' }}>
								<Icon style={{ fontSize: '28px', cursor: 'pointer' }} onClick={() => this.toggleIndividualSearchAvailable()} name="search" />
								{extraContent}
							</Flex>
							<DataList
								data={availableData}
								isLoaded={this.props.isLoaded || true}
								fields={fields}
								onReorder={onReorder}
								rowRender={availableRowRender}
								limit={this.state.limitA}
								individualSearch={this.state.individualSearchAvailable}
								individualSearchFields={individualSearchFieldsAvailable}
								individualSearchCallback={individualSearchAvailableCallback}
								pagination={{
									dataCount: this.state.totalAvailable,
									onPaginate: this.onPaginateAvailable,
								}}
							/>
						</TabPanel>
					</Tabs>
				</Col>
			</Flex>
		);
	}
}

SelectionList.propTypes = {
	children: PropTypes.node,
	className: PropTypes.string,
};

SelectionList.defaultProps = {
	className: '',
};

export default SelectionList;
