import createAction from '../utils/createAction';
import internalApi from '../utils/internalApi';
import storage from '../utils/storage';

import { clearAlerts } from './alerts';
import _ from 'lodash';

export const AUTHENTICATE = 'AUTHENTICATE';
export const CLEAR_TOKEN = 'CLEAR_TOKEN';
export const FETCH_LOGIN_HISTORY_LOADED = 'FETCH_LOGIN_HISTORY_LOADED';
export const GET_LOGIN_HISTORY = 'GET_LOGIN_HISTORY';

const makeLoginRequestApi = (authData) => {
	return internalApi.post(
		{
			server: 'auth',
			path: '/authenticate',
		},
		authData,
	);
};

const signIn = (authData, routerHistory) => (dispatch) => {
	dispatch(clearAlerts());

	makeLoginRequestApi(authData)
		.then((res) => {
			const { token = null, expired = false, passwordResetUrl = '', pinRequired = false } = res.payload.item;

			if (pinRequired) {
				return { pinRequired: true };
			}

			if (expired) {
				top.location = passwordResetUrl;
				return;
			}

			storage.set('token', token);
			dispatch(createAction(AUTHENTICATE, res.payload.item));

			if (routerHistory) {
				routerHistory.push('/dashboard');
			}
		})
		.catch((err) => {
			console.log('err ', err);
		});
};

const signInByExternalToken = (token, routerHistory) => (dispatch) => {
	dispatch(clearAlerts());

	storage.set('token', token);
	dispatch(createAction(AUTHENTICATE, { token }));

	if (routerHistory) {
		let fromUrl = localStorage?.getItem?.('fromUrl');
		if (fromUrl) {
			localStorage.removeItem('fromUrl');
			routerHistory.push(JSON.parse(fromUrl));
		} else {
			routerHistory.push('/dashboard');
		}
	}
};

const clearToken = () => createAction(CLEAR_TOKEN);

const getLoginHistory =
	({ order = {}, limit, offset, search = '', individualSearchFields = [] } = {}) =>
	(dispatch) => {
		dispatch(createAction(FETCH_LOGIN_HISTORY_LOADED));

		const queryParams = {
			search,
			orderBy: order.key,
			sortBy: order.in,
			limit,
			offset,
			individualSearchFields,
		};

		internalApi.get('user/login-history', { params: queryParams }).then((res) => {
			dispatch(createAction(GET_LOGIN_HISTORY, res.payload.items));
		});
	};

const deleteLoginHistory = (ids) => (dispatch) => {
	dispatch(clearAlerts());

	return internalApi.post('user/login-history', {
		ids,
	});
};

const signUp = (data) => (dispatch) => {
	dispatch(clearAlerts());

	return internalApi.post(
		{
			server: 'auth',
			path: '/register',
		},
		_.omit(data, 'confirmPassword'),
	);
};

const timeBasedSignIn = (timeBasedToken) => {
	return internalApi.post(
		{
			server: 'auth',
			path: '/ttAuth',
		},
		{ tt: timeBasedToken },
	);
};

export { signIn, makeLoginRequestApi, signUp, clearToken, getLoginHistory, deleteLoginHistory, signInByExternalToken, timeBasedSignIn };
