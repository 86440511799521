import _ from 'lodash';
import createAction from '../utils/createAction';
import internalApi from '../utils/internalApi';
import { clearAlerts } from './alerts';
import exportCSVFile from '../utils/exportCSVFile';

export const FETCH_THREATS_LOADED = 'FETCH_THREATS_LOADED';
export const FETCH_THREATS = 'FETCH_THREATS';
export const FETCH_THREAT_GROUPS_LOADED = 'FETCH_THREAT_GROUPS_LOADED';
export const FETCH_THREAT_GROUPS = 'FETCH_THREAT_GROUPS';

const fetchThreats =
	({ order = {}, name, limit = 1000, offset = 0, individualSearchFields = [] } = {}) =>
	(dispatch) => {
		dispatch(createAction(FETCH_THREATS_LOADED));

		const queryParams = {
			orderBy: order.key,
			sortBy: order.in,
			name,
			limit,
			offset,
			individualSearchFields,
		};

		internalApi.get('threat/list', { params: queryParams }).then((res) => {
			dispatch(createAction(FETCH_THREATS, res.payload.items));
		});
	};

const getRiskThreats = (riskId, version = '') => {
	return internalApi.get(`threat/list/${riskId}?v=${version}`);
};

const fetchThreatGroups =
	({ offset = 0, limit = 1000, name = '', individualSearchFields = [], order = {} } = {}) =>
	(dispatch) => {
		dispatch(createAction(FETCH_THREAT_GROUPS_LOADED));
		const queryParams = {
			offset,
			limit,
			name,
			orderBy: order.key,
			sortBy: order.in,
			individualSearchFields,
		};
		internalApi.get('threat/threat-groups/list', { params: queryParams }).then((res) => {
			dispatch(createAction(FETCH_THREAT_GROUPS, res.payload.items));
		});
	};

const getThreatGroups = ({ offset = 0, limit = 1000, name = '', individualSearchFields = [], order = {} } = {}) => {
	const queryParams = {
		offset,
		limit,
		name,
		orderBy: order.key,
		sortBy: order.in,
		individualSearchFields,
	};
	return internalApi.get('threat/threat-groups/list', { params: queryParams }).then((res) => {
		return res.payload.items;
	});
};

const fetchThreat = (id) => () => internalApi.get(`threat/one/${id}`);

const fetchThreatGroup = (id) => (dispatch) => {
	dispatch(clearAlerts());
	return internalApi.get(`threat/threat-groups/one/${id}`);
};

const exportCSV = (checkedItems) => () => {
	internalApi
		.get('threat/export', {
			params: { checkedItems: checkedItems.join(',') },
		})
		.then((res) => {
			exportCSVFile(res.payload.item, 'Threats');
		});
};

const saveThreat = (data) => (dispatch) => {
	dispatch(clearAlerts());

	return internalApi.post(data.id ? `threat/update/${data.id}` : `threat/create`, _.omit(data, 'id'));
};

const saveThreatGroup = (data) => (dispatch) => {
	dispatch(clearAlerts());

	return internalApi.post(data.id ? `threat/threat-groups/update/${data.id}` : `threat/threat-groups/create`, _.omit(data, 'id'));
};

const deleteThreat = (id) => () => internalApi.post(`threat/delete/${id}`);

const deleteThreatGroup = (id) => (dispatch) => {
	dispatch(clearAlerts());
	return internalApi.post(`threat/threat-groups/delete/${id}`);
};

const deleteThreats = (ids) => () =>
	internalApi.post('threat/delete-multiple', {
		ids,
	});

export {
	fetchThreats,
	fetchThreat,
	exportCSV,
	saveThreat,
	deleteThreat,
	deleteThreats,
	fetchThreatGroups,
	fetchThreatGroup,
	saveThreatGroup,
	deleteThreatGroup,
	getRiskThreats,
	getThreatGroups,
};
