import React from 'react';
import { TableRow } from '../components/Table';
import styles from '../routes/app/index.module.scss';
import { FormattedMessage, useIntl } from 'react-intl';

export const nl2br = (str, is_xhtml) => {
	if (typeof str === 'undefined' || str === null) {
		return '';
	}
	var breakTag = is_xhtml || typeof is_xhtml === 'undefined' ? '<br />' : '<br>';
	return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2');
};

export function hasOnlyNumbers(str) {
	return /^\d+$/.test(str);
}

export const lowerFirstLetter = (str) => str.charAt(0).toLowerCase() + str.slice(1);
export const upperFirstLetter = (str) => str.charAt(0).toUpperCase() + str.slice(1);

export const toSnakeCase = function (oStr) {
	var upperChars = oStr.match(/([A-Z])/g);
	if (!upperChars) {
		return oStr;
	}

	var str = oStr.toString();
	for (var i = 0, n = upperChars.length; i < n; i++) {
		str = str.replace(new RegExp(upperChars[i]), '-' + upperChars[i].toLowerCase());
	}

	if (str.slice(0, 1) === '-') {
		str = str.slice(1);
	}

	return str;
};

export const createFieldNameFromDisplayName = (name = '') =>
	lowerFirstLetter(name)
		.replace(/[^\w ]+/g, '')
		.replace(/ +/g, '');

export const createDisplayNameFromFieldName = (name = '') =>
	toSnakeCase(name)
		.split('-')
		.map((i) => upperFirstLetter(i))
		.join(' ');

export const pad = (n, width, z) => {
	z = z || '0';
	n = n + '';
	return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
};

export function parseLookupValue(value = '') {
	//console.log("##########################");
	//console.log("about to parse ", value);
	//console.log("##########################");
	const _parse = (v) => {
		if (v) {
			let formattedValues = v;
			formattedValues = formattedValues.replace(/lookup\@/, '');
			formattedValues = formattedValues.replace(/##/g, '"');
			formattedValues = formattedValues.replace(/-------/g, ' - ');
			formattedValues = formattedValues.replace(/---/g, ' ');
			formattedValues = formattedValues.replace(/___/g, ',');
			let obj = { v: v };
			try {
				let obj1 = JSON.parse(formattedValues);
				if (obj1) {
					obj = obj1;
				}
			} catch (e) {
				//console.error(`Error parsing value`,v);
			}
			//console.log("Returning =>",obj.v);
			return obj.v.replace(/\s+/g, ' ').trim();
		} else {
			//console.log("Returning =>",v);

			return v;
		}
	};
	if (value && typeof value === 'string' && value.indexOf('lookup@') !== -1) {
		let result = value
			.split(',')
			.map((v) => _parse(v))
			.join(', ');
		//console.log("$Returning => ",result)
		return result;
	} else {
		//console.log("@Returning => ",value);
		return value;
	}
}

export function parseLookupKey(value = '') {
	//console.log("##########################");
	//console.log("about to parse ", value);
	//console.log("##########################");
	const _parse = (v) => {
		if (v) {
			let formattedValues = v;
			formattedValues = formattedValues.replace(/lookup\@/, '');
			formattedValues = formattedValues.replace(/##/g, '"');
			formattedValues = formattedValues.replace(/---/g, ' ');
			formattedValues = formattedValues.replace(/___/g, ',');
			let obj = { v: v };
			try {
				let obj1 = JSON.parse(formattedValues);
				if (obj1) {
					obj = obj1;
				}
			} catch (e) {
				//console.error(`Error parsing value`,v);
			}
			//console.log("Returning =>",obj.v);
			return obj.id;
		} else {
			//console.log("Returning =>",v);

			return v;
		}
	};
	if (value && typeof value === 'string' && value.indexOf('lookup@') !== -1) {
		let result = value
			.split(',')
			.map((v) => _parse(v))
			.join(', ');
		//console.log("$Returning => ",result)
		return result;
	} else {
		//console.log("@Returning => ",value);
		return value;
	}
}

export function parseLookupKeys(value = '') {
	//console.log("##########################");
	//console.log("about to parse ", value);
	//console.log("##########################");
	const _parse = (v) => {
		if (v) {
			let formattedValues = v;
			formattedValues = formattedValues.replace(/lookup\@/, '');
			formattedValues = formattedValues.replace(/##/g, '"');
			formattedValues = formattedValues.replace(/---/g, ' ');
			formattedValues = formattedValues.replace(/___/g, ',');
			let obj = { v: v };
			try {
				let obj1 = JSON.parse(formattedValues);
				if (obj1) {
					obj = obj1;
				}
			} catch (e) {
				//console.error(`Error parsing value`,v);
			}
			//console.log("Returning =>",obj.v);
			return obj.id;
		} else {
			//console.log("Returning =>",v);

			return v;
		}
	};
	if (value && typeof value === 'string' && value.indexOf('lookup@') !== -1) {
		// e.g ["lookup@{##id##:##8bf44bf0-d720-11ea-bd81-5504986aa3bd##___##v##:##Financial---Analyst---II##}"]
		if (value[0] === '[' && value[value.length - 1] === ']') {
			try {
				let result = JSON.parse(value).map((v) => _parse(v));
				//console.log("$Returning => ",result)
				return result;
			} catch (e) {
				return [];
			}
		}
		let result = value.split(',').map((v) => _parse(v));
		//console.log("$Returning => ",result)
		return result;
	} else {
		//console.log("@Returning => ",value);
		return [];
	}
}

export function generateLookupID(id, value) {
	let obj = { id: id, v: value };
	let str = JSON.stringify(obj);
	str = str.replace(/\"/g, '##');
	// str = str.replace(/\s/g, '---');
	str = str.replace(/,/g, '___');
	return `lookup@${str}`;
}

export const calculateAverage = (values) => {
	if (values && values.length) {
		const avg = values
			.map((v) => {
				let out = 0;
				if (typeof v !== 'undefined' && v !== null) {
					out = parseInt(v);
				}
				return out;
			})
			.reduce((prev, current) => {
				return prev + current;
			}, 0);

		return (avg / values.length).toFixed(1);
	} else {
		return 0;
	}
};

export function getDataView(libraryData, chartFieldSets) {
	if (chartFieldSets.length === 0) {
		return (
			<TableRow>
				<td>NoItems</td>
			</TableRow>
		);
	}
	let finalItems = '',
		route = '';
	route = libraryData && libraryData[0] && libraryData[0].LibraryRoute;
	finalItems = chartFieldSets.map((i) => (
		<TableRow>
			<td
				key={i.Id}
				className={styles.tooltipTableRow}
				onClick={(element) => {
					let libRoute = libraryData[0].id === 'taskId' ? `task-management` : `library/${route}`;
					window.location.pathname = `${libRoute}/update/${element._targetInst.key}`;
				}}
			>
				{i.ChartLabel}
			</td>
		</TableRow>
	));
	return finalItems;
}

/*check for data containing json value or not*/
export function isJson(item) {
	item = typeof item !== 'string' ? JSON.stringify(item) : item;
	try {
		item = JSON.parse(item);
	} catch (e) {
		return false;
	}
	if (typeof item === 'object' && item !== null) {
		return true;
	}
	return false;
}

/*Parse Json data to normal and grouped*/
export function formattedJSONData(selected, filteredItems) {
	let fieldMapping = '';
	if (selected && selected.fieldValue) {
		let splitArr = selected.fieldValue.split('|');
		fieldMapping = { header: splitArr[0] && splitArr[0].split(','), map: splitArr[1] && splitArr[1].split(',') };
	}
	let dataItems = [];
	/* parsing json data value in two normal value */
	for (let l = 0, len = filteredItems.length; l < len; l++) {
		let fieldSetValue = filteredItems[l].ChartLabel;
		if (fieldSetValue) {
			let chartLabels = JSON.parse(fieldSetValue);
			let mLength = Array.isArray(chartLabels[0]) ? chartLabels[0].length : chartLabels.length;
			for (let m = 0; m < mLength; m++) {
				if (Array.isArray(chartLabels[0])) {
					if (chartLabels[0][m] !== '') {
						dataItems.push({ Id: filteredItems[l].Id, ChartLabel: fieldMapping.header[m], FieldName: filteredItems[l].FieldName });
					}
				} else {
					if (chartLabels[m] !== '') {
						dataItems.push({ Id: filteredItems[l].Id, ChartLabel: fieldMapping.header[m], FieldName: filteredItems[l].FieldName });
					}
				}
			}
		}
	}
	return dataItems;
}

export function formattedLookupData(filteredItems) {
	let newItems = [];
	for (let k = 0, len = filteredItems.length; k < len; k++) {
		let fieldSetValue = parseLookupValue(filteredItems[k].ChartLabel)
			.split(',')
			.map((v) => v.trim());
		for (let p = 0, keyLength = fieldSetValue.length; p < keyLength; p++) {
			newItems.push({ Id: filteredItems[k].Id, ChartLabel: fieldSetValue[p], FieldName: filteredItems[k].FieldName });
		}
	}
	return newItems;
}

export function formattedJSONFieldName(selected, items, itemsLength) {
	let fieldMapping = '';
	if (selected && selected.fieldValue) {
		let splitArr = selected.fieldValue.split('|');
		fieldMapping = { header: splitArr[0] && splitArr[0].split(','), map: splitArr[1] && splitArr[1].split(',') };
	}
	let fieldNameItems = [];
	/* parsing json data value in two normal value */
	for (let l = 0; l < itemsLength; l++) {
		let fieldNameValue = items[l].FieldName;
		if (fieldNameValue) {
			let fieldNameLabels = JSON.parse(fieldNameValue);
			let mLength = Array.isArray(fieldNameLabels[0]) ? fieldNameLabels[0].length : fieldNameLabels.length;
			for (let m = 0; m < mLength; m++) {
				if (Array.isArray(fieldNameLabels[0])) {
					if (fieldNameLabels[0][m] !== '') {
						fieldNameItems.push({ Id: items[l].Id, ChartLabel: items[l].ChartLabel, FieldName: fieldMapping.header[m] });
					}
				} else {
					if (fieldNameLabels[m] !== '') {
						fieldNameItems.push({ Id: items[l].Id, ChartLabel: items[l].ChartLabel, FieldName: fieldMapping.header[m] });
					}
				}
			}
		}
	}
	return fieldNameItems;
}

export function formattedLookupFieldName(items, itemsLength) {
	let newItems = [];
	for (let k = 0; k < itemsLength; k++) {
		let fieldNames = parseLookupValue(items[k].FieldName)
			.split(',')
			.map((v) => v.trim());
		for (let p = 0, keyLength = fieldNames.length; p < keyLength; p++) {
			newItems.push({ Id: items[k].Id, ChartLabel: items[k].ChartLabel, FieldName: fieldNames[p] });
		}
	}
	return newItems;
}

export function chartOnClick(event, elements) {
	const chart = elements[0]._chart;
	const element = chart.getElementAtEvent(event)[0];
	return chart.data.labels[element._index];
}

export function fieldOptionData(libraryId, libraryItems, isApprove) {
	let getfieldOptions = libraryItems.payload.items
		.filter((i) => i.library_id === libraryId)
		.map((item) => ({
			value: item.id,
			label: item.display_name,
			fieldValue: item.field_value,
		}));
	if (isApprove) {
		getfieldOptions.push({ value: 'status', label: 'Status', fieldValue: '' });
	}
	return getfieldOptions;
}

export const isLocalhost = () => {
	return location.hostname === 'localhost' || location.hostname === '127.0.0.1';
};

export const to = (promise) => {
	return promise
		.then((data) => {
			return [null, data];
		})
		.catch((err) => [err]);
};

export const postForm = ({ url, body }) => {
	var form = document.createElement('form');
	form.setAttribute('method', 'post');
	form.setAttribute('action', url);
	form.setAttribute('target', '_blank');
	form.setAttribute('enctype', 'application/x-www-form-urlencoded');

	if (body && typeof body === 'object') {
		Object.keys(body).forEach((attr) => {
			var _field = document.createElement('input');
			_field.setAttribute('name', attr);
			_field.setAttribute('value', body[attr]);
			form.appendChild(_field);
		});
	}

	document.body.appendChild(form); // Not entirely sure if this is necessary
	form.submit();
	document.body.removeChild(form);
};

export const extractHashedVariables = (inputString) => {
	// Use a regular expression to match variables enclosed in ##...##
	const regex = /##(.*?)##/g;
	const matches = [];
	let match;

	// Find all variable matches in the input string
	while ((match = regex.exec(inputString)) !== null) {
		matches.push(match[1]);
	}

	return matches;
};

export const getTranslatedText = (label, defaultMessage) => {
	return <FormattedMessage id={label} defaultMessage={defaultMessage} />;
};
