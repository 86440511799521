import internalApi from '../utils/internalApi';
import { clearAlerts } from './alerts';
import createAction from '../utils/createAction';
import { arrayMove } from '../utils/arrayHelpers';

export const GET_PLAN_TEMPLATE_ITEM = 'GET_PLAN_TEMPLATE_ITEM';
export const UPDATE_PLANS_TEMPLATE_ITEMS = 'UPDATE_PLANS_TEMPLATE_ITEMS';
export const FETCH_PLAN_TEMPLATE_ITEM_LOADED = 'FETCH_PLAN_TEMPLATE_ITEM_LOADED';

const getPlanTemplateItem =
	({ planTemplateId = '', order = {}, search, limit, offset } = {}) =>
	(dispatch) => {
		dispatch(createAction(FETCH_PLAN_TEMPLATE_ITEM_LOADED));
		const queryParams = {
			search,
			limit,
			offset,
			order,
			planTemplateId,
		};
		internalApi.get('plans/template-item/list', { params: queryParams }).then((res) => {
			dispatch(createAction(GET_PLAN_TEMPLATE_ITEM, res.payload.items));
		});
	};

const savePlanTemplateItem = (data) => (dispatch) => {
	dispatch(clearAlerts());

	return internalApi.post('plans/template-item/update', data);
};

const sortPlanTemplateItems = (planTemplateId, dragIndex, hoverIndex) => (dispatch, getState) => {
	const state = getState();

	const sorted = arrayMove(state.planTemplateItems.getIn(['items']).toJS(), dragIndex, hoverIndex);

	internalApi
		.post(`plans/template-item/${planTemplateId}/sort`, {
			planItems: sorted.map((i) => i.id),
		})
		.then((response) => {
			dispatch(createAction(UPDATE_PLANS_TEMPLATE_ITEMS, sorted));
		});
};

const deletePlanTemplateItems = (ids) => () =>
	internalApi.post('plans/template-item/delete-multiple', {
		ids,
	});

const fetchPlanTemplateItem = (id) => () => internalApi.get(`plans/template-item/one/${id}`);

export { savePlanTemplateItem, getPlanTemplateItem, deletePlanTemplateItems, fetchPlanTemplateItem, sortPlanTemplateItems };
