import React from 'react';
import PropTypes from 'prop-types';

import cx from 'classnames';
import cs from './component.module.scss';

class TableHeaderCol extends React.Component {
	state = {
		direction: this.props.direction,
	};

	onClick = () => {
		let { direction } = this.state;

		direction = direction === 'asc' ? 'desc' : 'asc';

		this.setState(
			{
				direction,
			},
			() => {
				if (this.props.hasOrder) {
					this.props.reOrder(this.props.field, direction);
				}
			},
		);
	};

	render() {
		const { isActive, children, align } = this.props;

		const style = {};
		if (align) {
			style.textAlign = align;
		}

		return (
			<th
				className={cx(cs.root, {
					[cs.active]: isActive,
				})}
				style={style}
				onClick={this.onClick}
			>
				{children}
			</th>
		);
	}
}

TableHeaderCol.propTypes = {
	children: PropTypes.node.isRequired,
	isActive: PropTypes.bool,
	field: PropTypes.string,
	align: PropTypes.string,
	hasOrder: PropTypes.bool,
	direction: PropTypes.string,
	reOrder: PropTypes.func,
};

TableHeaderCol.defaultProps = {
	isActive: false,
	field: '',
	align: null,
	hasOrder: false,
	direction: 'desc',
	reOrder: () => false,
};

export default TableHeaderCol;
