import { useRef } from 'react';
import { withRouter } from 'react-router-dom';
import { useDrag, useDrop } from 'react-dnd';

import _ from 'lodash';
import classNames from 'classnames';

import cs from './component.module.scss';

const SortableTableRow = (props) => {
	const dragDropRef = useRef(null);

	const cardTarget = {
		drop(item, monitor) {
			if (!dragDropRef.current) {
				return;
			}
			const dragIndex = item?.index; //monitor.getItem().index;
			const hoverIndex = props?.index;

			// Don't replace items with themselves
			if (dragIndex === hoverIndex) {
				return;
			}

			// Determine rectangle on screen
			const hoverBoundingRect = dragDropRef.current?.getBoundingClientRect?.();

			// Get vertical middle
			const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
			const hoverAvgY = (hoverBoundingRect.bottom + hoverBoundingRect.top) / 2;

			// Determine mouse position
			const clientOffset = monitor.getClientOffset();

			// Get pixels to the top
			// const hoverClientY = clientOffset.y - hoverBoundingRect.top;

			// Only perform the move when the mouse has crossed half of the items height
			// When dragging downwards, only move when the cursor is below 50%
			// When dragging upwards, only move when the cursor is above 50%

			// Dragging downwards
			// if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
			if (dragIndex < hoverIndex && Math.abs(clientOffset.y - hoverAvgY) > hoverMiddleY * 2) {
				return;
			}

			// Dragging upwards
			// if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
			if (dragIndex > hoverIndex && Math.abs(clientOffset.y - hoverAvgY) > hoverMiddleY * 2) {
				return;
			}

			// Time to actually perform the action
			props?.moveItem(dragIndex, hoverIndex);

			// Note: we're mutating the monitor item here!
			// Generally it's better to avoid mutations,
			// but it's good here for the sake of performance
			// to avoid expensive index searches.
			// monitor.getItem().index = hoverIndex;
			item.index = hoverIndex;
		},
	};

	const [, drop] = useDrop({
		accept: 'library',
		// hover(item: any, monitor) {
		drop: cardTarget.drop,
	});

	const [{ isDragging }, drag, preview] = useDrag({
		type: 'library',
		item: { type: 'library', id: props?.id, index: props?.index },
		collect: (monitor) => ({
			isDragging: monitor?.isDragging?.(),
		}),
	});

	const onDoubleClick = () => {
		const { updateUrl } = props;

		if (!_.isEmpty(updateUrl)) {
			props?.history?.push?.(`/${updateUrl?.path}/update/${updateUrl?.id}`);
		}
	};

	preview(drop(dragDropRef));
	drag(dragDropRef);

	const { dangerLine, dangerLineBack, style } = props;
	const opacity = isDragging ? 0 : 1;

	return (
		<tr
			ref={dragDropRef}
			className={classNames(cs.root, {
				[cs.dangerLine]: dangerLine,
				[cs.dangerLineBack]: dangerLineBack,
			})}
			style={{ ...style, opacity }}
			onClick={props?.onClick}
			onDoubleClick={onDoubleClick}
		>
			{props.children}
		</tr>
	);
};

export default withRouter(SortableTableRow);
