import createAction from '../utils/createAction';
import internalApi from '../utils/internalApi';
import { arrayMove } from '../utils/arrayHelpers';

import { clearAlerts } from './alerts';
import { fetchUserPermissions } from './role';
import exportCSVFile from '../utils/exportCSVFile';

export const FETCH_LIBRARY_LOADED = 'FETCH_LIBRARY_LOADED';
export const FETCH_LIBRARY = 'FETCH_LIBRARY';
export const FETCH_LIBRARY_WITH_FIELDS_LOADED = 'FETCH_LIBRARY_WITH_FIELDS_LOADED';
export const FETCH_LIBRARY_WITH_FIELDS = 'FETCH_LIBRARY_WITH_FIELDS';
export const TOGGLE_LIBRARY_AS_WIDGET = 'TOGGLE_LIBRARY_AS_WIDGET';
export const FETCH_LIBRARY_WITHOUT_FIELDS = 'FETCH_LIBRARY_WITHOUT_FIELDS';
export const TOGGLE_CURRENT_LIBRARY_FIELD = 'TOGGLE_CURRENT_LIBRARY_FIELD';
export const SET_INDIVIDUAL_SEARCH_FIELD = 'SET_INDIVIDUAL_SEARCH_FIELD';

const fetchLibraries =
	({ page = 1, limit = 50 } = {}) =>
	(dispatch) => {
		dispatch(createAction(FETCH_LIBRARY_LOADED));
		const queryParams = {
			page,
			limit,
		};
		internalApi.get('library/list', { params: queryParams }).then((res) => {
			dispatch(createAction(FETCH_LIBRARY, res.payload.items));
		});
	};

const sortLibraries = (dragIndex, hoverIndex) => (dispatch, getState) => {
	const state = getState();

	const sorted = arrayMove(state.library.get('libraries').get('data').toJS(), dragIndex, hoverIndex);

	internalApi.post('library/sort', { libraries: sorted.map((i) => i.id) }).then(
		(res) => {
			dispatch(
				createAction(FETCH_LIBRARY, {
					records: sorted,
				}),
			);
		},
		(err) => {
			console.log('err', err);
		},
	);
};

const exportCSV = (libraryAlias, checkedItems) => () => {
	internalApi
		.get(`library/export/${libraryAlias}`, {
			params: { checkedItems: checkedItems.join(',') },
		})
		.then((res) => {
			exportCSVFile(res, libraryAlias);
		});
};

const fetchLibrary = (id) => () => internalApi.get(`library/one/${id}`);

const fetchLibraryByAlias = (alias) => () => internalApi.get(`library/one-by-alias/${alias}`);

const fetchLibrariesWithApprovalCount = (alias) => () => internalApi.get(`library/with-approval-count/`);

const fetchLibraryWithFields = (alias, params) => (dispatch) => {
	dispatch(createAction(FETCH_LIBRARY_WITH_FIELDS_LOADED));

	let listType = params.listType || 'all';

	const queryParams = {
		orderBy: params.order.key,
		sortBy: params.order.in,
		search: params.search,
		offset: params.offset,
		limit: params.limit,
		id: params.id,
		type: params.type,
		listType: listType,
		individualSearchFields: params.individualSearchFields ? JSON.stringify(params.individualSearchFields) : '{}',
		viewDependencySearch: params?.viewDependencySearch ? JSON.stringify(params.viewDependencySearch) : '{}',
		approvalTimeFilter: params.approvalTimeFilter,
	};
	internalApi.post(`library/with-fields/${alias}`, { params: queryParams }).then((res) => {
		if (queryParams.type == 'without') {
			dispatch(createAction(FETCH_LIBRARY_WITHOUT_FIELDS, res.payload.item));
		} else {
			dispatch(createAction(FETCH_LIBRARY_WITH_FIELDS, res.payload.item));
		}
	});
};

const getLibraryWithFields = (alias, params) => {
	let listType = params?.listType || 'all';

	const queryParams = {
		orderBy: params?.order?.key,
		sortBy: params?.order?.in,
		search: params?.search,
		offset: params?.offset,
		limit: params?.limit,
		id: params?.id,
		type: params?.type,
		listType: listType,
		individualSearchFields: params?.individualSearchFields ? JSON.stringify(params.individualSearchFields) : '{}',
		viewDependencySearch: params?.viewDependencySearch ? JSON.stringify(params.viewDependencySearch) : '{}',
	};

	return internalApi.post(`library/with-fields/${alias}`, { params: queryParams }).then((res) => {
		if (queryParams.type == 'without') {
			return res.payload.item;
		} else {
			return res.payload.item;
		}
	});
};

const toggleCurrentLibraryField = (params) => (dispatch) => {
	dispatch(createAction(TOGGLE_CURRENT_LIBRARY_FIELD, params));
};

const setIndividualSearchField =
	({ libraryId, individualSearchFields }) =>
	(dispatch) => {
		dispatch(createAction(SET_INDIVIDUAL_SEARCH_FIELD, { libraryId, individualSearchFields }));
	};

const getRecordsForLookup = ({ libraryId, lookupFields, search, selectedItems = null, limit = 50 }) => {
	let _search = '';
	let _selectedItems = selectedItems;
	if (typeof search === 'string') {
		_search = search;
	} else if (typeof search === 'object' && Array.isArray(search) && !selectedItems) {
		_selectedItems = search;
	}

	return internalApi.get(`library/records/${libraryId}/list-for-lookup`, {
		params: {
			search: _search,
			limit,
			selectedItems: _selectedItems,
			lookupFields: JSON.stringify(lookupFields),
		},
	});
};

const searchRecordsForLookup = (params) => () => {
	let { search, selectedItems, libraryType, lookupFields, limit = 10 } = params;
	if (libraryType) {
		let _search = '';
		let _selectedItems = selectedItems;
		if (typeof search === 'string') {
			_search = search;
		} else if (typeof search === 'object' && Array.isArray(search) && !selectedItems) {
			_selectedItems = search;
		}

		return internalApi.get(`library/records/${libraryType}/list-for-lookup`, {
			params: {
				search: _search,
				sortRequired: false,
				selectedItems: _selectedItems,
				limit,
				lookupFields: JSON.stringify(lookupFields),
			},
		});
	} else {
		return new Promise();
	}
};

const fetchRecordsForLookup =
	({ libraryType, lookupFields }) =>
	() =>
		internalApi.get(`library/records/${libraryType}/list-for-lookup`, {
			params: {
				lookupFields: JSON.stringify(lookupFields),
			},
		});

const saveLibrary = (data) => (dispatch) => {
	dispatch(clearAlerts());

	let url = 'library/create';
	let params = data;
	if (data.id) {
		url = `library/update/${data.id}`;
		params = {
			name: data.name,
			parent: data.parent,
			icon: data.icon,
			canDelete: data.canDelete,
			isActive: data.isActive,
			isRestricted: data.isRestricted,
			isApprove: data.isApprove,
			reviewEnabled: data.reviewEnabled,
			reviewDays: data.reviewDays,
			remindReviewInDays: data.remindReviewInDays,
		};
	}

	return internalApi.post(url, params);
};

const changeLibraryStatus =
	({ id, isActive }) =>
	(dispatch) => {
		dispatch(clearAlerts());

		return internalApi.post('library/toggle-active', { id, isActive });
	};

const saveLibraryRecord = (libraryId, data) => (dispatch) => {
	dispatch(clearAlerts());

	let url = `library/records/${libraryId}/create`;
	if (data.id) {
		url = `library/records/${libraryId}/update/${data.id}`;
	}

	return internalApi.post(url, data);
};

const duplicateLibraryRecord = (libraryId, recordId) => (dispatch) => {
	dispatch(clearAlerts());
	return internalApi.post(`library/records/${libraryId}/duplicate/${recordId}`, {});
};

const submitForApproval = (libraryId, recordId) => (dispatch) => {
	dispatch(clearAlerts());

	return internalApi.post(`library/records/${libraryId}/submit-for-approval/${recordId}`);
};

const completeReview = (libraryId, recordId) => (dispatch) => {
	dispatch(clearAlerts());

	return internalApi.post(`library/records/${libraryId}/complete-review/${recordId}`);
};

const approveOrReject =
	(status, libraryId, rejectionReason = '', recordId) =>
	(dispatch) => {
		dispatch(clearAlerts());

		return internalApi.post(`library/records/${libraryId}/approve-or-reject/${recordId}`, {
			status,
			rejectionReason,
		});
	};

const deleteLibrary = (id) => () => internalApi.post(`library/delete/${id}`);

const deleteLibraries = (ids) => () =>
	internalApi.post('library/delete-multiple', {
		ids,
	});

const deleteLibraryRecords = (libraryId, ids) => () =>
	internalApi.post(`library/records/${libraryId}/delete`, {
		ids,
	});

const fetchRecordById =
	(libraryId, id, version = '') =>
	() =>
		internalApi.get(`library/records/${libraryId}/one/${id}?v=${version}`);

export {
	fetchLibraries,
	fetchLibrary,
	fetchLibraryByAlias,
	exportCSV,
	fetchLibraryWithFields,
	saveLibrary,
	deleteLibrary,
	saveLibraryRecord,
	deleteLibraryRecords,
	fetchRecordsForLookup,
	fetchRecordById,
	sortLibraries,
	submitForApproval,
	approveOrReject,
	deleteLibraries,
	changeLibraryStatus,
	toggleCurrentLibraryField,
	duplicateLibraryRecord,
	searchRecordsForLookup,
	fetchLibrariesWithApprovalCount,
	completeReview,
	setIndividualSearchField,
	getRecordsForLookup,
	getLibraryWithFields,
	//  toggleLibraryAsWidget
};
