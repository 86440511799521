import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import storage from '../../../utils/storage';

class PrivateRoute extends React.Component {
	render() {
		const { component: Component, render, ...rest } = this.props;

		return (
			<Route
				{...rest}
				render={(props) => {
					if (!this.props.token) {
						const pathname = storage.get('companyAlias') ? `/${storage.get('companyAlias')}/login` : '/';
						return (
							<Redirect
								to={{
									pathname,
								}}
							/>
						);
					} else if (Component) {
						return <Component {...props} />;
					}
					return render(props);
				}}
			/>
		);
	}
}

PrivateRoute.propTypes = {
	//  component: PropTypes.func,
	render: PropTypes.func,
};

PrivateRoute.defaultProps = {
	//  component: null,
	render: () => false,
};

const mapStateToProps = (state) => ({
	token: state.auth.get('token'),
});

export default connect(mapStateToProps)(PrivateRoute);
