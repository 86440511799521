import { Map, List } from 'immutable';

import * as actionTypes from '../actions/plan-template';

const initialState = new Map({
	isLoaded: false,
	items: new List(),
	count: 0,
});

export default (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.FETCH_PLAN_TEMPLATE_LOADED: {
			const { loaded = true } = action.payload;

			return state.updateIn(['isLoaded'], () => loaded);
		}
		case actionTypes.GET_PLAN_TEMPLATE: {
			return new Map({
				isLoaded: false,
				items: new List(action.payload.rows),
				count: action.payload.count,
			});
		}
		default:
			return state;
	}
};
