import { Map, List } from 'immutable';

import * as actionTypes from '../actions/teams';

const initialState = new Map({
	members: new List(),
});

export default (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.FETCH_MEMBERS: {
			return state.set('members', new List(action.payload));
		}
		case actionTypes.ADD_MEMBER: {
			return state.updateIn(['members'], (list) => list.push(action.payload));
		}
		case actionTypes.REMOVE_MEMBER: {
			return state.removeIn(['members', action.payload]);
		}
		case actionTypes.UPDATE_MEMBER: {
			const { key, fieldName, value } = action.payload;

			return state.updateIn(['members', key], (val) =>
				Object.assign({}, val, {
					[fieldName]: value,
				}),
			);
		}
		default:
			return state;
	}
};
