import React from 'react';
import PropTypes from 'prop-types';

import Icon from 'react-fa';
import Image from '../../Image';
import { Button, ButtonLink, ButtonDelete } from '../../Form';
import Flex, { Col } from '../../Flex';

import _ from 'lodash';
import config from '../../../utils/config';
import storage from '../../../utils/storage';
import internalApi from '../../../utils/internalApi';

import cs from './component.module.scss';

class UploadInputV2 extends React.Component {
	static propTypes = {
		name: PropTypes.string,
		label: PropTypes.string,
		value: PropTypes.string,
		accept: PropTypes.string,
		onUpload: PropTypes.func.isRequired,
		onDelete: PropTypes.func,
	};

	static defaultProps = {
		name: '',
		value: null,
		label: 'Click to Upload File ',
		accept: '*',
	};

	static isImage(type) {
		return _.findIndex(['jpg', 'png', 'jpeg'], (ext) => type.includes(ext)) !== -1;
	}

	constructor(props) {
		super(props);

		let fileUrl = '';
		let isImage = false;
		if (props.value) {
			fileUrl = props.value;
			// fileUrl = `${config('api.url')}resource/attachment/${
			//   props.value
			//   }?token=${storage.get('token')}`;
			isImage = UploadInputV2.isImage(props.value.split('.').pop());
		}

		this.state = {
			loading: false,
			fileUrl,
			isImage,
			file: null,
		};
	}

	componentWillReceiveProps(nextProps) {
		if (this.props.value !== nextProps.value) {
			this.setState({
				fileUrl: nextProps.value,
				// fileUrl: `${config('api.url')}resource/attachment/${
				//   nextProps.value
				//   }?token=${storage.get('token')}&companyAlias=${storage.get(
				//     'companyAlias',
				//   )}`,
				isImage: UploadInputV2.isImage(nextProps.value.split('.').pop()),
			});
		}
	}

	onUploadImage = (e) => {
		e.persist();

		if (this.state.loading) {
			return;
		}

		const self = this;

		this.setState({ loading: true }, () => {
			const file = e.target.files[0];

			const reader = new FileReader();
			reader.onload = (() => (fileEvt) => {
				self.setState({
					fileUrl: fileEvt.target.result,
					loading: false,
					file,
					isImage: UploadInputV2.isImage(file.type),
				});
			})(file);

			reader.readAsDataURL(file);

			this.props.onUpload(this.props.name, file);
		});
	};

	render() {
		const { file } = this.state;
		const fileName = file && file.name && file.name.split('.')[1] === 'csv' && file.name;
		let shouldDelete = typeof this.props.onDelete !== 'undefined';

		let { value = '' } = this.props;

		value = value ? value.trim() : null;

		return (
			<div className={cs.root}>
				<label className={cs.label}>
					<input accept={this.props.accept} type="file" hidden onChange={this.onUploadImage} />
					{this.props.children}
				</label>
			</div>
		);
	}
}

export default UploadInputV2;
