import React from 'react';
import PropTypes from 'prop-types';
import Image from '../../Image';
import RenderIf from '../../RenderIf';

import { withImageUrl } from '../../../utils/withStaticUrl';

import styles from './component.module.scss';
import imgLogo from '../../../assets/images/logo.png';

class Logo extends React.PureComponent {
	static propTypes = {
		logo: PropTypes.string,
	};

	static defaultProps = {
		logo: '',
	};

	render() {
		return (
			<React.Fragment>
				<Image
					className={styles.logoMain}
					src={this.props.logo ? withImageUrl(this.props.logo) : imgLogo}
					style={{
						maxHeight: '55px',
					}}
				/>

				{/* <RenderIf view='mobile'  >
      <Image
        src={this.props.logo ? withImageUrl(this.props.logo) : imgLogo}
        style={{
          maxHeight: '25px',
        }}
      />
      </RenderIf> */}
			</React.Fragment>
		);
	}
}

export default Logo;
