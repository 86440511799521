import _ from 'lodash';
import createAction from '../utils/createAction';
import { clearAlerts } from './alerts';
import internalApi from '../utils/internalApi';
import exportCSVFile from '../utils/exportCSVFile';
import storage from '../utils/storage';

export const FETCH_TASKS_LOADED = 'FETCH_TASKS_LOADED';
export const FETCH_TASKS = 'FETCH_TASKS';
export const SET_TASKS_FIELDS = 'SET_TASKS_FIELDS';

const fetchTasks =
	({ order = {}, name, page, limit, individualSearchFields = {} } = {}) =>
	(dispatch) => {
		dispatch(createAction(FETCH_TASKS_LOADED));

		const queryParams = {
			orderBy: order.key,
			sortBy: order.in,
			name,
			page,
			limit,
			individualSearchFields,
		};

		internalApi.get('task/list', { params: queryParams }).then((res) => {
			dispatch(createAction(FETCH_TASKS, res.payload.items));
		});
	};

const fetchTask = (id) => () => internalApi.get(`task/one/${id}`);

const exportCSV = (checkedItems) => () => {
	internalApi
		.get('task/export', {
			params: { checkedItems: checkedItems.join(',') },
		})
		.then((res) => {
			exportCSVFile(res.payload.item, 'tasks');
		});
};

const saveTask = (data) => (dispatch) => {
	dispatch(clearAlerts());

	return internalApi.post(data.id ? `task/update/${data.id}` : 'task/create', _.omit(data, 'id'));
};

const deleteTasks = (ids) => () =>
	internalApi.post('task/delete-multiple', {
		ids,
	});

const deleteTask = (id) => () => internalApi.post(`task/delete/${id}`);

const onColumnToggleTasks = (fieldId, value) => (dispatch, getState) => {
	const state = getState();
	const fields = state.taskManagement.get('fields');

	const newFields = fields.map((field) => {
		if (field.id === fieldId) {
			return { ...field, hidden: !value };
		}
		return { ...field };
	});

	storage.set('tasksFields', newFields);

	dispatch(createAction(SET_TASKS_FIELDS, newFields));
};

export { fetchTasks, fetchTask, exportCSV, saveTask, deleteTask, deleteTasks, onColumnToggleTasks };
