import { List, Map } from 'immutable';

import * as actionTypes from '../actions/api-keys';

const initialState = new Map({
	isLoaded: false,
	items: new List(),
	isNewKeyGenerated: false,
	generatedKey: new Map({ key: '', secret: '' }),
});

export default (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.IN_PROGRESS: {
			return state.set('isLoaded', action.payload);
		}

		case actionTypes.FETCH_API_KEYS_LOADED: {
			const { loaded = false } = action.payload;

			state = state.update('isLoaded', () => loaded);
			return state.update('items', () => new List(action.payload.records));
		}

		case actionTypes.NEW_KEYS_LOADED: {
			const { loaded = false } = action.payload;

			state = state.update('isLoaded', () => loaded);
			state = state.update('isNewKeyGenerated', () => true);
			return state.update('generatedKey', () => new Map(action.payload));
		}

		case actionTypes.CLEAR_NEW_KEYS_LOADED: {
			state = state.update('isNewKeyGenerated', () => false);
			return state.update('generatedKey', () => new Map({ key: '', secret: '' }));
		}

		default:
			return state;
	}
};
