import _ from 'lodash';
import config from '../utils/config';
import axios from './restClient';
import storage from './storage';

class InternalApi {
	static formatUrl(path) {
		if (_.isString(path)) {
			return `/resource/${path}`;
		}
		return `/${path.server}${path.path}`;
	}

	static formatAssetUrl(fileName) {
		return `${config('api.url')}resource/asset/${fileName}?token=${storage.get('token')}&companyAlias=${storage.get('companyAlias')}`;
	}

	static get(urlPath, rest = {}) {
		const requestParams = Object.assign({}, rest, {
			method: 'GET',
			params: Object.assign({}, rest.params || {}, {
				// token: storage.get('token'),
			}),
			headers: Object.assign({}, rest.headers || {}, {
				'company-alias': storage.get('companyAlias'),
				Authorization: `Bearer ${storage.get('token')}`,
			}),
		});

		return axios(InternalApi.formatUrl(urlPath), requestParams).then((res) => res?.data);
	}

	static post(urlPath, data = {}, headers = {}) {
		const requestParams = {
			method: 'POST',
			data,
			// params: {
			//   token: storage.get('token'),
			// },
			headers: Object.assign({}, headers, {
				'Content-Type': 'application/json',
				'company-alias': storage.get('companyAlias'),
				Authorization: `Bearer ${storage.get('token')}`,
			}),
		};

		return axios(InternalApi.formatUrl(urlPath), requestParams).then((res) => res?.data);
	}

	static put(urlPath, data = {}, headers = {}) {
		const requestParams = {
			method: 'PUT',
			data,
			// params: {
			//   token: storage.get('token'),
			// },
			headers: Object.assign({}, headers, {
				'Content-Type': 'application/json',
				'company-alias': storage.get('companyAlias'),
				Authorization: `Bearer ${storage.get('token')}`,
			}),
		};

		return axios(InternalApi.formatUrl(urlPath), requestParams).then((res) => res?.data);
	}

	static delete(urlPath, data = {}, headers = {}) {
		const requestParams = {
			method: 'DELETE',
			data,
			// params: {
			//   token: storage.get('token'),
			// },
			headers: Object.assign({}, headers, {
				'Content-Type': 'application/json',
				'company-alias': storage.get('companyAlias'),
				Authorization: `Bearer ${storage.get('token')}`,
			}),
		};

		return axios(InternalApi.formatUrl(urlPath), requestParams).then((res) => res?.data);
	}

	static uploadFile(name, file, options = {}) {
		const data = new FormData();

		data.append(name, file);
		data.append('name', name);
		data.append('options', JSON.stringify(options));

		const requestParams = {
			method: 'POST',
			data,
			// params: {
			//   token: storage.get('token'),
			// },
			headers: Object.assign(
				{},
				{
					'company-alias': storage.get('companyAlias'),
					Authorization: `Bearer ${storage.get('token')}`,
				},
			),
		};

		return axios(InternalApi.formatUrl('attachment/upload'), requestParams).then((res) => res?.data);
	}

	static uploadAsset(name, file, options = {}) {
		const data = new FormData();

		data.append(name, file);
		data.append('name', name);
		data.append('options', JSON.stringify(options));

		const requestParams = {
			method: 'POST',
			data,
			// params: {
			//   token: storage.get('token'),
			// },
			headers: Object.assign(
				{},
				{
					'company-alias': storage.get('companyAlias'),
					Authorization: `Bearer ${storage.get('token')}`,
				},
			),
		};

		return axios(InternalApi.formatUrl('asset/upload'), requestParams).then((res) => res?.data);
	}
}

export default InternalApi;
