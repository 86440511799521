import { Map, List } from 'immutable';
import storage from '../utils/storage';

import * as actionTypes from '../actions/auth';

const initialState = new Map({
	token: storage.get('token'),
	loginHistory: new Map({
		isLoaded: false,
		data: new List(),
		count: 0,
	}),
});

export default (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.AUTHENTICATE: {
			return state.set('token', action.payload.token);
		}
		case actionTypes.CLEAR_TOKEN: {
			return state.set('token', null);
		}
		case actionTypes.FETCH_LOGIN_HISTORY_LOADED: {
			const { loaded = false } = action.payload;

			return state.updateIn(['loginHistory', 'isLoaded'], () => loaded);
		}
		case actionTypes.GET_LOGIN_HISTORY: {
			const loginHistory = action.payload;

			return state.update(
				'loginHistory',
				() =>
					new Map({
						isLoaded: true,
						data: new List(loginHistory.rows),
						count: loginHistory.count,
					}),
			);
		}
		default:
			return state;
	}
};
