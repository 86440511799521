import { Map, List } from 'immutable';

import * as actionTypes from '../actions/plans';

const initialState = new Map({
	plans: new Map({
		isLoaded: false,
		items: new List(),
	}),
	fields: new Map({
		isLoaded: false,
		data: [],
	}),
	planItems: new Map({
		isLoaded: false,
		items: new List(),
	}),
	versionHistory: new Map({
		isLoaded: false,
		items: new List(),
	}),
});

export default (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.FETCH_PLANS: {
			return state.update(
				'plans',
				() =>
					new Map({
						isLoaded: true,
						items: new List(action.payload.map((item) => new Map(item))),
					}),
			);
		}
		case actionTypes.FETCH_PLANS_FIELDS: {
			return state.update(
				'fields',
				() =>
					new Map({
						isLoaded: true,
						data: action.payload,
					}),
			);
		}
		case actionTypes.FETCH_PLANS_ITEMS: {
			return state.update(
				'planItems',
				() =>
					new Map({
						isLoaded: true,
						items: new List(action.payload),
					}),
			);
		}
		case actionTypes.FETCH_VERSION_HISTORY: {
			return state.update(
				'versionHistory',
				() =>
					new Map({
						isLoaded: true,
						items: new List(action.payload),
					}),
			);
		}
		case actionTypes.FETCH_PLANS_LOADED: {
			const { loaded = false } = action.payload;
			return state.updateIn(['plans', 'isLoaded'], () => loaded);
		}
		case actionTypes.FETCH_PLANS_FIELDS_LOADED: {
			const { loaded = false } = action.payload;
			return state.updateIn(['fields', 'isLoaded'], () => loaded);
		}
		case actionTypes.FETCH_PLANS_ITEMS_LOADED: {
			const { loaded = false } = action.payload;
			return state.updateIn(['planItems', 'isLoaded'], () => loaded);
		}
		case actionTypes.FETCH_VERSION_HISTORY_LOADED: {
			const { loaded = false } = action.payload;
			return state.updateIn(['versionHistory', 'isLoaded'], () => loaded);
		}
		default:
			return state;
	}
};
