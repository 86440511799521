import React from 'react';
import Flex, { Col } from '../../Flex';
import GridDropdownItem from './GridDropdownItem';
import styles from './component.module.scss';
class Grid extends React.Component {
	constructor(props) {
		super(props);

		this.state = this._parseProps(props);
	}

	shouldComponentUpdate(nextProps, nextState) {
		if (this.props.value !== nextProps.value) {
			return true;
		}

		if (this.props.params !== nextProps.params) {
			return true;
		}

		if (this.props.field && this.props.field.name && this.props.field.name !== nextProps.field.name) {
			return true;
		}

		return false;
	}

	_parseProps(p) {
		const [cols, rows, options] = p.params.split('|').map((i) => i.split(','));
		let values = [];

		if (p.value) {
			try {
				values = JSON.parse(p.value);
			} catch (e) {
				if (rows && rows.length && cols && cols.length) {
					for (let i = 0; i < rows.length; i++) {
						values[i] = [];
						for (let j = 0; j < cols.length; j++) {
							values[i][j] = '';
						}
					}
				}
			}
		} else {
			if (rows && rows.length && cols && cols.length) {
				for (let i = 0; i < rows.length; i++) {
					values[i] = [];
					for (let j = 0; j < cols.length; j++) {
						values[i][j] = '';
					}
				}
			}
		}

		return {
			rows,
			cols,
			values,
			options: options?.map?.((item) => ({
				value: item,
				label: item,
			})) ?? [],
		};
	}

	componentWillReceiveProps(nextProps) {
		if (typeof this.props.value !== 'undefined' && typeof nextProps.value !== 'undefined' && this.props.value !== nextProps.value) {
			this.setState(this._parseProps(nextProps));
		}

		if (typeof this.props.params !== 'undefined' && typeof nextProps.params !== 'undefined' && this.props.params !== nextProps.params) {
			this.setState(this._parseProps(nextProps));
		}
	}

	onChange = (value, name) => {
		const [, i, j] = name.split('-');
		const { values } = this.state;

		values[i][j] = value;

		this.setState(
			{
				values,
			},
			() => {
				this.props.onChange(JSON.stringify(values), this.props.field.name);
			},
		);
	};

	render() {
		const { rows, cols, values } = this.state;

		// console.log("Grid rendered ", this.props.field);

		const padding = {
			left: 10,
			right: 10,
			bottom: 10,
			top: 10,
		};
		return (
			<Flex xs={{ align: 'center' }}>
				<Col xs={{ size: 12 }}>
					<Flex xs={{ align: 'left', width: '40%', display: 'inline-block' }} lg={{ width: '100%' }}>
						<Col className={styles.columnResponsive} md={{ size: 12, display: 'none' }} lg={{ size: 2, ...padding }}>
							&nbsp;
						</Col>
						{cols &&
							cols?.map?.((col, key) => (
								<Col className={styles.columnResponsive} xs={{ size: 12 }} lg={{ size: 10 / cols.length, ...padding }} key={key}>
									{col}
								</Col>
							))}
					</Flex>
					{rows &&
						rows?.map?.((row, rowKey) => (
							<Flex xs={{ align: 'left', width: '60%', display: 'inline-block' }} lg={{ width: '100%', align: 'center' }} key={rowKey}>
								<Col lg={{ size: 2, ...padding }} xs={{ size: 12, bottom: 10 }}>
									{row}
								</Col>
								{cols &&
									cols?.map?.((col, colKey) => (
										<Col lg={{ size: 10 / cols.length, ...padding }} xs={{ size: 12, bottom: 10 }} key={colKey}>
											<GridDropdownItem
												fieldName={this.props.field.name}
												rowKey={rowKey}
												colKey={colKey}
												value={values[rowKey][colKey]}
												options={this.state.options}
												onChange={this.onChange.bind(this)}
											/>
										</Col>
									))}
							</Flex>
						))}
				</Col>
			</Flex>
		);
	}
}

export default Grid;
