import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
// import ReCAPTCHA from 'react-google-recaptcha';
import { List } from 'immutable';
import Validator from '../../utils/form-validator';
import { to } from '../../utils/common';
import storage from '../../utils/storage';
// import config from '../../utils/config';
import FormView from '../../lib/FormView';
// import { isLocalhost } from '../../utils/common';
import { Button, Input } from '../../components/Form';
import ResponseAlerts from '../../components/ResponseAlerts';
import { Flex, Col, ToastContainer, Text, Padding, Image, Loading } from '../../components';
import { signInByExternalToken, makeLoginRequestApi } from '../../actions/auth';
import { getCompanyPublicInfo, getOnlyCompanyPublicInfo, setOnlyCompanyPublicInfo } from '../../actions/company-settings';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import imgLogo from '../../assets/images/logo.png';
import classNames from 'classnames';
import cs from '../../components/Form/Input/component.module.scss';
import cn from './index.module.scss';
import PinVerificationModal from './PinVerificationModal/PinVerificationModal';

class Auth extends FormView {
	static propTypes = {
		responseAlerts: PropTypes.instanceOf(List),
		signIn: PropTypes.func.isRequired,
	};

	static defaultProps = {
		responseAlerts: new List(),
	};

	state = {
		form: {
			username: '',
			password: '',
		},
		invalidCompany: false,
		reCaptcha: true, //isLocalhost() ? true : false,
		errors: {},
		companyPublicInfo: {
			allowPublicRegistration: false,
		},
		isLoading: true,
		isLoginProcessing: false,
		allowSystemLogin: true,
		ssoLoginUrl: '',
		passwordInputType: 'password',
		pinVerificationRequired: false,
	};

	validator = new Validator({
		username: {
			required: true,
			message: 'Username is required',
		},
		password: {
			required: true,
			message: 'Password is required',
		},
	});

	isValid() {
		const [isValid, errors] = this.validator.isValid(this.state.form);
		this.setState({
			errors,
		});
		return isValid;
	}

	checkCompany(companyToCheck, backupCompany = null) {
		if(companyToCheck === 'dashboard'){
			return;
		}
		getOnlyCompanyPublicInfo(companyToCheck).then(out => {
			if (out?.payload?.item) {
				storage.set('companyAlias', out?.payload?.item?.alias);
				this.props.setOnlyCompanyPublicInfo(out?.payload?.item);
				this.setState({ invalidCompany: false });
			}
		}).catch(err => {
			if (backupCompany) {
				// this.checkCompany(backupCompany);
				top.location = `/${backupCompany}`;
			} else {
				this.setState({ invalidCompany: true });
			}
		});

	}

	componentWillMount() {
		if (!storage.get('companyAlias')) {
			setTimeout(() => {
				if (this?.props?.match?.params?.companyAlias) {
					this.checkCompany(this?.props?.match?.params?.companyAlias);
				}
			}, 500);
		} else {
			let companyOnSessionStorage = storage.get('companyAlias');
			let topAlias = this.props.match.params.companyAlias;
			this.checkCompany(topAlias, topAlias !== companyOnSessionStorage ? companyOnSessionStorage : null);
		}
	}

	componentWillReceiveProps(nextProps) {
		if (this.props.match.params.companyAlias !== nextProps.match.params.companyAlias && nextProps.match.params.companyAlias) {
			storage.set('companyAlias', nextProps.match.params.companyAlias);
		}

		if (this.props.companyPublicInfo !== nextProps.companyPublicInfo) {
			if (nextProps.companyPublicInfo.authType !== 'system' && !nextProps.companyPublicInfo.allowSystemLogin && nextProps.companyPublicInfo.ssoLoginUrl) {
				top.location = nextProps.companyPublicInfo.ssoLoginUrl;
			} else {
				this.setState({
					isLoading: false,
					allowSystemLogin: nextProps.companyPublicInfo.allowSystemLogin,
					ssoLoginUrl: nextProps.companyPublicInfo.ssoLoginUrl,
				});
			}

			// this.setState({
			//   isLoading:false,
			//   allowSystemLogin:nextProps.companyPublicInfo.allowSystemLogin,
			//   ssoLoginUrl:nextProps.companyPublicInfo.ssoLoginUrl
			// });
		}
	}

	onPinVerificationClose = () => {
		console.log('cannot close without verification');
	};

	verifyPinAndLogin = async (pin) => {
		let form = this.state.form;
		form['pin'] = pin;
		this.setState({ form }, async () => {
			await this.onSubmit();
		});
	};

	onSubmit = async (e) => {
		if (e) {
			e.preventDefault();
		}
		if (this.isValid() && this.state.reCaptcha) {
			this.setState({ isLoginProcessing: true }, async () => {
				let [err, res] = await to(makeLoginRequestApi(this.state.form));
				const { token = null, expired = false, passwordResetUrl = '', pinRequired = false } = res.payload.item;
				// console.log('res ->',res.payload.item);

				if (expired) {
					top.location = passwordResetUrl;
					return;
				}

				if (pinRequired) {
					return this.setState({ pinVerificationRequired: true });
				}

				this.props.signIn(token, this.props.history);
			});
		}
	};

	onChangeReCaptcha = () => {
		this.setState({
			reCaptcha: true,
		});
	};

	onKeyPress = (e) => {
		if (e && e.which !== 13) {
			return;
		}
		this.onSubmit(null);
	};

	showHide = (e) => {
		e.preventDefault();
		e.stopPropagation();
		this.setState({
			passwordInputType: this.state.passwordInputType === 'password' ? 'text' : 'password',
		});
	};

	render() {

		if (this.state?.invalidCompany) {
			return (<div style={{ color:'crimson',position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>Not a valid company url</div>);
		}

		if (this.state.isLoading) {
			return <Loading />;
		}
		const { passwordInputType } = this.state;
		return (
			<Flex
				style={{ width: '100%', maxWidth: '300px', margin: '40px auto', height: '100vh', align: 'center', justify: 'center' }}
				xs={{ justify: 'center', align: 'center', height: '100vh' }}
			>
				<div>
					<Padding padding={{ bottom: 20 }}>
						<Image src={imgLogo} />
					</Padding>
					<ResponseAlerts alerts={this.props.responseAlerts} />
					<form onSubmit={this.onSubmit}>
						<Padding padding={{ bottom: 15 }}>
							<Input
								lg
								bordered
								invalid={this.state.errors.username}
								placeholder="Username"
								onKeyPress={this.onKeyPress}
								value={this.getField('username')}
								onChange={this.onChange('username')}
							/>
						</Padding>
						<Padding padding={{ bottom: 15 }}>
							<input
								className={classNames(cs.root, {
									[cs.bordered]: 'bordered',
									[cs.invalid]: this.state.errors.password,
									[cs.lg]: 'lg',
								})}
								placeholder="Password"
								onKeyPress={this.onKeyPress}
								value={this.getField('password')}
								onChange={this.onChange('password')}
								type={passwordInputType}
							/>

							<span className={cn.eyeIcon} onClick={this.showHide.bind(this)}>
								{passwordInputType === 'password' ? (
									<i>
										<FaEye />
									</i>
								) : (
									<i>
										<FaEyeSlash />
									</i>
								)}
							</span>
						</Padding>
						{/* {!isLocalhost() && (
              <Padding padding={{ bottom: 15 }}>
                <ReCAPTCHA
                  ref="recaptcha"
                  sitekey={config('reCaptcha.key')}
                  onChange={this.onChangeReCaptcha}
                />
              </Padding>
            )} */}
						<Button isLoading={this.state.isLoginProcessing} lg primary onClick={this.onSubmit}>
							Login
						</Button>
						{this.state.ssoLoginUrl && (
							<React.Fragment>
								<a href={this.state.ssoLoginUrl}>
									<Text style={{ textAlign: 'center', marginTop: '20px', marginBottom: '20px' }} textAlign="center" fontSize={16} color="#0052CC">
										Log in with SSO
									</Text>
								</a>
							</React.Fragment>
						)}
						<Padding padding={{ top: 15 }}>
							<Flex xs={{ align: 'center', justify: 'space-between' }}>
								{this.props.companyPublicInfo && this.props.companyPublicInfo.allowPublicRegistration && (
									<Col xs={6}>
										<Link to={`/${this.props.match.params.companyAlias || storage.get('companyAlias')}/register`}>
											<Text fontSize={16} color="#0052CC">
												Register
											</Text>
										</Link>
									</Col>
								)}
								<Col xs={6}>
									<Link to={`/${this.props.match.params.companyAlias || storage.get('companyAlias')}/forgot-password`}>
										<Text fontSize={16} align={this.props.companyPublicInfo && this.props.companyPublicInfo.allowPublicRegistration ? 'right' : 'left'} color="#0052CC">
											Forgot Password?
										</Text>
									</Link>
								</Col>
							</Flex>
							<Padding padding={{ top: 15 }}>
								<Flex xs={{ align: 'center', justify: 'space-between' }}>
									<Col xs={12}>
										<div dangerouslySetInnerHTML={{ __html: '<tinfoil:badge data-key="68c9b119617f9f7ea030b64e54c927399d9ff4a5" data-type="b"></tinfoil:badge>' }}></div>
									</Col>
								</Flex>
							</Padding>
						</Padding>
					</form>
					{this.state.pinVerificationRequired && <PinVerificationModal onComplete={this.verifyPinAndLogin} onClose={this.onPinVerificationClose} />}
					<ToastContainer />
				</div>
			</Flex>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		responseAlerts: state.alerts.get('errors'),
		companyPublicInfo: state.company.get('publicInfo'),
	};
};

const mapDispatchToProps = (dispatch) => ({
	signIn: (token, history) => {
		dispatch(signInByExternalToken(token, history));
	},
	getCompanyPublicInfo: (companyAlias) => {
		dispatch(getCompanyPublicInfo(companyAlias));
	},
	setOnlyCompanyPublicInfo: (info) => {
		dispatch(setOnlyCompanyPublicInfo(info));
	},
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Auth));
