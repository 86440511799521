import createAction from '../utils/createAction';
import internalApi from '../utils/internalApi';

export const FETCH_NOTIFICATION_LOADED = 'FETCH_NOTIFICATION_LOADED';
export const FETCH_NOTIFICATION = 'FETCH_NOTIFICATION';
export const HIDE_NOTIFICATION = 'HIDE_NOTIFICATION';

const fetchNotifications = () => (dispatch) => {
	dispatch(createAction(FETCH_NOTIFICATION_LOADED));

	internalApi.get('notification/list').then((res) => {
		dispatch(createAction(FETCH_NOTIFICATION, res.payload.items));
	});
};

const hideNotification = (id) => (dispatch) => {
	internalApi.put(`notification/${id}`).then((res) => {
		dispatch(createAction(HIDE_NOTIFICATION, id));
	});
};

export { fetchNotifications, hideNotification };
