import React from 'react';
import PropTypes from 'prop-types';

import Padding from '../../Padding';
import Alert from '../Alert';

class ResponseAlerts extends React.Component {
	static propTypes = {
		alerts: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string]))]),
	};

	static defaultProps = {
		alerts: [],
	};

	renderAlerts() {
		const { alerts } = this.props;

		const fill = (alert, index) => <Alert key={index}>{alert.text || alert.message || alert || ''}</Alert>;

		return alerts.map(fill);
	}

	render() {
		return (
			<Padding
				padding={{
					bottom: 15,
				}}
			>
				{this.renderAlerts()}
			</Padding>
		);
	}
}

export default ResponseAlerts;
