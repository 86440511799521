import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './component.module.scss';

class Textarea extends React.Component {
	onChange = (e) => {
		const { name, value } = e.target;
		this.props.onChange(value, name);
	};

	shouldComponentUpdate(nextProps, nextState) {
		if (this.props.value !== nextProps.value) {
			return true;
		}

		if (this.props.field && this.props.field.name && this.props.field.name !== nextProps.field.name) {
			return true;
		}

		return false;
	}

	onClick = (e) => {
		const { name, value } = e.target;
		if (this.props && this.props.onClick) {
			this.props.onClick(name, value);
		}
	};

	render() {
		const { field, value, placeholder, readonly, bordered } = this.props;

		// console.log(field, " Text area rendered");
		return (
			<div className={this.props.className}>
				<textarea
					className={classNames(styles.root, {
						[styles.bordered]: bordered,
						[styles.invalid]: this.props.invalid,
						[styles.readonly]: readonly,
						[styles.normaltextAreaHeight]: this.props.normalHeight,
					})}
					name={field.name}
					value={value}
					placeholder={placeholder}
					readOnly={readonly}
					onChange={this.onChange}
					onClick={this.onClick}
				/>
			</div>
		);
	}
}

Textarea.propTypes = {
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
	field: PropTypes.shape({
		name: PropTypes.string,
	}),
	placeholder: PropTypes.string,
	invalid: PropTypes.string,
	readonly: PropTypes.bool,
	bordered: PropTypes.bool,
	onChange: PropTypes.func,
	onClick: PropTypes.func,
	normalHeight: PropTypes.bool,
};

Textarea.defaultProps = {
	placeholder: '',
	invalid: null,
	readonly: false,
	bordered: false,
	field: {
		name: '',
		type: 'String',
	},
	onChange: () => false,
	normalHeight: false,
};

export default Textarea;
