import { Map, List } from 'immutable';

import * as actionTypes from '../actions/help';

const initialState = new Map({
	helpCategories: new Map({
		isLoaded: false,
		items: new List(),
		count: 0,
	}),
	helpQuestions: new Map({
		isLoaded: false,
		items: new List(),
		count: 0,
	}),
});

export default (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.FETCH_HELP_CATEGORY: {
			return state.update(
				'helpCategories',
				() =>
					new Map({
						isLoaded: true,
						items: new List(action.payload.rows),
						count: action.payload.count,
					}),
			);
		}
		case actionTypes.FETCH_HELP_CATEGORY_LOADED: {
			const { loaded = false } = action.payload;

			return state.updateIn(['helpCategories', 'isLoaded'], () => loaded);
		}
		case actionTypes.FETCH_HELP_QUESTIONS: {
			return state.update(
				'helpQuestions',
				() =>
					new Map({
						isLoaded: true,
						items: new List(action.payload.rows),
						count: action.payload.count,
					}),
			);
		}
		case actionTypes.FETCH_HELP_QUESTIONS_LOADED: {
			const { loaded = false } = action.payload;

			return state.updateIn(['helpQuestions', 'isLoaded'], () => loaded);
		}
		default:
			return state;
	}
};
