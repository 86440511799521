import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import storage from '../../utils/storage';
import qs from 'query-string';

import Loading from './../../components/Loading';

import FormView from '../../lib/FormView';

import { signInByExternalToken } from '../../actions/auth';
import { getCompanyPublicInfo } from '../../actions/company-settings';

class AutoLoginByToken extends FormView {
	static propTypes = {
		signInByExternalToken: PropTypes.func.isRequired,
	};

	state = {
		companyPublicInfo: {
			allowPublicRegistration: false,
		},
	};

	componentWillMount() {
		if (!storage.get('companyAlias')) {
			setTimeout(() => {
				if (this.props.match.params.companyAlias) {
					storage.set('companyAlias', this.props.match.params.companyAlias);
					this.props.getCompanyPublicInfo(storage.get('companyAlias'));
				}
			}, 1500);
		} else {
			let currentAlias = storage.get('companyAlias');
			let topAlias = this.props.match.params.companyAlias;
			if (topAlias !== currentAlias && topAlias !== 'dashboard') {
				storage.set('companyAlias', topAlias);
			}
			this.props.getCompanyPublicInfo(topAlias);
		}
	}

	componentWillReceiveProps(nextProps) {
		if (this.props.match.params.companyAlias !== nextProps.match.params.companyAlias && nextProps.match.params.companyAlias) {
			storage.set('companyAlias', nextProps.match.params.companyAlias);
		}
	}

	componentDidMount() {
		setTimeout(() => {
			const { t: token } = qs.parse(this.props.location.search);

			this.props.signInByExternalToken(token, this.props.history);
		}, 1700);
	}

	render() {
		return <Loading />;
	}
}

const mapStateToProps = (state) => ({
	companyPublicInfo: state.company.get('publicInfo'),
});

const mapDispatchToProps = (dispatch) => ({
	signInByExternalToken: (token, history) => {
		dispatch(signInByExternalToken(token, history));
	},
	getCompanyPublicInfo: (companyAlias) => {
		dispatch(getCompanyPublicInfo(companyAlias));
	},
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AutoLoginByToken));
