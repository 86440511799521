import createAction from '../utils/createAction';
import internalApi from '../utils/internalApi';

export const FETCH_MEMBERS = 'FETCH_MEMBERS';
export const ADD_MEMBER = 'ADD_MEMBER';
export const UPDATE_MEMBER = 'UPDATE_MEMBER';
export const REMOVE_MEMBER = 'REMOVE_MEMBER';

const fetchMembers = (teamId) => (dispatch) => {
	if (teamId) {
		internalApi.get(`teams/members/${teamId}/list`).then(({ payload }) => {
			dispatch(createAction(FETCH_MEMBERS, payload.items));
		});
	} else {
		dispatch(createAction(FETCH_MEMBERS, []));
	}
};

const addMember = () =>
	createAction(ADD_MEMBER, {
		userId: '',
		role: '',
		responsibility: '',
	});

const updateMember = (rest) => createAction(UPDATE_MEMBER, rest);

const removeMember = (key) => createAction(REMOVE_MEMBER, key);

const saveTeamMembers = (teamId) => (dispatch, getState) => {
	const state = getState();

	internalApi.post('teams/members/save', {
		teamId,
		members: state.teams.get('members').toJS(),
	});
};

export { fetchMembers, addMember, updateMember, removeMember, saveTeamMembers };
