import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';

import styles from './component.module.scss';

import imgNoImage from '../../assets/images/no-library.png';

const build_path = (...args) => {
	return args
		.map((part, i) => {
			if (i === 0) {
				return part.trim().replace(/[\/]*$/g, '');
			} else {
				return part.trim().replace(/(^[\/]*|[\/]*$)/g, '');
			}
		})
		.filter((x) => x.length)
		.join('/');
};

class Image extends React.Component {
	render() {
		let { src } = this.props;
		const { className, circle, stat, imageProps = {} } = this.props;

		if (stat) {
			src = build_path('/static/images/', src);
		}

		return (
			<img
				className={classNames(className, styles.root, {
					[styles.circle]: circle,
				})}
				alt=""
				src={src || imgNoImage}
				{...imageProps}
				style={this.props.style}
			/>
		);
	}
}

Image.propTypes = {
	src: PropTypes.string,
	className: PropTypes.string,
	circle: PropTypes.bool,
	stat: PropTypes.bool,
	imageProps: PropTypes.shape({}),
	style: PropTypes.shape({}),
};

Image.defaultProps = {
	className: '',
	src: null,
	circle: false,
	stat: false,
	imageProps: {},
	style: {},
};

export default Image;
