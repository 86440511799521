import { Map, List } from 'immutable';
import * as actionTypes from '../actions/library';

const initialState = new Map({
	libraries: new Map({
		isLoaded: false,
		data: new List(),
	}),
	currentLibrary: new Map({
		isLoaded: false,
		fields: [],
		records: new List(),
		library: {},
		total: 0,
	}),
	currentLibraryWithout: new Map({
		isLoaded: false,
		fields: [],
		records: new List(),
		library: {},
		total: 0,
	}),
	individualSearchFields: {},
});

export default (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.FETCH_LIBRARY_LOADED: {
			const { loaded = false } = action.payload;

			return state.updateIn(['libraries', 'isLoaded'], () => loaded);
		}
		case actionTypes.FETCH_LIBRARY: {
			const libraries = action.payload;

			return state.update(
				'libraries',
				() =>
					new Map({
						isLoaded: true,
						data: new List(libraries.records),
						total: action.payload.total || state.get('libraries').get('total'),
					}),
			);
		}
		case actionTypes.FETCH_LIBRARY_WITH_FIELDS_LOADED: {
			const { loaded = false } = action.payload;

			return state.updateIn(['currentLibrary', 'isLoaded'], () => loaded);
		}
		case actionTypes.FETCH_LIBRARY_WITH_FIELDS: {
			const { fields, records, library, total } = action.payload;

			let newFields = [];
			let currentFields = state.get('currentLibrary').get('fields');
			if (fields && fields.length) {
				newFields = fields.map((f) => {
					let fieldToUpdate = {};
					if (['modifiedBy', 'approvers', 'participants'].indexOf(f.id) !== -1) {
						let fieldExists = currentFields.filter((cf) => cf.id === f.id).shift();
						//console.log("fieldExists:",fieldExists);
						if (fieldExists) {
							fieldToUpdate.hidden = fieldExists.hidden;
						}
					}
					return { ...f, ...fieldToUpdate };
				});
			}

			return state.update(
				'currentLibrary',
				() =>
					new Map({
						isLoaded: true,
						fields: newFields,
						records: new List(records.map((item) => new Map(item))),
						library,
						total,
					}),
			);
		}
		case actionTypes.TOGGLE_CURRENT_LIBRARY_FIELD: {
			const { fieldId } = action.payload;

			let currentFields = state
				.get('currentLibrary')
				.get('fields')
				.map((f) => {
					let fieldToUpdate = {};
					if (f.id === fieldId) {
						fieldToUpdate.hidden = !f.hidden;
					}
					return { ...f, ...fieldToUpdate };
				});

			let currentFieldsWithout = state
				.get('currentLibraryWithout')
				.get('fields')
				.map((f) => {
					let fieldToUpdate = {};
					if (f.id === fieldId) {
						fieldToUpdate.hidden = !f.hidden;
					}
					return { ...f, ...fieldToUpdate };
				});

			state = state.updateIn(['currentLibrary', 'fields'], () => currentFields);

			return state.updateIn(['currentLibraryWithout', 'fields'], () => currentFieldsWithout);
		}
		case actionTypes.SET_INDIVIDUAL_SEARCH_FIELD: {
			const { libraryId, individualSearchFields } = action.payload;
			let libraries = state.get('individualSearchFields');
			if (libraries && libraryId) {
				libraries[libraryId] = individualSearchFields;
			}

			return state.update('individualSearchFields', () => libraries);
		}
		case actionTypes.FETCH_LIBRARY_WITHOUT_FIELDS: {
			const { fields, records, library, total } = action.payload;

			let newFields = [];
			let currentFields = state.get('currentLibraryWithout').get('fields');
			if (fields && fields.length) {
				newFields = fields.map((f) => {
					let fieldToUpdate = {};
					if (['modifiedBy', 'approvers', 'participants'].indexOf(f.id) !== -1) {
						let fieldExists = currentFields.filter((cf) => cf.id === f.id).shift();
						//console.log("fieldExists:",fieldExists);
						if (fieldExists) {
							fieldToUpdate.hidden = fieldExists.hidden;
						}
					}
					return { ...f, ...fieldToUpdate };
				});
			}

			return state.update(
				'currentLibraryWithout',
				() =>
					new Map({
						isLoaded: true,
						fields: newFields,
						records: new List(records.map((item) => new Map(item))),
						library,
						total,
					}),
			);
		}
		default:
			return state;
	}
};
