import React from 'react';
import PropTypes from 'prop-types';

import { Link } from 'react-router-dom';
import Flex, { Col } from '../Flex';
import Icon from 'react-fa';
import cs from './component.module.scss';
import { getTranslatedText } from 'utils/common';

class BreadCrumbs extends React.Component {
	static propTypes = {
		list: PropTypes.arrayOf(PropTypes.shape({})),
	};

	static defaultProps = {
		list: [],
	};

	render() {
		const { list } = this.props;

		return (
			<Flex>
				<Col xs={{ size: 12, bottom: 0, left: 20 }}>
					<nav className={cs.root} style={{ lineHeight: '12px' }}>
						<Link to="/dashboard">
							{' '}
							<Icon style={{ cursor: 'pointer' }} name="home" /> {getTranslatedText('dashBoard.title', 'DashBoard')}
						</Link>
						{list.map((item, key) => (
							<span key={key}>
								<span className={cs.page}>/</span>
								{item.url ? (
									<Link to={`/${item.url}`} className={cs.page}>
										{item.name}
									</Link>
								) : (
									<span className={cs.page}>{item.name}</span>
								)}
							</span>
						))}
					</nav>
				</Col>
			</Flex>
		);
	}
}

export default BreadCrumbs;
