import fieldTypes from '../constants/fieldTypes';
export default {
	approvalStatuses: {
		inprogress: 'In Progress',
		waiting_for_approval: 'Waiting for Approval',
		approved: 'Approved',
		rejected: 'Rejected',
	},
	fieldTypes: {
		checkbox: 'Checkbox',
		date: 'Date',
		dropdown: 'Drop Down',
		file: 'File',
		grid: 'Grid Drop Down',
		gridTable: 'Grid Table',
		gridText: 'Grid Text',
		calculatedAverage: 'Calculated Average',
		lookup: 'Library Lookup',
		number: 'Number',
		radio: 'Radio',
		table: 'Table',
		textarea: 'Text Area',
		text: 'Text Box',
		time: 'Time',
		wysiwyg: 'Wysiwyg Editor',
		[fieldTypes.hyperlink]: 'Hyperlink',
		[fieldTypes.recordSelector]: 'Record Selector',
		[fieldTypes.summitDownloadBCPlan]: 'Download BC Plan Button',
		[fieldTypes.cprodemoDownloadBCPlan]: 'Download BC Plan Button',
		[fieldTypes.cprodemoDownloadDisasterRecoveryPlan]: 'Download Disaster Recovery Plan Button',
		[fieldTypes.cprodemoDownloadRiskAssessmentPlan]: 'Download Risk Assessment Plan Button',
		[fieldTypes.ciDownloadBCPlan]: 'Download BC Plan Button',
		[fieldTypes.swbcDownloadBCPlan]: 'Download BC Plan Button',
		[fieldTypes.nucleaPreBIAModule]: 'Nuclea Pre-BIA Module',
		[fieldTypes.nucleaBIAModuleWhatSummary]: 'Nuclea BIA Module What Summary',
		[fieldTypes.nucleaBIASubProcessSummary]: 'Nuclea BIA Sub-Process Summary',
		[fieldTypes.nucleaPCODownloadButton]: 'Nuclea PCO Download Button',
		[fieldTypes.nucleaPCOSubProcessList]: 'Nuclea PCO Sub Process List',
		[fieldTypes.nucleaBIAProcessDetails]: 'Nuclea BIA Process Details',
		[fieldTypes.nucleaBIALocationDetails]: 'Nuclea BIA Location Details',
	},
	riskMatrixValues: [
		{
			value: 'low',
			label: 'Low',
		},
		{
			value: 'medium',
			label: 'Medium',
		},
		{
			value: 'high',
			label: 'High',
		},
		{
			value: 'na',
			label: 'N/A',
		},
	],
	task: {
		statusTypes: {
			inprogress: 'In Progress',
			complete: 'Complete',
			incomplete: 'Incomplete',
		},
		priorityTypes: {
			low: 'Low',
			medium: 'Medium',
			high: 'High',
		},
	},
	report: {
		fields: [
			{
				id: 'name',
				text: 'Name',
				value: 'name',
				hidden: false,
			},
			{
				id: 'print',
				text: 'Print',
				order: false,
				hidden: false,
			},
			{
				id: 'fields',
				text: 'Fields',
				order: false,
				hidden: false,
			},
			{
				id: 'edit',
				text: 'Edit',
				order: false,
				hidden: false,
			},
			{
				id: 'modifiedById',
				text: 'Modified By',
				value: 'modifiedById',
				hidden: false,
			},
			{
				id: 'updatedAt',
				text: 'Modified On',
				value: 'updatedAt',
				hidden: false,
			},
			{
				id: 'createdAt',
				text: 'Created On',
				value: 'createdAt',
				hidden: false,
			},
		],
		reportOrientation: {
			portrait: 'Portrait',
			landscape: 'Landscape',
		},
		columnOrientation: {
			vertical: 'Vertical',
			horizontal: 'Horizontal',
		},
		isOnNewPage: {
			yes: 'Yes',
			no: 'No',
		},
		isTemplate: {
			yes: 'Yes',
			no: 'No',
		},
	},
	risks: {
		fields: [
			{
				id: 'assessment',
				text: 'Risk Assessment Name',
				value: 'assessment',
				hidden: false,
			},
			{
				id: 'description',
				text: 'Description',
				value: 'description',
				hidden: false,
			},
			{
				id: 'status',
				text: 'Status',
				value: 'status',
				hidden: false,
			},
			{
				id: 'modifiedById',
				text: 'Modified By',
				value: 'modifiedById',
				hidden: false,
			},
			{
				id: 'updatedAt',
				text: 'Modified On',
				value: 'updatedAt',
				hidden: false,
			},
		],
	},
	callChain: {
		fields: [
			{
				id: 'name',
				text: 'Name',
				value: 'name',
				hidden: false,
			},
			{
				id: 'description',
				text: 'Description',
				value: 'description',
				hidden: false,
			},
			{
				id: 'modifiedById',
				text: 'Modified By',
				value: 'modifiedById',
				hidden: false,
			},
			{
				id: 'updatedAt',
				text: 'Modified On',
				value: 'updatedAt',
				hidden: false,
			},
			{
				id: 'createdAt',
				text: 'Created On',
				value: 'createdAt',
				hidden: false,
			},
			// {
			//   id: 'accessMembers',
			//   text: 'Access Members',
			//   value: 'accessMembers',
			//   hidden: false,
			// },
		],
	},
	tasks: {
		fields: [
			{
				id: 'title',
				text: 'Name',
				value: 'title',
				hidden: false,
			},
			{
				id: 'priority',
				text: 'Priority',
				value: 'priority',
				hidden: false,
			},
			{
				id: 'status',
				text: 'Status',
				value: 'status',
				hidden: false,
			},
			{
				id: 'assignee',
				text: 'Assignee',
				value: 'assignee',
				align: 'left',
				order: false,
				hidden: false,
			},
			{
				id: 'modifiedById',
				text: 'Modified By',
				value: 'modifiedById',
				hidden: false,
			},
			{
				id: 'updatedAt',
				text: 'Modified On',
				value: 'updatedAt',
				hidden: false,
			},
			{
				id: 'createdAt',
				text: 'Created On',
				value: 'createdAt',
				hidden: false,
			},
		],
	},
	todo: {
		fields: [
			{
				id: 'title',
				text: 'Title',
				value: 'title',
				hidden: false,
			},
			{
				id: 'status',
				text: 'Status',
				value: 'status',
				hidden: false,
			},
			{
				id: 'modifiedById',
				text: 'Modified By',
				value: 'modifiedById',
				hidden: false,
			},
			{
				id: 'updatedAt',
				text: 'Modified On',
				value: 'updatedAt',
				hidden: false,
			},
			{
				id: 'createdAt',
				text: 'Created On',
				value: 'createdAt',
				hidden: false,
			},
		],
	},
	roles: {
		fields: [
			{
				id: 'role',
				text: 'Role',
				value: 'role',
				hidden: false,
			},
			{
				id: 'updatedAt',
				text: 'Modified On',
				value: 'updatedAt',
				hidden: false,
			},
		],
	},
	users: {
		fields: [
			{
				id: 'username',
				text: 'Username',
				value: 'username',
				hidden: false,
			},
			{
				id: 'firstName',
				text: 'First Name',
				value: 'firstName',
				hidden: false,
			},
			{
				id: 'lastName',
				text: 'Last Name',
				value: 'lastName',
				hidden: false,
			},
			{
				id: 'email',
				text: 'E-mail',
				value: 'email',
				hidden: false,
			},
			{
				id: 'role',
				text: 'Role',
				value: 'role',
				hidden: false,
			},
			{
				id: 'phone',
				text: 'Phone',
				value: 'phone',
				hidden: false,
			},
			{
				id: 'isActive',
				text: 'User status',
				value: 'isActive',
				hidden: false,
			},
			{
				id: 'isLocked',
				text: 'Locked',
				value: 'isLocked',
				hidden: false,
			},
			{
				id: 'updatedAt',
				text: 'Updated On',
				value: 'updatedAt',
				hidden: false,
			},
			{
				id: 'createdAt',
				text: 'Created On',
				value: 'createdAt',
				hidden: false,
			},
		],
	},
	authSSOTypes: [
		{
			value: 'system',
			label: 'CPRO Default',
		},
		{
			value: 'saml',
			label: 'SAML',
		},
	],
};
