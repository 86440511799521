import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import cs from './component.module.scss';

const Table = (props) => (
	<table className={classNames(cs.root, [props.className])} cellSpacing="0" cellPadding="0">
		{props.children}
	</table>
);

Table.propTypes = {
	children: PropTypes.node.isRequired,
};

Table.defaultProps = {};

export default Table;
