import React from 'react';

class Main extends React.PureComponent {
	render() {
		const style = {
			marginLeft: this.props.isHidden ? '80px' : '250px',
			transition: 'margin-left 1s',
			WebkitTransition: 'margin-left 1s',
		};
		return <div style={style}>{this.props.children}</div>;
	}
}

export default Main;
