import _ from 'lodash';
import createAction from '../utils/createAction';
import internalApi from '../utils/internalApi';
import { arrayMove } from '../utils/arrayHelpers';

import { clearAlerts } from './alerts';

export const FETCH_LIBRARY_ITEMS_LOADED = 'FETCH_LIBRARY_ITEMS_LOADED';
export const FETCH_LIBRARY_ITEMS = 'FETCH_LIBRARY_ITEMS';

export const FETCH_LIBRARY_ITEMS_BY_LIBRARY_ID_LOADING = 'FETCH_LIBRARY_ITEMS_BY_LIBRARY_ID_LOADING';
export const FETCH_LIBRARY_ITEMS_BY_LIBRARY_ID = 'FETCH_LIBRARY_ITEMS_BY_LIBRARY_ID';

const fetchLibraryItems = (libraryId) => (dispatch) => {
	dispatch(createAction(FETCH_LIBRARY_ITEMS_LOADED));

	internalApi.get(`library/fields/${libraryId}`).then((res) => {
		dispatch(createAction(FETCH_LIBRARY_ITEMS, res.payload.items));
	});
};

const fetchLibraryItemsByLibraryId = (libraryId) => (dispatch) => {
	dispatch(createAction(FETCH_LIBRARY_ITEMS_BY_LIBRARY_ID_LOADING));

	internalApi.get(`library/fields/${libraryId}`).then((res) => {
		dispatch(createAction(FETCH_LIBRARY_ITEMS_BY_LIBRARY_ID, { items: res.payload.items, libraryId }));
	});
};

const getLibraryItemsByLibraryId = (libraryId) => {
	return internalApi.get(`library/fields/${libraryId}`).then((res) => {
		return res.payload.items;
	});
};

const changeLibraryFieldStatus =
	({ id, isActive }) =>
	(dispatch) => {
		dispatch(clearAlerts());

		return internalApi.post('library/fields/toggle-active', { id, isActive });
	};

const fieldExists = (libraryId, displayName) => {
	return internalApi.post(`library/fields/${libraryId}/fieldExists`, { displayName }).then((item) => item.payload.item.exists);
};

const sortFields = (libraryId, dragIndex, hoverIndex) => (dispatch, getState) => {
	const state = getState();

	const sorted = arrayMove(state.libraryItems.get('fields').get('data').toJS(), dragIndex, hoverIndex);

	internalApi
		.post(`library/fields/${libraryId}/sort`, {
			fields: sorted.map((i) => i.id),
		})
		.then(() => {
			dispatch(createAction(FETCH_LIBRARY_ITEMS, sorted));
		});
};

const fetchField = (libraryId, id) => () => internalApi.get(`library/fields/${libraryId}/one/${id}`);

const saveField = (libraryId, data) => (dispatch) => {
	dispatch(clearAlerts());

	let url = `library/fields/${libraryId}/create`;
	let params = data;
	if (data.id) {
		url = `library/fields/${libraryId}/update/${data.id}`;
		params = _.omit(data, 'id');
	}

	return internalApi.post(url, params);
};

const onColumnToggle = (fieldId, value) => (dispatch) => {
	dispatch(clearAlerts());

	return internalApi.post(`library/fields/${fieldId}/hidden-toggle`, {
		value: !value,
	});
};

const deleteField = (libraryId, id) => () => internalApi.post(`library/fields/${libraryId}/delete/${id}`);

export {
	fetchLibraryItems,
	fetchLibraryItemsByLibraryId,
	getLibraryItemsByLibraryId,
	saveField,
	sortFields,
	fetchField,
	deleteField,
	onColumnToggle,
	changeLibraryFieldStatus,
	fieldExists,
};
