import { createContext, useEffect, useState } from 'react';
import { IntlProvider } from 'react-intl';
import Portuguese from './lang/pt.json';
import English from './lang/en.json';
import Spanish from './lang/es.json';
import { useSelector } from 'react-redux';

export const Context = createContext();

const local = navigator.language;
let lang;


const LangWrapper = ({ children }) => {
  const { language } = useSelector((state) => state?.user?.get?.('user').get?.('item'))
  const [locale, setLocale] = useState(local);
  const [messages, setMessages] = useState(lang);

  useEffect(() => {
		if(language){
			selectLanguage(language)
		}
  }, [language])

  const langList = {
    'Portuguese': 'pt',
    'Spanish': 'es',
    'English': 'en-GB'
  }

  function selectLanguage(e) {
    const newLocale = e;
    setLocale(newLocale);
    if (newLocale === 'pt') {
      setMessages(Portuguese);
    } else if (newLocale === 'es') {
      setMessages(Spanish);
    } else {
      setMessages(English);
    }
  }


  return (
    <Context.Provider value={{ langList, locale, selectLanguage }}>
      <IntlProvider messages={messages} locale={locale}>
        {children}
      </IntlProvider>
    </Context.Provider>
  );
}


export default LangWrapper;