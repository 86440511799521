import { Map, List } from 'immutable';
import * as actionTypes from '../actions/todo';
import storage from '../utils/storage';
import config from '../utils/config';

const initialState = new Map({
	todo: new Map({
		isLoaded: false,
		items: new List(),
		count: 0,
	}),
	checklist: new Map({
		isLoaded: false,
		items: new List(),
	}),
	fields: storage.get('todoFields') || config('todo.fields'),
});

export default (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.FETCH_TODO: {
			return state.update(
				'todo',
				() =>
					new Map({
						isLoaded: true,
						items: new List(action.payload.rows),
						count: action.payload.count,
					}),
			);
		}
		case actionTypes.FETCH_TODO_LOADED: {
			const { loaded = false } = action.payload;

			return state.updateIn(['todo', 'isLoaded'], () => loaded);
		}
		case actionTypes.FETCH_CHECKLIST: {
			return state.update(
				'checklist',
				() =>
					new Map({
						isLoaded: true,
						items: new List(action.payload),
					}),
			);
		}
		case actionTypes.FETCH_CHECKLIST_LOADED: {
			const { loaded = false } = action.payload;

			return state.updateIn(['checklist', 'isLoaded'], () => loaded);
		}
		case actionTypes.SET_TODO_FIELDS: {
			return state.set('fields', action.payload);
		}
		default:
			return state;
	}
};
