import _ from 'lodash';
import createAction from '../utils/createAction';
import internalApi from '../utils/internalApi';
import { clearAlerts } from './alerts';

export const FETCH_HELP_CATEGORY_LOADED = 'FETCH_HELP_CATEGORY_LOADED';
export const FETCH_HELP_CATEGORY = 'FETCH_HELP_CATEGORY';
export const FETCH_HELP_QUESTIONS_LOADED = 'FETCH_HELP_QUESTIONS_LOADED';
export const FETCH_HELP_QUESTIONS = 'FETCH_HELP_QUESTIONS';

const fetchHelpCategories =
	({ order = {}, name, limit, offset, search = '', individualSearchFields = [] } = {}) =>
	(dispatch) => {
		dispatch(createAction(FETCH_HELP_CATEGORY_LOADED));

		const queryParams = {
			orderBy: order.key,
			sortBy: order.in,
			name: name || '',
			limit: limit || 10000,
			offset: offset || 0,
			search,
			individualSearchFields,
		};

		return internalApi.get('help/help-category/list', { params: queryParams }).then((res) => {
			dispatch(createAction(FETCH_HELP_CATEGORY, res.payload.items));
		});
	};

const getHelpCategories =
	({ order = {}, name, limit, offset, search = '', individualSearchFields = [] } = {}) =>
	(dispatch) => {
		const queryParams = {
			orderBy: order.key,
			sortBy: order.in,
			name: name || '',
			limit: limit || 10000,
			offset: offset || 0,
			search,
			individualSearchFields,
		};

		return internalApi.get('help/help-category/list', { params: queryParams });
	};

const fetchHelpQuestions =
	({ order = {}, name, limit, offset, individualSearchFields = [] } = {}) =>
	(dispatch) => {
		dispatch(createAction(FETCH_HELP_QUESTIONS_LOADED));

		const queryParams = {
			orderBy: order.key,
			sortBy: order.in,
			name,
			limit,
			offset,
			individualSearchFields,
		};

		internalApi.get('help/help-questions/list', { params: queryParams }).then((res) => {
			dispatch(createAction(FETCH_HELP_QUESTIONS, res.payload.items));
		});
	};

const fetchHelpCategoryById = (id) => (dispatch) => {
	dispatch(clearAlerts());

	return internalApi.get(`help/help-category/${id}`);
};

const fetchHelpQuestionById = (id) => (dispatch) => {
	dispatch(clearAlerts());

	return internalApi.get(`help/help-question/${id}`);
};

const fetchHelpQuestionByCategoryId = (categoryId) => (dispatch) => {
	dispatch(clearAlerts());

	return internalApi.get(`help/find-by-category-id/${categoryId}`);
};

const saveHelpCategory = (data, id) => (dispatch) => {
	dispatch(clearAlerts());

	return internalApi.post(id ? `help/help-category/update/${id}` : `help/help-category/create`, _.omit(data, 'id'));
};

const saveHelpQuestion = (data, id) => (dispatch) => {
	dispatch(clearAlerts());

	return internalApi.post(id ? `help/help-question/update/${id}` : `help/help-question/create`, _.omit(data, ['id', 'category']));
};

const deleteHelpCategories = (ids) => () =>
	internalApi.post('help/help-categories/delete', {
		ids,
	});

const deleteHelpQuestions = (ids) => () =>
	internalApi.post('help/help-questions/delete', {
		ids,
	});

export {
	fetchHelpCategories,
	fetchHelpQuestions,
	fetchHelpCategoryById,
	fetchHelpQuestionById,
	fetchHelpQuestionByCategoryId,
	saveHelpCategory,
	saveHelpQuestion,
	deleteHelpCategories,
	deleteHelpQuestions,
	getHelpCategories,
};
