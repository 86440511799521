import createAction from '../utils/createAction';
import internalApi from '../utils/internalApi';
import { clearAlerts } from './alerts';
import exportCSVFile from '../utils/exportCSVFile';
import storage from '../utils/storage';

export const FETCH_ROLES_LOADED = 'FETCH_ROLES_LOADED';
export const FETCH_ROLES = 'FETCH_ROLES';
export const FETCH_CURRENT_USER_PERMISSIONS_LOADED = 'FETCH_CURRENT_USER_PERMISSIONS_LOADED';
export const FETCH_CURRENT_USER_PERMISSIONS = 'FETCH_CURRENT_USER_PERMISSIONS';
export const SET_ROLES_FIELDS = 'SET_ROLES_FIELDS';

const fetchRoles =
	({ search = '', page = 1, limit = 50, individualSearchFields = [] } = {}) =>
	(dispatch) => {
		dispatch(createAction(FETCH_ROLES_LOADED));
		const queryParams = {
			search,
			page,
			limit,
			individualSearchFields,
		};
		internalApi.get('role/list', { params: queryParams }).then((res) => {
			dispatch(createAction(FETCH_ROLES, res.payload.items));
		});
	};

const fetchUserPermissions = () => (dispatch) => {
	dispatch(createAction(FETCH_CURRENT_USER_PERMISSIONS_LOADED));

	internalApi.get('role/permission/me').then((res) => {
		dispatch(createAction(FETCH_CURRENT_USER_PERMISSIONS, res.payload.items));
	});
};

const exportCSV = (checkedItems) => () => {
	internalApi
		.get('role/export', {
			params: { checkedItems: checkedItems.join(',') },
		})
		.then((res) => {
			exportCSVFile(res.payload.item, 'Roles');
		});
};

const getRoleById = (id) => () => internalApi.get(`role/one/${id}`);

const fetchPermissions = () => () => internalApi.get('role/permission/list');

const saveRole = (data) => (dispatch) => {
	dispatch(clearAlerts());

	return internalApi.post(data.id ? `role/update/${data.id}` : `role/create`, data);
};

const deleteRoles = (ids) => () =>
	internalApi.post('role/delete-multiple', {
		ids,
	});

const onColumnToggleRoles = (fieldId, value) => (dispatch, getState) => {
	const state = getState();
	const fields = state.roles.get('fields');

	const newFields = fields.map((field) => {
		if (field.id === fieldId) {
			return { ...field, hidden: !value };
		}
		return { ...field };
	});

	storage.set('rolesFields', newFields);

	dispatch(createAction(SET_ROLES_FIELDS, newFields));
};

export { fetchRoles, fetchUserPermissions, exportCSV, getRoleById, fetchPermissions, saveRole, deleteRoles, onColumnToggleRoles };
