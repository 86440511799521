import { Map, List } from 'immutable';
import * as actionTypes from '../actions/dashboard';

const initialState = new Map({
	widgetsLoaded: false,
	widgets: new List(),
});

export default (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.FETCH_DASHBOARD_WIDGETS_LOADED: {
			const { loaded = false } = action.payload;

			return state.set('widgetsLoaded', false);
		}
		case actionTypes.FETCH_DASHBOARD_WIDGETS: {
			const widgets = action.payload;

			return state.update('widgets', () => new List(widgets));
		}
		case actionTypes.TOGGLE_LIBRARY_AS_WIDGET: {
			const { libraryId } = action.payload;
			const updatedData = state.get('widgets').map((l) => {
				if (l.id && l.id == libraryId) {
					l.showAsWidget = !l.showAsWidget;
				}
				return l;
			});
			return state.updateIn(['widgets'], () => updatedData);
		}
		default:
			return state;
	}
};
