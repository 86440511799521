import React from 'react';
import PropTypes from 'prop-types';

import AsyncSelect from 'react-select/lib/Async';
import { parseLookupValue, parseLookupKeys } from './../../../utils/common';

import { isImmutable, List } from 'immutable';
import _ from 'lodash';

class Select2Async extends React.Component {
	static propTypes = {
		field: PropTypes.shape({
			name: PropTypes.string,
			type: PropTypes.string,
		}),
		value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.shape({})]),
		promiseOptions: PropTypes.func.isRequired,
		readOnly: PropTypes.bool,
		clearable: PropTypes.bool,
		isMulti: PropTypes.bool,
		onChange: PropTypes.func.isRequired,
	};

	static defaultProps = {
		field: {
			name: '',
			type: 'String',
		},
		value: '',
		readOnly: false,
		clearable: false,
		isMulti: false,
	};

	timerHandle = null;

	constructor(props) {
		super(props);

		this.state = {
			selectedValues: [],
		};
	}

	componentDidMount() {
		this.getData(this.props);
	}

	componentWillUnmount() {
		if (this.timerHandle) {
			clearTimeout(this.timerHandle);
		}
	}

	getData(props) {
		let value = props.value || '';
		if (value && typeof value === 'string' && value.trim()) {
			if (props.promiseOptions && value.indexOf('lookup@') === -1) {
				((nProps) => {
					if (this.timerHandle) {
						clearTimeout(this.timerHandle);
					}

					this.timerHandle = setTimeout(() => {
						nProps.promiseOptions(nProps.value.split(',')).then((response) => {
							this.setState({
								selectedValues: response,
							});
						});
					}, 100);
				})(props);
			} else {
				let selectedValues = value.split(',').map((v) => {
					return {
						value: v,
						label: parseLookupValue(v),
					};
				});

				this.setState({
					selectedValues,
				});

				// Once cached data is set lets make an actual query to see if something changed and update accordingly.
				if (props.promiseOptions && value.indexOf('lookup@') !== -1) {
					((nProps) => {
						if (this.timerHandle) {
							clearTimeout(this.timerHandle);
						}

						this.timerHandle = setTimeout(() => {
							nProps.promiseOptions(parseLookupKeys(nProps.value)).then((response) => {
								// console.log('res -> ',response);
								this.setState({
									selectedValues: response,
								});
							});
						}, 100);
					})(props);
				}
			}
		} else if (value && typeof value === 'object') {
			let selectedValues = isImmutable(value) ? value.toJS() : value;

			this.setState({
				selectedValues,
			});
		}
	}

	shouldComponentUpdate(nextProps, nextState) {
		//Temp disabled

		// //console.log("1. this.props.value-> ",this.props.value );
		// console.log("2. this.state.selectedValues-> ",this.state.selectedValues );
		// console.log("3. nextPropps.value-> ",nextProps.value);
		// if(this.props.value !== nextProps.value){
		//   return true;
		// }

		// if(this.state.selectedValues.length !== nextState.selectedValues.length){
		//   return true;
		// }

		// if(this.props.field && this.props.field.name && this.props.field.name !== nextProps.field.name){
		//   return true;
		// }

		// return false;
		return true;
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (!this.props.value && this.state.selectedValues.length) {
			this.setState({
				selectedValues: [],
			});
		} else if (prevProps.value !== this.props.value) {
			this.getData(this.props);
		}
	}

	onChange = (selectedOption) => {
		//console.log(`selectedOption=>${selectedOption.map(i => i.value).join(',')}<=`);
		if (this.props.isMulti) {
			this.props.onChange(selectedOption.map((i) => i.value).join(','), this.props.field.name);
		} else {
			this.props.onChange(selectedOption && selectedOption.value ? selectedOption.value : '', this.props.field.name);
		}
	};

	render() {
		const { field, value, readOnly } = this.props;
		let selectedValues = this.state.selectedValues;

		// console.log("Select2Async render", field);

		return (
			<div>
				<AsyncSelect
					isMulti={this.props.isMulti}
					delimiter=","
					name={field.name}
					value={selectedValues}
					loadOptions={this.props.promiseOptions}
					cacheOptions
					defaultOptions
					isDisabled={readOnly}
					isClearable={this.props.clearable}
					onChange={this.onChange}
				/>
			</div>
		);
	}
}

export default Select2Async;
