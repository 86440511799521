import _ from 'lodash';
import createAction from '../utils/createAction';
import internalApi from '../utils/internalApi';
import { arrayMove } from '../utils/arrayHelpers';

import { clearAlerts } from './alerts';

export const FETCH_LIBRARY_GROUPS_LOADED = 'FETCH_LIBRARY_GROUPS_LOADED';
export const FETCH_LIBRARY_GROUPS = 'FETCH_LIBRARY_GROUPS';

export const FETCH_LIBRARY_GROUPS_BY_LIBRARY_ID_LOADING = 'FETCH_LIBRARY_GROUPS_BY_LIBRARY_ID_LOADING';
export const FETCH_LIBRARY_GROUPS_BY_LIBRARY_ID = 'FETCH_LIBRARY_GROUPS_BY_LIBRARY_ID';

const fetchLibraryGroups = (libraryId) => (dispatch) => {
	dispatch(createAction(FETCH_LIBRARY_GROUPS_LOADED));

	internalApi.get(`library/groups/${libraryId}`).then((res) => {
		dispatch(createAction(FETCH_LIBRARY_GROUPS, res.payload.items));
	});
};

const fetchLibraryGroupsByLibraryId = (libraryId) => (dispatch) => {
	dispatch(createAction(FETCH_LIBRARY_GROUPS_BY_LIBRARY_ID_LOADING));

	internalApi.get(`library/groups/${libraryId}`).then((res) => {
		dispatch(createAction(FETCH_LIBRARY_GROUPS_BY_LIBRARY_ID, { items: res.payload.items, libraryId }));
	});
};

const changeLibraryGroupStatus =
	({ id, isActive }) =>
	(dispatch) => {
		dispatch(clearAlerts());

		return internalApi.post('library/groups/toggle-active', { id, isActive });
	};

const groupExists = (libraryId, displayName) => {
	return internalApi.post(`library/groups/${libraryId}/groupExists`, { displayName }).then((item) => item.payload.item.exists);
};

const sortGroups = (libraryId, dragIndex, hoverIndex) => (dispatch, getState) => {
	const state = getState();

	const sorted = arrayMove(state.libraryGroups.get('groups').get('data').toJS(), dragIndex, hoverIndex);

	internalApi
		.post(`library/groups/${libraryId}/sort`, {
			fields: sorted.map((i) => i.id),
		})
		.then(() => {
			dispatch(createAction(FETCH_LIBRARY_GROUPS, sorted));
		});
};

const fetchGroup = (libraryId, id) => () => internalApi.get(`library/groups/${libraryId}/one/${id}`);

const saveGroup = (libraryId, data) => (dispatch) => {
	dispatch(clearAlerts());

	let url = `library/groups/${libraryId}/create`;
	let params = data;
	if (data.id) {
		url = `library/groups/${libraryId}/update/${data.id}`;
		params = _.omit(data, 'id');
	}

	return internalApi.post(url, params);
};

const deleteGroup = (libraryId, id) => () => internalApi.post(`library/groups/${libraryId}/delete/${id}`);

export { fetchLibraryGroups, fetchLibraryGroupsByLibraryId, saveGroup, sortGroups, fetchGroup, deleteGroup, changeLibraryGroupStatus, groupExists };
