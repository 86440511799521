import _ from 'lodash';
import internalApi from '../utils/internalApi';
import createAction from '../utils/createAction';

export const IN_PROGRESS = 'IN_PROGRESS';
export const FETCH_APPROVAL_HISTORY_LOADED = 'FETCH_APPROVAL_HISTORY_LOADED';

const fetchApprovalHistory = () => (dispatch) => {
	dispatch(createAction(FETCH_APPROVAL_HISTORY_LOADED, []));
	dispatch(createAction(IN_PROGRESS, true));
	internalApi.get(`approval-history/list`).then((res) => {
		dispatch(createAction(FETCH_APPROVAL_HISTORY_LOADED, res.payload.items));
	});
};

export { fetchApprovalHistory };
