export default (str, filename = 'data') => {
	const a = document.createElement('a');
	a.href = `data:attachment/csv,${encodeURIComponent(str)}`;
	a.target = '_blank';
	a.download = `${filename}.csv`;

	document.body.appendChild(a);
	a.click();
	document.body.removeChild(a);
};
