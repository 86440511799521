import React from 'react';
import PropTypes from 'prop-types';

import { parse } from 'qs';

export default function withQueryParams(WrappedComponent) {
	return class extends React.Component {
		static propTypes = {
			location: PropTypes.shape({
				search: PropTypes.string.isRequired,
			}).isRequired,
		};

		render() {
			return <WrappedComponent {...this.props} queryParams={parse(this.props.location.search.slice(1))} />;
		}
	};
}
