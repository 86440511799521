import React from 'react';
import { NavLink } from 'react-router-dom';

import { Icon } from 'react-fa';
import ClickOutside from 'react-click-outside';
import Flex, { Col } from '../../Flex';
import Image from '../../Image';

import { withImageUrl } from '../../../utils/withStaticUrl';
import { hasPerm } from '../../../utils/accessControl';
import storage from '../../../utils/storage';

import styles from './component.module.scss';

class SidebarMenuItem extends React.PureComponent {
	state = {
		isOpen: false,
	};

	get isSubmenu() {
		return !this.props.menuItem.url;
	}

	onClick = (e) => {
		if (this.isSubmenu) {
			e.preventDefault();

			this.setState({
				isOpen: !this.state.isOpen,
			});
		}
	};

	onClose = () => {
		this.setState({
			isOpen: false,
		});
	};

	renderSidebarOpenView = (menuItem, showAngel = true) => (
		<Flex xs={{ align: 'center' }}>
			<Col xs={{ size: 2, left: 10 }}>
				<Image className={styles.image} src={menuItem.icon ? withImageUrl(menuItem.icon) : null} />
			</Col>
			<Col xs={{ size: 9 }}>
				<span>{menuItem.name}</span>
			</Col>
			{this.isSubmenu && showAngel && (
				<Col xs={{ size: 1 }}>
					<Icon name={this.state.isOpen ? 'angle-down' : 'angle-left'} />
				</Col>
			)}
		</Flex>
	);

	renderSidebarCloseView = (menuItem) => {
		const itemStyle = {
			height: '25px',
			marginLeft: '12px',
		};

		const subMenuItemStyle = {
			height: '18px',
			marginLeft: '12px',
		};

		return (
			<div>
				<Image src={menuItem.icon ? withImageUrl(menuItem.icon) : null} style={this.isSubmenu ? subMenuItemStyle : itemStyle} />
			</div>
		);
	};

	render() {
		const { menuItem, sidebarIsClose, isAdmin, isSuperAdmin, companyInfo } = this.props;

		return (
			<li className={styles.root}>
				<ClickOutside onClickOutside={this.onClose}>
					<NavLink
						to={`/${menuItem.url}`}
						activeClassName={styles.selected}
						onClick={this.onClick}
						style={{
							padding: '0 8px',
						}}
					>
						{sidebarIsClose ? this.renderSidebarCloseView(menuItem) : this.renderSidebarOpenView(menuItem)}
					</NavLink>
					{this.isSubmenu && this.state.isOpen && (
						<Flex xs={{ align: 'center' }}>
							<Col xs={{ size: 12, left: 10 }}>
								<div style={{ paddingLeft: 20 }}>
									{menuItem.items
										.filter((item) => {
											if (item.name === 'Groups') {
												if (companyInfo && companyInfo.meta && !companyInfo.meta.allowSecurityGroups) {
													return false;
												}
											}

											if (typeof item.permission === 'undefined') {
												return true;
											}

											return hasPerm(item.permission);
										})
										.map((item, key) => (
											<NavLink
												to={`/${item.url}`}
												activeClassName={styles.selected}
												key={key}
												style={{
													padding: 0,
												}}
											>
												{sidebarIsClose ? this.renderSidebarCloseView(item) : this.renderSidebarOpenView(item, false)}
											</NavLink>
										))}
								</div>
							</Col>
						</Flex>
					)}
				</ClickOutside>
			</li>
		);
	}
}

export default SidebarMenuItem;
