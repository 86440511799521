import React, { memo, useEffect, useRef, useState } from 'react';
import { getLibraryWithFields } from '../../../../actions/library';
import moment from 'moment';
import { useSelector } from 'react-redux';
import FullCalendar from '../../../../components/FullCalendar';

const fetchColorByStatus = (status) => {
	switch (status) {
		case 'Success':
		case 'Success After Fail':
		case 'Success After Replan':
			return { backgroundColor: 'green', textColor: 'white', borderColor: 'transparent' };
		case 'Replanned':
			return { backgroundColor: '#ebeb13', textColor: 'black', borderColor: 'transparent' };
		case 'Fail':
			return { backgroundColor: 'crimson', textColor: 'white', borderColor: 'transparent' };
		case 'Planned':
			return { backgroundColor: 'cornflowerblue', textColor: 'black', borderColor: 'transparent' };
		default:
			return { backgroundColor: 'cornflowerblue', textColor: 'white', borderColor: 'transparent' };
	}
};

const DemoCalendarView = (props) => {
	const calendarRef = useRef(null);
	const [events, setEvents] = useState([]);
	const [libraryId, setLibraryId] = useState(null);
	const libraries = useSelector((state: any) => state?.library?.get?.('libraries')?.get?.('data')?.toJS?.());

	useEffect(() => {
		loadAllEvents();
	}, []);

	const loadAllEvents = () => {
		getLibraryWithFields('demo-calendar', {
			order: { key: 'updatedAt', in: 'desc' },
			search: '',
			offset: 0,
			limit: 5000,
			listType: 'all',
		}).then((out) => {
			if (out && out.records) {
				setEvents(
					out?.records?.map?.((r) => {
						const selectedLib = libraries?.filter((item) => item?.id === r?.recordSelector?.libraryId);
						let title = r?.name;
						if (selectedLib?.length > 0) {
							title += `(${selectedLib?.[0]?.name} - ${r?.recordSelector?.recordIds?.length})`;
						}
						return {
							id: r?.id,
							...fetchColorByStatus(r?.finalresult),
							title,
							start: new Date(moment(r?.startDate + ' ' + r?.startTime).valueOf()),
							end: new Date(moment(r?.dueDate + ' ' + r?.dueTime).valueOf()),
							isDraggable: true,
							status: r?.status,
						};
					}),
				);
				setLibraryId(out.library.id);
			}
		});
	};

	return <FullCalendar ref={calendarRef} selectable={true} events={events} allDayMaxRows={4} popup={true} libraryId={libraryId} />;
};

export default memo(DemoCalendarView);
