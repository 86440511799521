import Table from './Table';
import THead from './THead';
import TableRow from './TableRow';
import SortableTableRow from './SortableTableRow';
import TableCol from './TableCol';
import TableHeaderCol from './TableHeaderCol';
import DataTable from './DataTable';

export default Table;

export { THead, TableRow, SortableTableRow, TableCol, TableHeaderCol, DataTable };
