import React, { memo } from 'react';
import PropTypes from 'prop-types';

import ReactTooltip from 'react-tooltip';

import Icon from 'react-fa';
import { Col } from '../../Flex';

import cs from './component.module.scss';

const HelpText = (props) => {
	//   console.log("HelpText being rendered..");
	return (
		<React.Fragment>
			{props.helpText && (
				<Col xs={0.5}>
					<span className={cs.popoverTarget}>
						<Icon name="info-circle" data-tip={props.helpText} data-html={props.isHtml} />
					</span>
				</Col>
			)}
			<ReactTooltip effect="solid" className={props.isHtml ? cs.tooltipBackground : ''} />
		</React.Fragment>
	);
};

HelpText.propTypes = {
	helpText: PropTypes.string,
	isHtml: PropTypes.bool,
};

HelpText.defaultProps = {
	helpText: null,
	isHtml: false,
};

export default memo(HelpText);
