import { Map, List } from 'immutable';

import * as actionTypes from '../actions/plan-template-items';

const initialState = new Map({
	isLoaded: false,
	items: new List(),
	count: 0,
});

export default (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.FETCH_PLAN_TEMPLATE_ITEM_LOADED: {
			const { loaded = true } = action.payload;

			return state.updateIn(['isLoaded'], () => loaded);
		}
		case actionTypes.UPDATE_PLANS_TEMPLATE_ITEMS: {
			return state.updateIn(['items'], () => new List(action.payload));
		}
		case actionTypes.GET_PLAN_TEMPLATE_ITEM: {
			return new Map({
				isLoaded: false,
				items: new List(action.payload.rows),
				count: action.payload.count,
			});
		}
		default:
			return state;
	}
};
