import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import classNames from 'classnames';
import _ from 'lodash';

import cs from './component.module.scss';

class TableRow extends React.Component {
	static propTypes = {
		history: PropTypes.shape({
			push: PropTypes.func.isRequired,
		}).isRequired,
		children: PropTypes.node.isRequired,
		dangerLine: PropTypes.bool,
		dangerLineBack: PropTypes.bool,
		hover: PropTypes.bool,
		style: PropTypes.shape({}),
		updateUrl: PropTypes.shape({
			path: PropTypes.string,
			id: PropTypes.string,
		}),
		onClick: PropTypes.func,
	};

	static defaultProps = {
		dangerLine: false,
		dangerLineBack: false,
		hover: false,
		style: {},
		updateUrl: {},
		onClick: () => false,
		isDouble: true,
	};

	state = {
		hovered: false,
	};

	onDoubleClick = () => {
		if (!this.props.isDouble) return;
		const { updateUrl } = this.props;

		if (!_.isEmpty(updateUrl) && (updateUrl.permission !== undefined ? updateUrl.permission : true)) {
			this.props.history.push(`/${updateUrl.path}/update/${updateUrl.id}`);
		}
	};

	onMouseEnter = () => {
		this.setState({
			hovered: true,
		});
	};

	onMouseLeave = () => {
		this.setState({
			hovered: false,
		});
	};

	render() {
		const { dangerLine, dangerLineBack, style, onClick } = this.props;

		return (
			<tr
				className={classNames(cs.root, {
					[cs.dangerLine]: dangerLine,
					[cs.dangerLineBack]: dangerLineBack,
					[cs.hovered]: this.props.hover && this.state.hovered,
				})}
				style={style}
				onClick={onClick}
				onDoubleClick={this.onDoubleClick}
				onMouseEnter={this.onMouseEnter}
				onMouseLeave={this.onMouseLeave}
			>
				{this.props.children}
			</tr>
		);
	}
}

export default withRouter(TableRow);
