import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import cs from './component.module.scss';

const TableCol = (props) => (
	<td
		className={classNames(cs.root, props.className, {
			[cs.date]: props.showAsDate,
			[cs.bordered]: props.bordered,
		})}
		style={props.style}
		onClick={props.onClick}
	>
		{props.children}
	</td>
);

TableCol.propTypes = {
	className: PropTypes.string,
	children: PropTypes.node,
	showAsDate: PropTypes.bool,
	bordered: PropTypes.bool,
	style: PropTypes.shape({}),
	onClick: PropTypes.func,
};

TableCol.defaultProps = {
	className: null,
	children: null,
	showAsDate: false,
	bordered: false,
	style: {},
	onClick: () => false,
};

export default TableCol;
