import { Map, List } from 'immutable';

import * as actionTypes from '../actions/library-groups';

const initialState = new Map({
	groups: new Map({
		isLoaded: false,
		data: new List(),
	}),
	groupsByLibrary: new Map({
		isLoaded: false,
		groupsMap: new Map(),
	}),
});

export default (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.FETCH_LIBRARY_GROUPS_LOADED: {
			const { loaded = false } = action.payload;

			return state.updateIn(['groups', 'isLoaded'], () => loaded);
		}
		case actionTypes.FETCH_LIBRARY_GROUPS_BY_LIBRARY_ID_LOADING: {
			const { loaded = false } = action.payload;
			return state.updateIn(['groupsByLibrary', 'isLoaded'], () => loaded);
		}
		case actionTypes.FETCH_LIBRARY_GROUPS: {
			const groups = action.payload;

			return state.update(
				'groups',
				() =>
					new Map({
						isLoaded: true,
						data: new List(groups),
					}),
			);
		}

		case actionTypes.FETCH_LIBRARY_GROUPS_BY_LIBRARY_ID: {
			const { items, libraryId } = action.payload;
			return state.updateIn(['groupsByLibrary', 'groupsMap'], (groupsMap) => {
				return groupsMap.update(libraryId, () => new List(items));
			});
		}
		default:
			return state;
	}
};
