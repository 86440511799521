import { Map, List } from 'immutable';

import * as actionTypes from '../actions/notification';

const initialState = new Map({
	notifications: new Map({
		isLoaded: false,
		items: new List(),
	}),
});

export default (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.FETCH_NOTIFICATION: {
			return state.update(
				'notifications',
				() =>
					new Map({
						isLoaded: true,
						items: new List(action.payload),
					}),
			);
		}
		case actionTypes.FETCH_NOTIFICATION_LOADED: {
			const { loaded = false } = action.payload;

			return state.updateIn(['notifications', 'isLoaded'], () => loaded);
		}

		case actionTypes.HIDE_NOTIFICATION: {
			const id = action.payload;
			let notificationIndex = state
				.get('notifications')
				.get('items')
				.findIndex((item) => item.id == id);
			if (notificationIndex !== -1) {
				let newList = state.get('notifications').get('items').remove(notificationIndex);
				return state.update(
					'notifications',
					() =>
						new Map({
							isLoaded: true,
							items: newList,
						}),
				);
			}
			return state;
		}
		default:
			return state;
	}
};
