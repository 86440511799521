export default (state) => {
	const pages = state.ui.get('pages');

	const libraries = state.library
		.get('libraries')
		.get('data')
		.filter((i) => i.isActive)
		.filter((i) => !i.isHidden);

	pages[0][2].items = libraries
		.filter((i) => i.parent === 'library')
		.map((item) => ({
			name: item.name,
			icon: item.icon,
			url: `library/${item.alias}`,
			permission: `perm_${item.alias}_view`,
		}));

	const restItems = libraries
		.filter((i) => i.parent === 'root')
		.map((item) => ({
			name: item.name,
			icon: item.icon,
			url: `library/${item.alias}`,
			permission: `perm_${item.alias}_view`,
		}));

	const menu = [...pages[0], ...restItems, ...pages[1]];
	return menu;
};
