import _ from 'lodash';
import internalApi from '../utils/internalApi';
import createAction from '../utils/createAction';

export const IN_PROGRESS = 'IN_PROGRESS';
export const FETCH_API_KEYS_LOADED = 'FETCH_API_KEYS_LOADED';
export const NEW_KEYS_LOADED = 'NEW_KEYS_LOADED';
export const CLEAR_NEW_KEYS_LOADED = 'CLEAR_NEW_KEYS_LOADED';

const deleteApiKey = (id, companyId) => (dispatch) => {
	dispatch(createAction(IN_PROGRESS, true));
	internalApi.delete(`api-keys/${id}`).then((deleted) => {
		dispatch(listApiKeys(companyId));
	});
};

const listApiKeys = (companyId) => (dispatch) => {
	dispatch(createAction(IN_PROGRESS, true));
	internalApi.get(`api-keys/${companyId}`).then((res) => {
		dispatch(createAction(FETCH_API_KEYS_LOADED, res.payload.items));
	});
};

const generateApiKeys = (companyId) => (dispatch) => {
	dispatch(createAction(IN_PROGRESS, true));
	internalApi.post(`api-keys/${companyId}`).then((res) => {
		dispatch(createAction(NEW_KEYS_LOADED, res.payload.item));
	});
};

const clearGeneratedKeys = () => (dispatch) => {
	dispatch(createAction(CLEAR_NEW_KEYS_LOADED));
};

export { deleteApiKey, listApiKeys, generateApiKeys, clearGeneratedKeys };
