import React from 'react';
import PropTypes from 'prop-types';

import { THead, TableRow, TableHeaderCol } from '../../../Table';

class DataListHeader extends React.Component {
	static propTypes = {
		fields: PropTypes.arrayOf(
			PropTypes.shape({
				text: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
				value: PropTypes.string,
				order: PropTypes.bool,
				hidden: PropTypes.bool,
			}),
		),
		currentOrder: PropTypes.string,
		onReorder: PropTypes.func,
	};

	static defaultProps = {
		fields: null,
		currentOrder: '',
		onReorder: () => false,
	};

	render() {
		return (
			<THead>
				<TableRow>
					{this.props.fields &&
						this.props.fields
							.filter((th) => !th.hidden)
							.map((field, key) => (
								<TableHeaderCol
									key={key}
									field={field.value}
									align={field.align}
									hasOrder={field.order === undefined ? true : field.order}
									isActive={this.props.currentOrder === field.value}
									reOrder={this.props.onReorder}
								>
									{field.text}
								</TableHeaderCol>
							))}
				</TableRow>
			</THead>
		);
	}
}

export default DataListHeader;
