import Immutable from 'immutable';

export const arrayMove = (arr, oldIndex, newIndex) => {
	if (newIndex >= arr.length) {
		let k = newIndex - arr.length + 1;
		while (k--) {
			arr.push(undefined);
		}
	}
	arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
	return arr;
};

export const toImmutableKeyedMap = (field, data) => {
	let list = data;
	if (!Immutable.isImmutable(data)) {
		list = new Immutable.List(data);
	}
	return list.toMap().mapEntries(([, value]) => [value[field], value]);
};

export const mergeListsByField = (field, list1, list2) => toImmutableKeyedMap(field, list1).merge(toImmutableKeyedMap(field, list2)).toList();
