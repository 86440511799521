import React from 'react';
import internalApi from '../utils/internalApi';

class FormView extends React.Component {
	getField(fieldName, formName = 'form', defaultReturn = '') {
		if (this.state[formName][fieldName]) {
			return this.state[formName][fieldName];
		} else {
			return defaultReturn;
		}
	}

	getMeta(path, formName = 'form') {
		return path.split('.').reduce((parent, current) => {
			if (parent && typeof parent[current] !== 'undefined') {
				return parent[current];
			} else {
				return '';
			}
		}, this.state[formName]['meta']);
	}

	handleChange = (fieldName) => (initialValue) => {
		const { form } = this.state;
		form[fieldName] = initialValue;
		this.setState({ form }, () => {
			if (this.onChangeAfterTrigger) {
				this.onChangeAfterTrigger();
			}
		});
	};

	onChange =
		(fieldName, cb, formName = 'form') =>
		(initialValue) => {
			const form = this.state[formName];
			if (fieldName == 'password' && initialValue && initialValue.target && typeof initialValue.target.value !== 'undefined') {
				form[fieldName] = initialValue.target.value;
			} else {
				form[fieldName] = initialValue;
			}
			// form[fieldName] = fieldName == 'password' ? initialValue.target.value : initialValue;
			this.setState({ [formName]: form }, () => {
				if (cb) {
					cb();
				}

				if (this.onChangeAfterTrigger) {
					this.onChangeAfterTrigger();
				}
			});
		};

	onChangeMeta =
		(path, cb, formName = 'form') =>
		(initialValue) => {
			const form = this.state[formName];
			let pathList = ('meta.' + path).split('.');
			let currentData = form;
			while (pathList && pathList.length) {
				let key = pathList.shift();
				if (typeof currentData === 'object') {
					if (typeof currentData[key] === 'undefined') {
						currentData[key] = {};
					} else {
						if (typeof currentData[key] === 'object') {
							currentData[key] = { ...currentData[key] };
						}
					}

					if (!pathList.length) {
						currentData[key] = initialValue;
					}
					currentData = currentData[key];
				}
			}

			this.setState({ [formName]: form }, () => {
				if (cb) {
					cb();
				}

				if (this.onChangeAfterTrigger) {
					this.onChangeAfterTrigger();
				}
			});
		};

	onChangeCheckbox = (checked, fieldName) => {
		this.setState(
			(prevState) => {
				const { form } = prevState;
				form[fieldName] = checked;
				return {
					form,
				};
			},
			() => {
				if (this.onChangeAfterTrigger) {
					this.onChangeAfterTrigger();
				}
			},
		);
	};

	onUploadFile = (fieldName) => (name, file) => {
		internalApi.uploadFile(name, file).then(({ payload }) => {
			this.setState(
				(prevState) => {
					const { form } = prevState;
					form[fieldName] = payload.item;
					return {
						form,
					};
				},
				() => {
					if (this.onChangeAfterTrigger) {
						this.onChangeAfterTrigger();
					}
				},
			);
		});
	};
}

export default FormView;
