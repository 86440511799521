import React from 'react';

import cx from 'classnames';
import styles from './component.module.scss';

const Sidebar = (props) => (
	<div
		className={cx(styles.root, {
			[styles.closed]: props.isClose,
		})}
	>
		<div className={styles.menu} style={{ paddingTop: 10 }}>
			<ul className={styles.nav}>{props.children}</ul>
		</div>
	</div>
);

export default Sidebar;
