import React from 'react';
import Padding from '../../Padding';

import styles from './component.module.scss';

class Header extends React.PureComponent {
	render() {
		return (
			<div className={styles.root}>
				<Padding padding={{ top: 10, bottom: 10, left: 10 }}>{this.props.children}</Padding>
			</div>
		);
	}
}

export default Header;
