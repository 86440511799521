import internalApi from '../utils/internalApi';

const createOrSaveLibraryRecord = (libraryId, data) => {
	let url = `library/records/${libraryId}/create`;
	if (data.id) {
		url = `library/records/${libraryId}/update/${data.id}`;
	}
	return internalApi.post(url, data);
};

export { createOrSaveLibraryRecord };
