import internalApi from '../utils/internalApi';
import { clearAlerts } from './alerts';
import createAction from '../utils/createAction';

export const GET_PLAN_TEMPLATE = 'GET_PLAN_TEMPLATE';
export const FETCH_PLAN_TEMPLATE_LOADED = 'FETCH_PLAN_TEMPLATE_LOADED';

const getPlanTemplate =
	({ order = {}, search, limit, offset, individualSearchFields = [] } = {}) =>
	(dispatch) => {
		dispatch(createAction(FETCH_PLAN_TEMPLATE_LOADED));
		const queryParams = {
			search,
			limit,
			offset,
			order,
			individualSearchFields,
		};
		internalApi.get('plans/template/list', { params: queryParams }).then((res) => {
			dispatch(createAction(GET_PLAN_TEMPLATE, res.payload.items));
		});
	};

const savePlanTemplate = (data) => (dispatch) => {
	dispatch(clearAlerts());

	return internalApi.post('plans/template/update', data);
};

const deletePlanTemplates = (ids) => () =>
	internalApi.post('plans/template/delete-multiple', {
		ids,
	});

const fetchPlanTemplate = (id) => () => internalApi.get(`plans/template/one/${id}`);

const duplicatePlanTemplate = (id) => () => internalApi.get(`plans/template/duplicate/${id}`);

export { savePlanTemplate, getPlanTemplate, deletePlanTemplates, fetchPlanTemplate, duplicatePlanTemplate };
