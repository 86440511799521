import createAction from '../utils/createAction';
import internalApi from '../utils/internalApi';
import toast from '../utils/toast';
export const FETCH_DASHBOARD_WIDGETS_LOADED = 'FETCH_DASHBOARD_WIDGETS_LOADED';
export const FETCH_DASHBOARD_WIDGETS = 'FETCH_DASHBOARD_WIDGETS';
export const TOGGLE_LIBRARY_AS_WIDGET = 'TOGGLE_LIBRARY_AS_WIDGET';

const fetchDashboardWidgets = () => (dispatch) => {
	dispatch(createAction(FETCH_DASHBOARD_WIDGETS_LOADED));
	internalApi.get('library/widgets').then((res) => {
		dispatch(createAction(FETCH_DASHBOARD_WIDGETS, res.payload.items)); //.filter(item => item.permission = `perm_${item.alias}_view`)
	});
};

const toggleLibraryAsWidget = (libraryId, type) => (dispatch) => {
	return internalApi.post('library/toggle-widget', { id: libraryId, type: type }).then((response) => {
		dispatch(
			createAction(TOGGLE_LIBRARY_AS_WIDGET, {
				libraryId,
			}),
		);
		toast(response.payload.message, 'success');
	});
};

const fetchLibraryItems = () => (dispatch) => internalApi.get('library/fetchLibraryItems');

//adding data
const addDashboardWidgetData = (data, libraryId, typeId, displayOrder) => (dispatch) =>
	internalApi.post('library/add-widget-data', { data: data, typeId: typeId, libraryId: libraryId, displayOrder: displayOrder });

//getting data
const fetchWidgetData = () => (dispatch) => internalApi.get('library/fetch-widget-data');

//show data
const showWidget = (options) => () =>
	internalApi.get(options.title === 'Chart' ? 'library/showChartdata' : 'library/showMapdata', { params: options.formValues });

//dispose widget
const disposeWidget = (id) => (dispatch) => internalApi.post('library/remove-widget', { id: id });

const fetchLibraries = () => (dispatch) => internalApi.get('library/fetchlibraries');

const addDashboardWidget = (data, libraryId, typeId) => (dispatch) =>
	internalApi.post('library/addDashboardWidget', { data: data, typeId: typeId, libraryId: libraryId });

const getChartLibraryData = (options) => () => internalApi.get('library/getChartLibraryData', { params: options.formValues });

const fetchAnalyticsData = () => (dispatch) => internalApi.get('library/fetch-analytics-data');

const fetchWidgetAnalyticsData = () => (dispatch) => internalApi.get('library/fetch-widget-analytics-data');

const disposeAnalyticWidget = (id) => (dispatch) => internalApi.post('library/remove-analytic-widget', { id: id });

export {
	fetchDashboardWidgets,
	toggleLibraryAsWidget,
	fetchLibraryItems,
	addDashboardWidgetData,
	fetchWidgetData,
	showWidget,
	disposeWidget,
	fetchLibraries,
	addDashboardWidget,
	getChartLibraryData,
	fetchAnalyticsData,
	fetchWidgetAnalyticsData,
	disposeAnalyticWidget,
};
