import { memo, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styles from './CalendarView.module.scss';
import FullCalendar from '../../../../components/FullCalendar';
import { getLibraryWithFields } from '../../../../actions/library';
import moment from 'moment';
import ManageEvent from './ManageEvent';

// https://github.com/fullcalendar/fullcalendar-examples/blob/main/react/src/DemoApp.jsx

let eventGuid = 0;
function createEventId() {
	return String(eventGuid++);
}

const fetchColorByStatus = (status) => {
	switch (status) {
		case 'Success':
		case 'Success After Fail':
		case 'Success After Replan':
			return { backgroundColor: 'green', textColor: 'white', borderColor: 'transparent' };
		case 'Replanned':
			return { backgroundColor: '#ebeb13', textColor: 'black', borderColor: 'transparent' };
		case 'Fail':
			return { backgroundColor: 'crimson', textColor: 'white', borderColor: 'transparent' };
		case 'Planned':
			return { backgroundColor: 'cornflowerblue', textColor: 'black', borderColor: 'transparent' };
		default:
			return { backgroundColor: 'cornflowerblue', textColor: 'white', borderColor: 'transparent' };
	}
};

const CalendarView = (props) => {
	const calendarRef = useRef(null);
	const history = useHistory();
	const [openEventId, openEvent] = useState(null);
	const [events, setEvents] = useState([]);

	useEffect(() => {
		loadAllEvents();
	}, []);

	const loadAllEvents = () => {
		getLibraryWithFields('calendar', {
			order: { key: 'updatedAt', in: 'desc' },
			search: '',
			offset: 0,
			limit: 5000,
			listType: 'all',
		}).then((out) => {
			if (out && out.records) {
				// console.log('calendar->', out.records);
				setEvents(
					out?.records?.map?.((r) => ({
						id: r?.id,
						...fetchColorByStatus(r?.finalresult),
						title: r?.nameoftheexercisetest,
						date: moment(r?.calendarexecutiondate).toISOString(),
						allDay: true,
					})),
				);
			}
			openEvent(null);
		});
	};

	const createNewEvent = () => {
		history.push(`/library/calendar/create`);
	};

	const handleDateClick = (info) => {
		console.log('info->', info);
		console.log('Clicked on: ' + info.dateStr);
		// console.log('Coordinates: ' + info.jsEvent.pageX + ',' + info.jsEvent.pageY);
		console.log('Current view: ' + info.view.type);
		// change the day's background color just for fun
		// info.dayEl.style.backgroundColor = 'red';
		// console.log(opts);

		let calendarApi = calendarRef?.current?.getApi?.();
		// calendarApi?.next?.();

		let title = prompt('Please enter a new title for your event');
		// let calendarApi = info.view.calendar;

		// calendarApi.unselect() // clear date selection

		// start: info.startStr,
		// end: info.endStr,

		if (title) {
			calendarApi.addEvent({
				id: createEventId(),
				title,
				start: info.dateStr,
				end: info.dateStr,
				allDay: info.allDay,
			});
		}
	};

	const handleCalendarResponse = (info) => {
		console.log(info);
	};

	const handleEventClick = (clickInfo) => {
		history.push(`/library/calendar/update/${clickInfo?.event?.id}`);
		// openEvent(clickInfo?.event?.id);
	};

	const handleEvents = (events) => {
		// this.setState({
		// currentEvents: events
		// })
	};

	return (
		<div className={styles.root}>
			<FullCalendar
				ref={calendarRef}
				editable={true}
				selectable={true}
				events={events}
				// dateClick={handleDateClick}
				select={handleCalendarResponse}
				eventClick={handleEventClick}
				eventAdd={handleCalendarResponse}
				eventChange={handleCalendarResponse}
				eventRemove={handleCalendarResponse}
				customButtons={{
					myCustomButton: {
						text: 'Add New Item',
						click: function () {
							createNewEvent();
						},
					},
				}}
				headerToolbar={{
					left: 'title',
					// center: 'myCustomButton',
					right: 'myCustomButton today dayGridMonth,timeGridWeek,timeGridDay prev,next',
				}}
			/>
			{openEventId && <ManageEvent id={openEventId} onClose={() => openEvent(null)} loadAllEvents={loadAllEvents} />}
		</div>
	);
};

export default memo(CalendarView);
