import React from 'react';
import PropTypes from 'prop-types';

import cs from './component.module.scss';

const DataTable = ({ children, style = {} }) => (
	<table style={style} className={cs.root} cellSpacing="0" cellPadding="0">
		{children}
	</table>
);

DataTable.propTypes = {
	children: PropTypes.node.isRequired,
};

export default DataTable;
