import internalApi from '../utils/internalApi';
import { clearAlerts } from './alerts';
import createAction from '../utils/createAction';

export const GET_COMPANY_INFO = 'GET_COMPANY_INFO';
export const GET_COMPANY_PUBLIC_INFO = 'GET_COMPANY_PUBLIC_INFO';
export const GET_COMPANY_PUBLIC_INFO_LOADED = 'GET_COMPANY_PUBLIC_INFO_LOADED';

const getCompanyInfo = () => (dispatch) => {
	internalApi.get('company/info').then((res) => {
		dispatch(createAction(GET_COMPANY_INFO, res.payload.item));
	});
};

const getCompanyPublicInfo = (companyAlias) => (dispatch) => {
	internalApi.post('public/company/info', { companyAlias }).then((res) => {
		dispatch(createAction(GET_COMPANY_PUBLIC_INFO_LOADED, res.payload.item));
	});
};

const getOnlyCompanyPublicInfo = (companyAlias) => {
	return internalApi.post('public/company/info', { companyAlias });
};

const setOnlyCompanyPublicInfo = (info) => (dispatch) => {
	dispatch(createAction(GET_COMPANY_PUBLIC_INFO_LOADED, info));
};

const saveCompany = (data) => (dispatch) => {
	dispatch(clearAlerts());

	return internalApi.post('company/update', data);
};

export { getCompanyInfo, saveCompany, getCompanyPublicInfo, getOnlyCompanyPublicInfo, setOnlyCompanyPublicInfo };
