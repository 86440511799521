import { Map, List } from 'immutable';

import * as actionTypes from '../actions/threat';

const initialState = new Map({
	threats: new Map({
		isLoaded: false,
		items: new List(),
		count: 0,
	}),
	threatGroups: new Map({
		isLoaded: false,
		items: new List(),
	}),
});

export default (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.FETCH_THREATS: {
			return state.update(
				'threats',
				() =>
					new Map({
						isLoaded: true,
						items: new List(action.payload.rows),
						count: action.payload.count,
					}),
			);
		}
		case actionTypes.FETCH_THREAT_GROUPS: {
			return state.update(
				'threatGroups',
				() =>
					new Map({
						isLoaded: true,
						items: new List(action.payload.records),
						total: action.payload.total,
					}),
			);
		}
		case actionTypes.FETCH_THREATS_LOADED: {
			const { loaded = false } = action.payload;

			return state.updateIn(['threats', 'isLoaded'], () => loaded);
		}
		case actionTypes.FETCH_THREAT_GROUPS_LOADED: {
			const { loaded = false } = action.payload;

			return state.updateIn(['threatGroups', 'isLoaded'], () => loaded);
		}
		default:
			return state;
	}
};
