//import 'core-js';
import 'react-app-polyfill/ie9';
import React from 'react';
import ReactDOM from 'react-dom';

import Root from './routes/root/index';
import { store } from './store/store';

// import 'react-awesome-popover/dest/react-awesome-popover.css';

import './style/react-checkbox-tree-style.css';
import './style/index.scss';
import 'react-virtualized/styles.css';

const element = document.getElementById('root');

ReactDOM.render(<Root store={store} />, element);
