import React, { memo, useState } from 'react';
import styles from './PinVerificationModal.module.scss';
import Modal from './../../../components/Modal';
import Button from './../../../components/Form/Button';
import PinInput from 'react-pin-input';

const PinVerificationModal = ({ onComplete, onClose }) => {
	const [pin, setPin] = useState(null);

	const isReadOnly = pin ? false : true;
	return (
		<Modal width={650} hasPadding title="Enter your verification code" isOpen={true} onClose={onClose}>
			<div className={styles.root}>
				<div>Input the code we sent to your email to access your account.</div>
				<PinInput
					length={4}
					initialValue=""
					secret
					onChange={(value, index) => {
						setPin(value);
					}}
					type="numeric"
					inputMode="number"
					style={{ padding: '10px' }}
					// inputStyle={{borderColor: 'red'}}
					// inputFocusStyle={{borderColor: 'blue'}}
					onComplete={(value, index) => {
						onComplete(value);
					}}
					autoSelect={true}
				/>
				<div style={{ width: 100, padding: 10 }}>
					<Button readonly={isReadOnly} primary>
						Verify
					</Button>
				</div>
			</div>
		</Modal>
	);
};

export default memo(PinVerificationModal);
