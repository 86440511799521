import React from 'react';

class Text extends React.PureComponent {
	render() {
		let props = this.props;
		let {
			color = '#676a6c',
			className = '',
			transform = 'none',
			fontSize = 16,
			fontWeight = 'normal',
			lineHeight = 'normal',
			align = 'normal',
			margin = {},
			style = {},
			onClick = null,
		} = this.props;

		const calculatedStyle = {
			color: color,
			textTransform: transform,
			textAlign: align,
			fontSize: fontSize,
			fontWeight: fontWeight,
			fontFamily: 'Arial',
			lineHeight: lineHeight,
			marginTop: margin.top || 0,
			marginRight: margin.right || 0,
			marginBottom: margin.bottom || 0,
			marginLeft: margin.left || 0,
			cursor: onClick ? 'pointer' : 'select',
			...style,
		};

		return (
			<div className={className} style={calculatedStyle} onClick={onClick}>
				{props.children}
			</div>
		);
	}
}

// Text.propTypes = {
//   children: PropTypes.node.isRequired,
//   className: PropTypes.string,
//   color: PropTypes.string,
//   fontSize: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
//   transform: PropTypes.oneOf(['uppercase', 'capitalize', 'lowercase', 'none']),
//   align: PropTypes.string,
//   fontWeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
//   lineHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
//   margin: PropTypes.shape({
//     top: PropTypes.number,
//     right: PropTypes.number,
//     bottom: PropTypes.number,
//     left: PropTypes.number,
//   }),
//   style: PropTypes.shape({}),
//   onClick: PropTypes.oneOfType([PropTypes.func, PropTypes.instanceOf(null)]),
// };

export default Text;
