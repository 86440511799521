import React from 'react';
import PropTypes from 'prop-types';

import { Route } from 'react-router-dom';
import { AccessDenied } from '../../ErrorPage';

import * as routeAccess from '../../../utils/accessControl';

class AppRoute extends React.Component {
	render() {
		const { permission, component: Component, ...rest } = this.props;

		if (!permission) {
			return <AccessDenied />;
		}

		if (this.props.computedMatch.params.libraryAlias && !routeAccess.hasPerm(`perm_${this.props.computedMatch.params.libraryAlias}_view`)) {
			return <AccessDenied />;
		}
		//return <Route {...rest} component={Component} />;
		return (
			<Route
				{...rest}
				render={(props) => {
					return <Component {...props} />;
				}}
			/>
		);
	}
}

AppRoute.propTypes = {
	//  component: PropTypes.func,
	permission: PropTypes.bool,
};

AppRoute.defaultProps = {
	//  component: null,
	permission: true,
};

export default AppRoute;
