import React from 'react';
import Icon from 'react-fa';
import { withRouter } from 'react-router-dom';

import Flex, { Col } from './../../Flex';
import { Button } from './../../Form';
import styles from './../component.module.scss';
import { getTranslatedText } from 'utils/common';

class NotificationTypeRecordWaitingForApproval extends React.Component {
	viewRecord() {
		const { item } = this.props;
		this.props.history.push(`/library/${item.meta.libraryAlias}/update/${item.meta.id}`);
		this.props.closeNotificationsWindow();
	}

	render() {
		const { item, hideNotification } = this.props;

		return (
			<React.Fragment>
				<Flex xs={{ align: 'center', justify: 'space-between' }}>
					<Col xs={11}>
						<div>
							<a href={item.href} className={styles.href}>
								{item.text}
							</a>
						</div>
						<div style={{ marginTop: '15px' }}>
							<Button sm onClick={this.viewRecord.bind(this)} primary style={{ width: '100px' }}>
								{getTranslatedText('common.viewRecord', 'View Record')}
							</Button>
						</div>
					</Col>
					<Col xs={1}>
						<Icon
							name="times-circle"
							onClick={() => hideNotification(item.id)}
							style={{
								float: 'right',
								fontSize: '16px',
								color: '#505F79',
								boxSizing: 'border-box',
								cursor: 'pointer',
							}}
						/>
					</Col>
				</Flex>
			</React.Fragment>
		);
	}
}

export default withRouter(NotificationTypeRecordWaitingForApproval);
