import React from 'react';
import PropTypes from 'prop-types';

import { List } from 'immutable';

// import { DndProvider } from 'react-dnd';
// import HTML5Backend from 'react-dnd-html5-backend';
import Table, { TableRow } from '../../Table';
import DataListHeader from './DataListHeader';

// @DragDropContext(HTML5Backend)
export default class DataList extends React.Component {
	static propTypes = {
		data: PropTypes.oneOfType([PropTypes.array, PropTypes.instanceOf(List)]),
		fields: PropTypes.arrayOf(PropTypes.shape({})),
		isLoaded: PropTypes.bool,
		loadingAsText: PropTypes.bool,
		noItems: PropTypes.string,
		rowRender: PropTypes.func.isRequired,
		onReorder: PropTypes.func,
	};

	static defaultProps = {
		data: [],
		dataCount: 0,
		pagination: false,
		fields: [],
		isLoaded: false,
		loadingAsText: false,
		noItems: 'No Items',
		onReorder: () => false,
	};

	state = {
		order: {
			key: 'createdAt',
			in: 'desc',
		},
	};

	limit = 25;

	get getView() {
		const { data, noItems, rowRender, fields } = this.props;

		if (data.count() === 0) {
			return (
				<TableRow>
					<td colSpan={fields.length}>{noItems}</td>
				</TableRow>
			);
		}

		return data.map(rowRender);
	}

	onReorder = (key, orderIn) => {
		let inOrder = orderIn;
		if (key === this.state.order.key) {
			inOrder = this.state.order.in === 'asc' ? 'desc' : 'asc';
		}
		this.setState(
			{
				order: {
					key,
					in: inOrder,
				},
			},
			() => {
				this.props.onReorder(this.state.order);
			},
		);
	};

	render() {
		const { fields } = this.props;
		return (
			<div>
				<Table>
					<DataListHeader fields={fields} currentOrder={this.state.order.key} onReorder={this.onReorder} />
					<tbody>{this.getView}</tbody>
				</Table>
			</div>
		);
	}
}
