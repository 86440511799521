import React from 'react';
import PropTypes from 'prop-types';

import cs from './component.module.scss';

const Alert = (props) => <div className={`${cs.root} ${props.success ? cs.success : ''}`}>{props.children}</div>;

Alert.propTypes = {
	children: PropTypes.node,
	success: PropTypes.bool,
};

Alert.defaultProps = {
	children: null,
	success: false,
};

export default Alert;
