import React, { lazy, Suspense } from 'react';
import { Switch, Route } from 'react-router-dom';
import { AppRoute } from '../../components/Routing';
import { Loading } from './../../components';

const Dashboard = lazy(() => import('./dashboard'));
const Analytics = lazy(() => import('./analytics'));

const Company = lazy(() => import('./administration/company'));
const CompanySave = lazy(() => import('./administration/company/company-save'));

const Library = lazy(() => import('./library'));
const LibrarySave = lazy(() => import('./library/library-save'));
const LibraryDetailView = lazy(() => import('./library/library-detail-view'));
const LibraryviewDependency = lazy(() => import('./library/library-view-dependency'));

const TaskManagement = lazy(() => import('./task-management'));
const TaskSave = lazy(() => import('./task-management/task-save'));

const UserSettings = lazy(() => import('./user-settings'));
const ApprovalHistory = lazy(() => import('./approval-history'));

const HelpCenter = lazy(() => import('./help-center'));

const CompanySettings = lazy(() => import('./administration/company-settings'));
const LoginHistory = lazy(() => import('./administration/login-history'));
const PlanTemplate = lazy(() => import('./administration/plan-template'));
const PlanTemplateSave = lazy(() => import('./administration/plan-template/plan-template-save'));
const PlanTemplateItemSave = lazy(() => import('./administration/plan-template/plan-item'));

const ManageTemplate = lazy(() => import('./administration/manage-template'));
const ManageTemplateSave = lazy(() => import('./administration/manage-template/manage-template-save'));

const User = lazy(() => import('./administration/users'));
const UserSave = lazy(() => import('./administration/users/user-save'));

const Threats = lazy(() => import('./administration/threat'));
const ThreatsSave = lazy(() => import('./administration/threat/threat-save'));
const ThreatGroupSave = lazy(() => import('./administration/threat/threat-group-save'));

const Report = lazy(() => import('./report'));
const ReportPrint = lazy(() => import('./report/report-print'));
const ReportSave = lazy(() => import('./report/report-save'));
const StaticReport = lazy(() => import('./report/static-report'));

const PlanItemSave = lazy(() => import('./plans/plan-items/plan-item-save'));
const PlanEditor = lazy(() => import('./plans/plan-editor'));

const SystemGroups = lazy(() => import('./administration/groups'));
const Roles = lazy(() => import('./administration/roles'));
const RoleSave = lazy(() => import('./administration/roles/role-save'));

const LibraryManagement = lazy(() => import('./administration/library-management'));
const LibraryManagementSave = lazy(() => import('./administration/library-management/library-save'));
const LibraryItems = lazy(() => import('./administration/library-management/library-items'));
const LibraryItemsSave = lazy(() => import('./administration/library-management/library-items/library-items-save'));
const LibraryGroups = lazy(() => import('./administration/library-management/library-groups'));
const LibraryGroupsSave = lazy(() => import('./administration/library-management/library-groups/library-groups-save'));

const Help = lazy(() => import('./administration/help'));
const HelpQuestionSave = lazy(() => import('./administration/help/help-question-save'));
const HelpCategorySave = lazy(() => import('./administration/help/help-category-save'));

const Import = lazy(() => import('./administration/import'));

const Todo = lazy(() => import('./todo'));
const TodoSave = lazy(() => import('./todo/todo-save'));

import { NotFound } from '../../components/ErrorPage';

import * as routeAccess from '../../utils/accessControl';
import storage from '../../utils/storage';
import CalendarView from './calendar/CalendarView';
import DemoCalendarView from './calendar/DemoCalendarView';

class AppRoutes extends React.Component {
	render() {
		return (
			<Suspense fallback={<Loading fixed={false} size="sm" type="ball-pulse-sync" />}>
				<Switch>
					<Route path="/" render={(params) => <Dashboard {...params} />} exact />
					<Route path="/dashboard" render={(params) => <Dashboard {...params} />} exact />
					<Route path="/analytics" render={(params) => <Analytics {...params} />} exact />
					<AppRoute path="/company" component={Company} exact permission={storage.get('companyAlias') === 'admin'} />
					<AppRoute path="/company/create" component={CompanySave} exact permission={storage.get('companyAlias') === 'admin'} />
					<AppRoute path="/company/update/:id" component={CompanySave} exact permission={storage.get('companyAlias') === 'admin'} />

					<Route path="/import" render={() => <Import resetTimer={this.props.resetTimer} />} exact />
					<Route path="/plan-editor" render={() => <PlanEditor />} exact />

					<AppRoute path="/plan-item/:planId/create" component={PlanItemSave} exact />
					<AppRoute path="/plan-item/:planId/update/:id" component={PlanItemSave} exact />

					<AppRoute path="/library/calendar" component={CalendarView} exact />
					<AppRoute path="/library/demo-calendar" component={DemoCalendarView} exact />
					<AppRoute path="/library/dependency-mapping" component={LibraryviewDependency} exact />

					<AppRoute path="/library/:libraryAlias" component={Library} exact />
					<Route path="/library/:libraryAlias/create" render={(params) => <LibrarySave {...params} />} exact />
					<Route path="/library/:libraryAlias/create/:plantemplateId" render={(params) => <LibrarySave {...params} />} exact />
					<Route path="/library/:libraryAlias/update/:id" render={(params) => <LibrarySave {...params} />} exact />
					<Route path="/library/:libraryAlias/view/:id" render={(params) => <LibraryDetailView {...params} />} exact />
					{/* <Route path="/library/:libraryAlias/viewDependency" render={(params) => <LibraryviewDependency {...params} />} exact /> */}

					<AppRoute path="/task-management" component={TaskManagement} exact permission={routeAccess.canViewTask()} />
					<AppRoute path="/task-management/create" component={TaskSave} exact permission={routeAccess.canCreateTask()} />
					<AppRoute path="/task-management/update/:id" component={TaskSave} exact permission={routeAccess.canUpdateTask()} />

					<Route path="/user-settings/update/:id" render={(params) => <UserSettings {...params} />} exact />
					<AppRoute path="/company-settings" component={CompanySettings} exact permission={routeAccess.canUpdateCompanySettings()} />
					<AppRoute path="/approval-history" component={ApprovalHistory} exact permission={routeAccess.canManageApprovals()} />

					<AppRoute path="/login-history" component={LoginHistory} exact permission={routeAccess.canViewLoginHistory()} />

					<AppRoute path="/user-setup" component={User} exact permission={routeAccess.canViewUsers()} />
					<AppRoute path="/user-setup/create" component={UserSave} exact permission={routeAccess.canCreateUsers()} />
					<AppRoute path="/user-setup/update/:id" component={UserSave} exact permission={routeAccess.canUpdateUsers()} />

					<AppRoute path="/groups" component={SystemGroups} exact permission={routeAccess.canViewRoles()} />
					<AppRoute path="/roles" component={Roles} exact permission={routeAccess.canViewRoles()} />
					<AppRoute path="/roles/create" component={RoleSave} exact permission={routeAccess.canCreateRoles()} />
					<AppRoute path="/roles/update/:id" component={RoleSave} exact permission={routeAccess.canUpdateRoles()} />

					<AppRoute path="/threats" component={Threats} exact permission={routeAccess.canViewThreat()} />
					<AppRoute path="/threats/create" component={ThreatsSave} exact permission={routeAccess.canCreateThreat()} />
					<AppRoute path="/threats/update/:id" component={ThreatsSave} exact permission={routeAccess.canUpdateThreat()} />
					<AppRoute path="/threat-groups/create" component={ThreatGroupSave} exact permission={routeAccess.canCreateThreat()} />
					<AppRoute path="/threat-groups/update/:id" component={ThreatGroupSave} exact permission={routeAccess.canUpdateThreat()} />

					<AppRoute path="/todo" component={Todo} exact />
					<AppRoute path="/todo/create" component={TodoSave} exact />
					<AppRoute path="/todo/update/:id" component={TodoSave} exact />
					<AppRoute path="/plan-template-item/:planTemplateId/create" component={PlanTemplateItemSave} exact permission={routeAccess.canUpdatePlanTemplate()} />
					<AppRoute path="/plan-template-item/:planTemplateId/update/:id" component={PlanTemplateItemSave} exact permission={routeAccess.canUpdatePlanTemplate()} />
					<AppRoute path="/plan-template" component={PlanTemplate} exact permission={routeAccess.canViewPlanTemplate()} />
					<AppRoute path="/plan-template/create" component={PlanTemplateSave} exact permission={routeAccess.canCreatePlanTemplate()} />
					<AppRoute path="/plan-template/update/:id" component={PlanTemplateSave} exact permission={routeAccess.canUpdatePlanTemplate()} />

					<AppRoute path="/manage-template" component={ManageTemplate} exact />
					<AppRoute path="/manage-template/create" component={ManageTemplateSave} exact />
					<AppRoute path="/manage-template/update/:id" component={ManageTemplateSave} exact />
					{/*  <AppRoute path="/manage-template" component={ManageTemplate} exact permission={routeAccess.canViewManageTemplate()} />
					<AppRoute path="/manage-template/create" component={ManageTemplateSave} exact permission={routeAccess.canCreateManageTemplate()} />
					<AppRoute path="/manage-template/update/:id" component={ManageTemplateSave} exact permission={routeAccess.canUpdateManageTemplate()} /> */}

					<AppRoute path="/reports" component={Report} exact permission={routeAccess.canViewReports()} />
					<AppRoute path="/reports/create" component={ReportSave} exact />
					<AppRoute path="/reports/update/:id" component={ReportSave} exact />
					<AppRoute path="/reports/print/:id" component={ReportPrint} exact />
					<AppRoute path="/reports/static" component={StaticReport} exact />

					<AppRoute path="/help-category/create" component={HelpCategorySave} exact permission={routeAccess.canCreateHelpCategory()} />
					<AppRoute path="/help-category/update/:id" component={HelpCategorySave} exact permission={routeAccess.canUpdateHelpCategory()} />
					<AppRoute path="/help" component={Help} exact permission={routeAccess.canViewHelpQuestions()} />
					<AppRoute path="/help-questions/create" component={HelpQuestionSave} exact permission={routeAccess.canCreateHelpQuestions()} />
					<AppRoute path="/help-questions/update/:id" component={HelpQuestionSave} exact permission={routeAccess.canUpdateHelpQuestions()} />

					<AppRoute path="/help-center" component={HelpCenter} exact permission={routeAccess.canViewHelpCenter()} />

					<AppRoute path="/library-management" component={LibraryManagement} exact permission={routeAccess.canViewLibraryManagement()} />
					<AppRoute path="/library-management/create" component={LibraryManagementSave} exact permission={routeAccess.canCreateLibraryManagement()} />
					<AppRoute path="/library-management/update/:id" component={LibraryManagementSave} exact permission={routeAccess.canUpdateLibraryManagement()} />
					<AppRoute path="/library-management/:id/fields" component={LibraryItems} exact permission={routeAccess.canViewLibraryManagement()} />
					<AppRoute path="/library-management/:libraryId/fields/create" component={LibraryItemsSave} exact permission={routeAccess.canCreateLibraryManagement()} />
					<AppRoute
						path="/library-management/:libraryId/fields/update/:fieldId"
						component={LibraryItemsSave}
						exact
						permission={routeAccess.canUpdateLibraryManagement()}
					/>
					<AppRoute path="/library-management/:id/groups" component={LibraryGroups} exact permission={routeAccess.canViewLibraryManagement()} />
					<AppRoute path="/library-management/:libraryId/groups/create" component={LibraryGroupsSave} exact permission={routeAccess.canCreateLibraryManagement()} />
					<AppRoute
						path="/library-management/:libraryId/groups/update/:groupId"
						component={LibraryGroupsSave}
						exact
						permission={routeAccess.canUpdateLibraryManagement()}
					/>
					<Route render={(params) => <Dashboard {...params} />} />
					{/* <Route render={(params)=><NotFound  {...params} />} /> */}
				</Switch>
			</Suspense>
		);
	}
}

export default AppRoutes;
