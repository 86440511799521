import { Map, List } from 'immutable';

import * as actionTypes from '../actions/library-items';

const initialState = new Map({
	fields: new Map({
		isLoaded: false,
		data: new List(),
	}),
	fieldsByLibrary: new Map({
		isLoaded: false,
		fieldsMap: new Map(),
	}),
});

export default (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.FETCH_LIBRARY_ITEMS_LOADED: {
			const { loaded = false } = action.payload;

			return state.updateIn(['fields', 'isLoaded'], () => loaded);
		}
		case actionTypes.FETCH_LIBRARY_ITEMS_BY_LIBRARY_ID_LOADING: {
			const { loaded = false } = action.payload;
			return state.updateIn(['fieldsByLibrary', 'isLoaded'], () => loaded);
		}
		case actionTypes.FETCH_LIBRARY_ITEMS: {
			const libraries = action.payload;

			return state.update(
				'fields',
				() =>
					new Map({
						isLoaded: true,
						data: new List(libraries),
					}),
			);
		}

		case actionTypes.FETCH_LIBRARY_ITEMS_BY_LIBRARY_ID: {
			const { items, libraryId } = action.payload;
			return state.updateIn(['fieldsByLibrary', 'fieldsMap'], (fieldsMap) => {
				return fieldsMap.update(libraryId, () => new List(items));
			});
		}
		default:
			return state;
	}
};
