import React from 'react';
import PropTypes from 'prop-types';

class Checkbox extends React.Component {
	onChange = ({ target }) => {
		const { disabled, field, onChange } = this.props;

		if (disabled) {
			return;
		}

		const { name, checked } = target;

		onChange(checked, name, field);
	};

	render() {
		const { checked, disabled, field, label } = this.props;

		return (
			<label>
				<input
					type="checkbox"
					checked={checked}
					name={field.name}
					disabled={disabled && !checked}
					onChange={this.onChange}
					onClick={this.props.onClick}
					style={this.props.style}
				/>
				{label && <span>{label}</span>}
			</label>
		);
	}
}

Checkbox.propTypes = {
	field: PropTypes.shape({
		name: PropTypes.string,
		type: PropTypes.string,
	}).isRequired,
	checked: PropTypes.bool,
	label: PropTypes.string,
	disabled: PropTypes.bool,
	onChange: PropTypes.func,
	onClick: PropTypes.func,
};

Checkbox.defaultProps = {
	checked: false,
	disabled: false,
	label: null,
	onChange: () => false,
	onClick: () => false,
};

export default Checkbox;
