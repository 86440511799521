import React, { memo } from 'react';
import Textarea from './../Textarea';

const GridTextArea = ({ fieldName, value, rowKey, colKey, onChange }) => {
	return <Textarea field={{ name: `${fieldName}-${rowKey}-${colKey}` }} value={value} onChange={onChange} />;
};

export default memo(GridTextArea, (p, n) => {
	const needsToReRender = false;
	const dontRerender = true;

	if (p && n && typeof p.value !== 'undefined' && typeof n.value !== 'undefined' && p.value !== n.value) {
		return needsToReRender;
	}

	if (p && n && typeof p.fieldName !== 'undefined' && typeof n.fieldName !== 'undefined' && p.fieldName !== n.fieldName) {
		return needsToReRender;
	}

	if (p && n && typeof p.rowKey !== 'undefined' && typeof n.rowKey !== 'undefined' && p.rowKey !== n.rowKey) {
		return needsToReRender;
	}

	if (p && n && typeof p.colKey !== 'undefined' && typeof n.colKey !== 'undefined' && p.colKey !== n.colKey) {
		return needsToReRender;
	}

	return dontRerender;
});
