import { Map, List } from 'immutable';
import * as actionTypes from '../actions/user';
import storage from '../utils/storage';
import config from '../utils/config';

const processUserField = () => {
	let f = storage.get('usersFields') || config('users.fields');
	f = f.filter((r) => r.id != 'role' && r.id != 'impersonate');
	let f1 = f.slice(0, 4);
	let f2 = f.slice(4, f.length);
	f1.push({
		id: 'role',
		text: 'Role',
		value: 'role',
		hidden: false,
	});
	f = f1.concat(f2);
	f.push({
		id: 'impersonate',
		text: 'Impersonate',
		value: 'impersonate',
		hidden: false,
	});
	return f;
};

const initialState = new Map({
	user: new Map({
		isLoaded: false,
		item: {},
	}),
	users: new Map({
		isLoaded: false,
		items: new List(),
	}),
	fields: processUserField(),
});

export default (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.FETCH_USER: {
			return state.update(
				'user',
				() =>
					new Map({
						isLoaded: true,
						item: action.payload,
					}),
			);
		}
		case actionTypes.FETCH_USERS_LOADED: {
			const { loaded = false } = action.payload;

			return state.updateIn(['users', 'isLoaded'], () => loaded);
		}
		case actionTypes.TOGGLE_USER_LOCKED_STATUS: {
			const { userId } = action.payload;
			let items = state.get('users').get('items');
			const index = items.findIndex((user, i) => {
				return user.id === userId;
			});
			if (index !== -1) {
				items = items.update(index, (v) => {
					return { ...v, isLocked: !v.isLocked };
				});
			}
			return state.updateIn(['users', 'items'], () => items);
		}
		case actionTypes.FETCH_USERS: {
			return state.update(
				'users',
				() =>
					new Map({
						isLoaded: true,
						items: new List(action.payload.records),
						total: action.payload.total,
					}),
			);
		}
		case actionTypes.FETCH_USER_FIELDS: {
			return state.set('fields', action.payload);
		}
		case actionTypes.CLEAR_USER: {
			return state.update(
				'user',
				() =>
					new Map({
						isLoaded: false,
						item: {},
					}),
			);
		}
		case actionTypes.SET_USERS_FIELDS: {
			return state.set('fields', action.payload);
		}
		default:
			return state;
	}
};
