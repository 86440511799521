import qs from 'query-string';
import config from '../utils/config';
import storage from './storage';

export const withImageUrl = (name = '', folder = 'uploads') => {
	const params = {
		token: storage.get('token'),
		from: folder,
		companyAlias: storage.get('companyAlias'),
	};

	return `${config('api.url')}resource/attachment/${name}?${qs.stringify(params)}`;
};

export const templateURL = (name = '') => {
	const companyAlias = storage.get('companyAlias');
	const params = {
		token: storage.get('token'),
		from: 'uploads/' + companyAlias + '/templates',
		companyAlias,
	};

	return `${config('api.url')}resource/attachment/${name}?${qs.stringify(params)}`;
};
