import React from 'react';
import PropTypes from 'prop-types';

import Select from 'react-select';

import { isImmutable, List } from 'immutable';
import _ from 'lodash';

//import 'react-select/dist/react-select.css';

class Select2Multiple extends React.Component {
	static propTypes = {
		field: PropTypes.shape({
			name: PropTypes.string,
			type: PropTypes.string,
		}),
		value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.shape({})]),
		options: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.shape({})), PropTypes.arrayOf(PropTypes.string), PropTypes.instanceOf(List)]).isRequired,
		readOnly: PropTypes.bool,
		clearable: PropTypes.bool,
		onChange: PropTypes.func.isRequired,
	};

	static defaultProps = {
		field: {
			name: '',
			type: 'String',
		},
		value: '',
		readOnly: false,
		clearable: false,
	};

	shouldComponentUpdate(nextProps, nextState) {
		if (this.props.value !== nextProps.value) {
			return true;
		}

		if (this.props.options !== nextProps.options) {
			return true;
		}

		if (this.props.field && this.props.field.name && this.props.field.name !== nextProps.field.name) {
			return true;
		}

		return false;
	}

	onChange = (selectedOption) => {
		//console.log("selectedOption=>",selectedOption.map(i => i.value).join(','));
		this.props.onChange(selectedOption.map((i) => i.value).join(','), this.props.field.name);
	};

	render() {
		const options = this.props.options.map((option) => {
			if (typeof option !== 'object') {
				return {
					value: option,
					label: option,
				};
			}
			return option;
		});

		const { field, value, readOnly } = this.props;
		const formattedOptions = isImmutable(options) ? options.toJS() : options;

		// console.log("Select2Multiple render => ", field);

		let selectedValues = [];
		if (value && typeof value === 'string' && value.trim()) {
			selectedValues = value.split(',').map((v) => {
				let found = formattedOptions.filter((i) => i.value === v).shift();
				let label = found ? found.label : '';

				return {
					value: v,
					label: label,
				};
			});
		} else if (value && typeof value === 'object') {
			selectedValues = isImmutable(value) ? value.toJS() : value;
		}

		return (
			<div>
				<Select
					isMulti
					delimiter=","
					name={field.name}
					value={selectedValues}
					options={formattedOptions}
					isDisabled={readOnly}
					isClearable={this.props.clearable}
					onChange={this.onChange}
				/>
			</div>
		);
	}
}

export default Select2Multiple;
