import React, { Fragment } from 'react';

import qs from 'query-string';
import Validator from '../../utils/form-validator';
import toast from '../../utils/toast';
import storage from '../../utils/storage';
import internalApi from '../../utils/internalApi';

import { Link } from 'react-router-dom';
import FormView from '../../lib/FormView';
import { Input, Group, Button, ButtonLink } from '../../components/Form';
import { Flex, Col, Padding, ToastContainer, Image, Text, PasswordStrength, Alert } from '../../components';

import imgLogo from '../../assets/images/logo.png';
import { getTranslatedText } from 'utils/common';

class ResetPassword extends FormView {
	validator = new Validator({
		password: {
			required: true,
			message: 'Password is required',
		},
		passwordConfirmation: {
			required: true,
			message: 'Password Confirmation is required',
		},
	});

	constructor(props) {
		super(props);

		const { token, expired = 0 } = qs.parse(props.location.search);

		this.state = {
			form: {
				password: '',
				passwordConfirmation: '',
				token,
			},
			expired: expired,
			isChanged: false,
			isDataLoading: true,
			strengthMeterVisible: false,
			isStrongPassword: false,
			allowedSettings: {
				allowPublicRegistration: true,
				failedAttemptsAllowed: 5,
				isActive: true,
				lockoutPeriod: 300,
				numberOfCharactersAllowed: 12,
				numberOfPriorPasswordsAllowed: 22,
				passwordExpiration: 0,
			},
		};
	}

	static getDerivedStateFromProps(props) {
		storage.set('companyAlias', props.match.params.companyAlias);
		return {};
	}

	componentDidMount() {
		internalApi.post('public/company/info', { companyAlias: this.props.match.params.companyAlias }).then((res) => {
			const allowedSetting = res.payload.item;
			const { numberOfCharactersAllowed } = allowedSetting;
			this.setState({ allowedSettings: allowedSetting, isDataLoading: false });
		});
	}

	isValid() {
		const [isValid, errors] = this.validator.isValid(this.state.form);
		this.setState({
			errors,
		});
		return { isValid, errors };
	}

	onSend = (e) => {
		e.preventDefault();

		const isValidResponse = this.isValid();

		if (!isValidResponse.isValid) {
			Object.keys(isValidResponse.errors).forEach((key) => {
				toast(isValidResponse.errors[key], 'error');
			});
			// toast('Validation errors', 'error');
			return;
		}

		if (this.state.form.password.length < this.state.allowedSettings.numberOfCharactersAllowed) {
			toast(`Password should be at least ${this.state.allowedSettings.numberOfCharactersAllowed} characters`, 'error');
			return;
		}

		if (!this.state.isStrongPassword) {
			toast(`Password is not strong enough`, 'error');
			return;
		}

		if (this.state.form.password !== this.state.form.passwordConfirmation) {
			toast("Password confirmation doesn't match Password", 'error');
			return;
		}

		internalApi
			.post(
				{
					server: 'auth',
					path: '/reset-password',
				},
				this.state.form,
			)
			.then(({ payload }) => {
				toast(payload.message, 'success');
				this.setState({
					isChanged: true,
					expired: false,
				});
			});
	};

	showStrengthMeter = () => {
		this.setState({ strengthMeterVisible: true });
	};

	hideStrengthMeter = () => {
		this.setState({ strengthMeterVisible: false });
	};

	setisStrongPassword = (isStrong) => {
		this.setState({ isStrongPassword: isStrong });
	};

	render() {
		const { isChanged, isDataLoading, expired } = this.state;

		return (
			<Flex xs={{ justify: 'center', align: 'center', height: '100vh' }}>
				<Col xs={{ size: 3 }}>
					<ToastContainer />
					<Padding padding={{ bottom: 20 }}>
						<Image src={imgLogo} />
					</Padding>
					{expired === '1' && (
						<Alert>
							{getTranslatedText('reaset-password.alert1', 'Your password has been expired,')}
							<br />
							{getTranslatedText('reaset-password.alert2', 'please create a new password to proceed.')}
						</Alert>
					)}
					{!isChanged && (
						<Fragment>
							<Group langLabel="common.password" label="Password">
								<div style={{ position: 'relative' }}>
									{this.state.strengthMeterVisible && (
										<PasswordStrength
											value={this.getField('password')}
											minLength={this.state.allowedSettings.numberOfCharactersAllowed}
											onChange={(isStrong) => this.setisStrongPassword(isStrong)}
										/>
									)}
								</div>
								<Input
									type="password"
									lg
									bordered
									autoComplete="off"
									onFocus={() => this.showStrengthMeter()}
									onBlur={() => this.hideStrengthMeter()}
									value={this.getField('password')}
									onChange={this.onChange('password')}
								/>
							</Group>
							<Group langLabel="common.confirmPassword" label="Confirm Password">
								<Input type="password" lg bordered value={this.getField('passwordConfirmation')} onChange={this.onChange('passwordConfirmation')} />
							</Group>
							<Button isLoading={isDataLoading} lg primary onClick={this.onSend}>
								Reset Password
							</Button>
							<Padding padding={{ top: 15 }}>
								<Flex xs={{ align: 'center', justify: 'center' }}>
									<Link to={`/${this.props.match.params.companyAlias || storage.get('companyAlias')}`}>
										<Text fontSize={16} align="right" color="#0052CC">
											Back to Login
										</Text>
									</Link>
								</Flex>
							</Padding>
						</Fragment>
					)}
					{isChanged && (
						<ButtonLink
							lg
							primary
							linkProps={{
								to: `/${this.props.match.params.companyAlias || storage.get('companyAlias')}`,
							}}
						>
							Back to Login
						</ButtonLink>
					)}
				</Col>
			</Flex>
		);
	}
}

export default ResetPassword;
