import React from 'react';

import { DndProvider } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';

// @DndProvider(HTML5Backend)
export default class DNDContainer extends React.Component {
	render() {
		return (
			<DndProvider backend={HTML5Backend}>
				<div {...this.props}>{this.props.children}</div>
			</DndProvider>
		);
	}
}
