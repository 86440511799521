export default class {
	static id = 'id';
	static lookup = 'lookup';
	static date = 'date';
	static text = 'text';
	static checkbox = 'checkbox';
	static radio = 'radio';
	static grid = 'grid';
	static gridText = 'gridText';
	static gridTable = 'gridTable';
	static textarea = 'textarea';
	static dropdown = 'dropdown';
	static wysiwyg = 'wysiwyg';
	static file = 'file';
	static table = 'table';
	static hyperlink = 'hyperlink';
	static calculatedAverage = 'calculatedAverage';
	static recordSelector = 'recordSelector';
	static summitDownloadBCPlan = '__summit#DownloadBCPlan';
	static swbcDownloadBCPlan = '__swbc#DownloadBCPlan';
	static cprodemoDownloadBCPlan = '__cprodemo#DownloadBCPlan';
	static cprodemoDownloadDisasterRecoveryPlan = '__cprodemo#DownloadDisasterRecoveryPlan';
	static cprodemoDownloadRiskAssessmentPlan = '__cprodemo#DownloadRiskAssessment';
	static ciDownloadBCPlan = '__ci#DownloadBCPlan';
	static nucleaPreBIAModule = '__nuclea#PreBIAModule';
	static nucleaBIASubProcessSummary = '__nuclea#BIASubProcessSummary';
	static nucleaBIAModuleWhatSummary = '__nuclea#BIAModuleWhatSummary';
	static nucleaPCODownloadButton = '__nuclea#PCODownloadButton';
	static nucleaPCOSubProcessList = '__nuclea#PCOSubProcessList';
	static nucleaBIAProcessDetails = '__nuclea#BIAProcessDetails';
	static nucleaBIALocationDetails = '__nuclea#BIALocationDetails';
}
