import React from 'react';
import PropTypes from 'prop-types';
import { Tabs } from 'react-tabs';

import './component.scss';

class AppTabs extends React.Component {
	render() {
		return <Tabs {...this.props}>{this.props.children}</Tabs>;
	}
}

AppTabs.propTypes = {
	children: PropTypes.node.isRequired,
};

export default AppTabs;
