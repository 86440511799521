import _ from 'lodash';
import createAction from '../utils/createAction';
import internalApi from '../utils/internalApi';
import { clearAlerts } from './alerts';
import { arrayMove } from '../utils/arrayHelpers';
import exportCSVFile from '../utils/exportCSVFile';

export const FETCH_PLANS_FIELDS_LOADED = 'FETCH_PLANS_FIELDS_LOADED';
export const FETCH_PLANS_FIELDS = 'FETCH_PLANS_FIELDS';
export const FETCH_PLANS_LOADED = 'FETCH_PLANS_LOADED';
export const FETCH_PLANS = 'FETCH_PLANS';
export const FETCH_PLANS_ITEMS_LOADED = 'FETCH_PLANS_ITEMS_LOADED';
export const FETCH_PLANS_ITEMS = 'FETCH_PLANS_ITEMS';
export const FETCH_VERSION_HISTORY_LOADED = 'FETCH_VERSION_HISTORY_LOADED';
export const FETCH_VERSION_HISTORY = 'FETCH_VERSION_HISTORY';

const fetchPlanItems = (planId, params) => (dispatch) => {
	dispatch(createAction(FETCH_PLANS_ITEMS_LOADED));

	const queryParams = {
		search: params.search,
	};

	internalApi.get(`plans/plans-items/${planId}/list`, { params: queryParams }).then(({ payload }) => {
		dispatch(createAction(FETCH_PLANS_ITEMS, payload.items));
	});
};

const exportCSV = (checkedItems) => () => {
	internalApi
		.get('plans/plans-items/export', {
			params: { checkedItems: checkedItems.join(',') },
		})
		.then((res) => {
			exportCSVFile(res.payload.item, 'plan items');
		});
};

const fetchPlanItem = (id) => () => internalApi.get(`plans/plans-items/one/${id}`);

const savePlanItem = (planId, data) => (dispatch) => {
	dispatch(clearAlerts());

	return internalApi.post(
		data.id ? `plans/plans-items/update/${data.id}` : 'plans/plans-items/create',
		Object.assign(
			{},
			{
				planId,
			},
			_.omit(data, 'id'),
		),
	);
};

const createPlanFromTemplate = (libraryId, planId) => (dispatch) => {
	dispatch(clearAlerts());

	return internalApi.post('plans/create-from-template', {
		libraryId,
		planId,
	});
};

const deletePlanItems = (ids) => () =>
	internalApi.post('plans/plans-items/delete', {
		ids,
	});

const sortPlanItems = (planId, dragIndex, hoverIndex) => (dispatch, getState) => {
	const state = getState();

	const sorted = arrayMove(state.plan.getIn(['planItems', 'items']).toJS(), dragIndex, hoverIndex);

	internalApi
		.post(`plans/plans-items/${planId}/sort`, {
			planItems: sorted.map((i) => i.id),
		})
		.then(() => {
			dispatch(createAction(FETCH_PLANS_ITEMS, sorted));
		});
};

const fetchVersionHistory = (planId) => (dispatch) => {
	dispatch(createAction(FETCH_VERSION_HISTORY_LOADED));

	internalApi.get(`plans/version-history/${planId}/list`).then(({ payload }) => {
		console.log('payload.items', payload.items);
		dispatch(createAction(FETCH_VERSION_HISTORY, payload.items));
	});
};

const createHistoryItem = (data) => (dispatch) => {
	dispatch(clearAlerts());

	return internalApi.post('plans/version-history/create', data);
};

export {
	fetchPlanItems,
	exportCSV,
	createPlanFromTemplate,
	sortPlanItems,
	fetchPlanItem,
	savePlanItem,
	deletePlanItems,
	fetchVersionHistory,
	createHistoryItem,
};
