import React from 'react';
import Icon from 'react-fa';
import Flex, { Col } from './../../Flex';

import styles from './../component.module.scss';

class NotificationTypeText extends React.Component {
	render() {
		const { item, hideNotification } = this.props;

		return (
			<React.Fragment>
				<Flex xs={{ align: 'center', justify: 'space-between' }}>
					<Col xs={11}>
						<a href={item.href} className={styles.href}>
							<span dangerouslySetInnerHTML={{ __html: item.text }}></span>
						</a>
					</Col>
					<Col xs={1}>
						<Icon
							name="times-circle"
							onClick={() => hideNotification(item.id)}
							style={{
								float: 'right',
								fontSize: '16px',
								color: '#505F79',
								boxSizing: 'border-box',
								cursor: 'pointer',
							}}
						/>
					</Col>
				</Flex>
			</React.Fragment>
		);
	}
}

export default NotificationTypeText;
