import { Map, List } from 'immutable';
import config from '../utils/config';
import * as actionTypes from '../actions/reports';
import storage from '../utils/storage';

const initialState = new Map({
	reports: new Map({
		isLoaded: false,
		items: new List(),
	}),
	printable: new Map({
		isLoaded: false,
		items: new List(),
	}),
	fields: storage.get('reportFields') || config('report.fields'),
});

export default (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.FETCH_REPORTS: {
			return state.update(
				'reports',
				() =>
					new Map({
						isLoaded: true,
						items: new List(action.payload.records),
						total: action.payload.total,
					}),
			);
		}
		case actionTypes.FETCH_REPORTS_LOADED: {
			const { loaded = false } = action.payload;

			return state.updateIn(['reports', 'isLoaded'], () => loaded);
		}
		case actionTypes.FETCH_PRINTABLE_DATA: {
			return state.update(
				'printable',
				() =>
					new Map({
						isLoaded: true,
						items: new List(action.payload),
					}),
			);
		}
		case actionTypes.FETCH_PRINTABLE_DATA_LOADED: {
			const { loaded = false } = action.payload;

			return state.updateIn(['printable', 'isLoaded'], () => loaded);
		}
		case actionTypes.SET_REPORTS_FIELDS: {
			return state.set('fields', action.payload);
		}
		default:
			return state;
	}
};
