import _ from 'lodash';
import createAction from '../utils/createAction';
import internalApi from '../utils/internalApi';
import { clearAlerts } from './alerts';
import storage from '../utils/storage';

export const FETCH_REPORTS_LOADED = 'FETCH_REPORTS_LOADED';
export const FETCH_REPORTS = 'FETCH_REPORTS';
export const FETCH_PRINTABLE_DATA_LOADED = 'FETCH_PRINTABLE_DATA_LOADED';
export const FETCH_PRINTABLE_DATA = 'FETCH_PRINTABLE_DATA';
export const SET_REPORTS_FIELDS = 'SET_REPORTS_FIELDS';

const fetchReports =
	({ order = {}, page = 1, limit = 50 } = {}) =>
	(dispatch) => {
		dispatch(createAction(FETCH_REPORTS_LOADED));

		const queryParams = {
			orderBy: order.key,
			sortBy: order.in,
			page,
			limit,
		};

		internalApi.get('reports/list', { params: queryParams }).then((res) => {
			dispatch(createAction(FETCH_REPORTS, res.payload.items));
		});
	};

const fetchReport = (id) => () => internalApi.get(`reports/one/${id}`);

const fetchPrintData =
	(id, { filterType, assigned, filters }) =>
	() =>
		internalApi.post(`reports/print/${id}`, {
			recordIds: assigned,
			filterType,
			filters,
		});

const saveReport = (data) => (dispatch) => {
	dispatch(clearAlerts());

	return internalApi.post(data.id ? `reports/update/${data.id}` : 'reports/create', _.omit(data, 'id'));
};

const fetchPrintableData = (data) => (dispatch) => {
	dispatch(createAction(FETCH_PRINTABLE_DATA_LOADED));

	if (data.report === 'tasks') {
		return internalApi
			.get('task/list', {
				params: { filter: data.filter === 'tasksByStatus' ? '' : data.filter },
			})
			.then((res) => {
				dispatch(createAction(FETCH_PRINTABLE_DATA, res.payload.items));
			});
	}
	// todo: other report types
	return internalApi.get('help/help-category/list').then((res) => {
		dispatch(createAction(FETCH_PRINTABLE_DATA, res.payload.items));
	});
};

const deleteReports = (ids) => () =>
	internalApi.post('reports/delete-multiple', {
		ids,
	});

const onColumnToggleReport = (fieldId, value) => (dispatch, getState) => {
	const state = getState();
	const fields = state.reports.get('fields');

	const newFields = fields.map((field) => {
		if (field.id === fieldId) {
			return { ...field, hidden: !value };
		}
		return { ...field };
	});

	storage.set('reportFields', newFields);

	dispatch(createAction(SET_REPORTS_FIELDS, newFields));
};

//########################## Static reports START ##############################
const fetchRiskAssessment = (params) => () => internalApi.post(`reports/print-static/riskAssessment`, params);
const fetchRiskManagementReportData = (params) => () => internalApi.post(`reports/print-static/riskManagementReportData`, params);
const fetchRiskAssessmentManagementReportData = (params) => () => internalApi.post(`reports/print-static/riskAssessmentManagementReportData`, params);

const fetchTeamsReportData = (params) => () => internalApi.post(`reports/print-static/fetchTeamsReportData`, params);
const fetchSummitBIAReport = (params) => internalApi.post(`reports/print-static/summit/biaReport`, params);
const fetchSummitApplicationGapReport = (params) => internalApi.post(`reports/print-static/summit/applicationGapReport`, params);
const fetchSummitBIAReportForAllCompletedStatus = (params) => internalApi.post(`reports/print-static/summit/biaReportForAllCompletedStatus`, params);
const fetchSummitBCPlanReport = (params) => internalApi.post(`reports/print-static/summit/bcPlanReport`, params);
const fetchCIBCPlanReport = (params) => internalApi.post(`reports/print-static/ci/bcPlanReport`, params);
const fetchSWBCBCPlanReport = (params) => internalApi.post(`reports/print-static/swbc/bcPlanReport`, params);

const fetchCProDemoBCPlanReport = (params) => internalApi.post(`reports/print-static/cpro/bcPlanReport`, params);
const fetchCProDemoDisasterRecoveryPlanReport = (params) => internalApi.post(`reports/print-static/cpro/disasterRecoveryPlanReport`, params);
const fetchCProDemoRiskAssessmentPlanReport = (params) => internalApi.post(`reports/print-static/cpro/riskAssessmentPlanReport`, params);
//########################## Static reports END ##############################

export {
	fetchReports,
	fetchReport,
	fetchPrintData,
	saveReport,
	fetchPrintableData,
	deleteReports,
	onColumnToggleReport,

	/* Static Reports  */
	fetchRiskAssessment,
	fetchTeamsReportData,
	fetchRiskManagementReportData,
	fetchRiskAssessmentManagementReportData,
	fetchSummitBIAReport,
	fetchSummitApplicationGapReport,
	fetchSummitBIAReportForAllCompletedStatus,
	fetchSummitBCPlanReport,
	fetchCProDemoBCPlanReport,
	fetchCProDemoDisasterRecoveryPlanReport,
	fetchCProDemoRiskAssessmentPlanReport,
	fetchCIBCPlanReport,
	fetchSWBCBCPlanReport,
};
