import axios from 'axios';
import config from '../utils/config';
import { store } from '../store/store';
import { pushError } from '../actions/alerts';
import toast from '../utils/toast';
import storage from '../utils/storage';

const instance = axios.create({
	baseURL: config('api.url'),
});

instance.interceptors.response.use(
	(response) => response,
	(error) => {
		const { response } = error;
		let errorMessage = 'Something went wrong!';

		if (response) {
			if (response.data && response.data.payload && response.data.payload.error) {
				errorMessage = response.data.payload.error;
			}

			if (response.status === 403 || response.status === 401) {
				storage.remove('token');
				// window.location.reload();
				return;
			}
		}

		if (store) {
			store.dispatch(pushError(errorMessage));
		}
		toast(errorMessage, 'error');

		return Promise.reject(response);
	},
);

export default instance;
