import React, { Suspense } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import windowDimensions from 'react-window-dimensions';
import debounce from 'lodash.debounce';
import { wDimensions } from './../../constants';
import internalApi from './../../utils/internalApi';
import { ButtonLink } from '../../components/Form';
import AppRoutes from './routes';
import ReactTooltip from 'react-tooltip';

import styles from './index.module.scss';

import { Sidebar, SidebarMenuItem, SidebarUser, Main, Header, Logo, Footer } from '../../components/Layout';
import { Flex, Col, Padding, Text, Loading, Notifications, FilterWidget, ToastContainer, DND, Modal, RenderIf } from '../../components';
import { Button } from '../../components/Form';
import Icon from 'react-fa';

import { fetchUser, clearUser } from '../../actions/user';
import { fetchUserPermissions } from '../../actions/role';
import { clearToken } from '../../actions/auth';
import { fetchLibraries } from '../../actions/library'; //toggleLibraryAsWidget
import { toggleParam, setParam } from '../../actions/ui';
import { getCompanyInfo } from '../../actions/company-settings';
import { fetchNotifications, hideNotification } from '../../actions/notification';
import { fetchDashboardWidgets, toggleLibraryAsWidget } from '../../actions/dashboard';

import storage from '../../utils/storage';
import configureMenu from '../../utils/configureMenu';
import { hasPerm } from '../../utils/accessControl';
import IdleTimer from '../../utils/IdleTimer';
import { MdOutlineWidgets } from 'react-icons/md';
import { getTranslatedText } from 'utils/common';

class App extends React.Component {
	static propTypes = {
		sidebarIsClose: PropTypes.bool.isRequired,
		notificationIsOpen: PropTypes.bool.isRequired,
		filterWidgetIsOpen: PropTypes.bool.isRequired,
		isLoaded: PropTypes.bool.isRequired,
		companyInfo: PropTypes.shape({}).isRequired,
		fetchUser: PropTypes.func.isRequired,
		fetchUserPermissions: PropTypes.func.isRequired,
		fetchLibraries: PropTypes.func.isRequired,
		getCompanyInfo: PropTypes.func.isRequired,
		clearUser: PropTypes.func.isRequired,
		onToggleSidebarIsClose: PropTypes.func.isRequired,
		onToggleNotificationIsOpen: PropTypes.func.isRequired,
		onToggleFilterWidgetIsOpen: PropTypes.func.isRequired,
		closeFilterWidgetIfOpen: PropTypes.func.isRequired,
	};

	state = {
		notifications: [
			{
				name: 'Test notification 1',
				href: '',
			},
			{
				name: 'Test notification 2',
				href: '',
			},
			{
				name: 'Test notification 3',
				href: '',
			},
		],
		isOpen: false,
		secs: 120,
	};
	timer;
	timerSec;
	timerCount;
	notificationTimer = null;

	componentDidMount() {
		this.getData();
		if (!this.notificationTimer) {
			// Fetch notifications every 3 minutes;
			this.notificationTimer = setInterval(() => this.fetchNotifications(), 1000 * 60 * 1);
		}

		//window.addEventListener("beforeunload", this.handleWindowUnload);
	}

	handleWindowUnload = (ev) => {
		storage.remove('token');
		storage.remove('companyAlias');
		//ev.preventDefault();
		//return ev.returnValue = 'Are you sure you want to close?';
	};

	getData = () => {
		this.props.fetchUser();
		this.props.fetchUserPermissions();
		this.props.fetchLibraries();
		this.props.fetchDashboardWidgets();
		this.fetchNotifications();
		this.props.getCompanyInfo();

		this.timer = new IdleTimer(
			() => {
				this.logOut();
			},
			1000 * 60 * 15,
		);
		this.timer.activate();

		this.timerSec = new IdleTimer(
			() => {
				this.timerCount = setInterval(() => {
					if (this.state.secs < 2) {
						this.logOut();
						return;
					}
					this.setState({
						secs: this.state.secs - 1,
					});
				}, 1000);
				this.setState({
					isOpen: true,
				});
			},
			1000 * 60 * 13,
		);
		this.timerSec.activate();
	};

	resetTimer = () => {
		this.setState({
			secs: 120,
		});
		this.timer.resetTimer();
		this.timerSec.resetTimer();
	};

	componentDidUpdate(prevProps) {
		if (this.props.token != prevProps.token) {
			this.getData();
		}
	}

	componentWillUnmount() {
		this.timer.deactivate();
		this.timerSec.deactivate();
		//window.removeEventListener("beforeunload", this.handleWindowUnload);
		if (this.notificationTimer) {
			clearTimeout(this.notificationTimer);
		}
	}

	fetchNotifications = () => {
		this.props.fetchNotifications();
	};

	logOut = async () => {
		const token = storage.get('token');
		if (!token) {
			return;
		}

		let result = await internalApi.post({
			server: 'auth',
			path: '/logout',
		});
		// console.log(result);

		const pathname = storage.get('companyAlias') ? `/${storage.get('companyAlias')}/logout` : '/';

		storage.remove('token');
		storage.remove('companyAlias');
		this.props.clearUser();
		this.props.history.push(pathname);
	};

	onFilterWidget = (userId, type) => {
		this.props.toggleDashboardWidget(userId, type);
	};

	hideThisNotification(notificationId) {
		this.props.onHideNotification(notificationId);
	}

	render() {
		const {
			menu,
			widgetItems,
			user,
			isLoaded,
			notificationIsOpen,
			filterWidgetIsOpen,
			companyInfo,
			notifications,
			libraries,
			isAdmin,
			isSuperAdmin,
			windowWidth,
		} = this.props;

		//let whichView = 'desktop';
		let sidebarIsClose = this.props.sidebarIsClose;

		if (!isLoaded) {
			return <Loading />;
		}

		// If device is mobile then close the sidebar
		if (windowWidth < wDimensions.mobileBreakPoint) {
			//whichView = 'mobile';
			sidebarIsClose = true;
		}

		return (
			<div>
				<Suspense fallback={<Loading fixed={false} size="sm" type="ball-pulse-sync" />}>
					<Sidebar isClose={sidebarIsClose}>
						<SidebarUser user={user.get('item')} isClose={sidebarIsClose} />
						{menu
							.filter((item) => {
								if (item?.hideIfChildrenEmpty && item?.items && !item?.items?.filter?.((i) => hasPerm(i.permission))?.length) {
									return false;
								}

								if (item.name === 'Libraries' && item.items && !item.items.filter((i) => hasPerm(i.permission)).size) {
									return false;
								}

								if (typeof item.permission === 'undefined') {
									return true;
								}

								return hasPerm(item.permission);
							})
							.map((item, key) => (
								<SidebarMenuItem
									key={key}
									menuItem={item}
									sidebarIsClose={sidebarIsClose}
									isAdmin={this.props.isAdmin}
									isSuperAdmin={this.props.isSuperAdmin}
									companyInfo={this.props.companyInfo}
								/>
							))}
					</Sidebar>
				</Suspense>
				<Main isHidden={sidebarIsClose}>
					<Header>
						<Flex xs={{ justify: 'space-between', align: 'center' }}>
							<Col lg={{ size: 9 }} md={{ size: 7 }} xs={{ size: 5, left: 15, top: 7, bottom: 7 }}>
								<RenderIf view="desktop">
									<Icon
										name="bars"
										style={{
											marginRight: 20,
											fontSize: 20,
											width: 38,
											height: 30,
											textAlign: 'center',
											verticalAlign: 'middle',
											paddingTop: 5,
											backgroundColor: '#0052CC',
											color: '#fff',
											borderRadius: 3,
											cursor: 'pointer',
										}}
										onClick={this.props.onToggleSidebarIsClose}
										className={styles.sideIcon}
									/>
								</RenderIf>
								<Logo logo={companyInfo.logo} />
							</Col>
							<Col lg={{ size: 3 }} md={{ size: 4 }} xs={{ size: 7 }}>
								<Flex xs={{ justify: 'flex-end' }}>
									<Col lg={{ size: 1 }} md={{ size: 1 }} sm={{ size: 1 }} xs={{ size: 1.5 }}>
										<ButtonLink
											linkProps={{
												to: `/help-center`,
											}}
											style={{ width: 'auto', backgroundColor: '#fff', color: '#676a6c', height: 'auto', paddingTop: 0, paddingLeft: 0, lineHeight: 0, marginRight: 3 }}
										>
											<Icon style={{ cursor: 'pointer', fontSize: 14 }} name="question-circle" data-tip={getTranslatedText("help.center", "Help Center")} data-place="bottom" />
										</ButtonLink>
									</Col>

									<Col lg={{ size: 1 }} md={{ size: 1 }} sm={{ size: 1 }} xs={{ size: 1.5 }}>
										<MdOutlineWidgets
											style={{ cursor: 'pointer', fontSize: 14 }}
											name="Filters"
											data-tip={eval(getTranslatedText("header.widgets", "Widgets"))}
											data-place="bottom"
											onClick={this.props.onToggleFilterWidgetIsOpen}
										/>
										{filterWidgetIsOpen && (
											<FilterWidget
												isOpen={filterWidgetIsOpen}
												isAdmin={isAdmin}
												widgetItems={widgetItems}
												onClick={this.onFilterWidget}
												closeFilterWidgetIfOpen={this.props.closeFilterWidgetIfOpen}
											/>
										)}
									</Col>
									<Col lg={{ size: 1 }} md={{ size: 1 }} sm={{ size: 1 }} xs={{ size: 1.5 }}>
										<div style={{ position: 'relative', paddingRight: 5 }}>
											<Icon
												style={{ cursor: 'pointer', fontSize: 14 }}
												className={notifications.size > 0 ? styles.bell : ''}
												name="bell"
												data-tip={getTranslatedText("common.notifications", "Notifications")}
												data-place="bottom"
												onClick={this.props.onToggleNotificationIsOpen}
											/>
											{notifications.size > 0 && <div className={styles.bellCount}>{notifications.size}</div>}

											{notificationIsOpen && (
												<Notifications
													isOpen={notificationIsOpen}
													items={notifications}
													closeNotificationsWindow={this.props.onToggleNotificationIsOpen.bind(this)}
													hideNotification={this.hideThisNotification.bind(this)}
												/>
											)}
										</div>
									</Col>
									<Col lg={{ size: 4 }} md={{ size: 4 }} sm={{ size: 4 }} xs={{ size: 4 }}>
										<Text fontSize={14} fontWeight={600} onClick={this.logOut}>
											{getTranslatedText('common.logOut', 'Log Out')}
										</Text>
									</Col>
								</Flex>
							</Col>
						</Flex>
					</Header>
					<div>
						<DND>
							<Padding padding={{ top: 20, left: 20, right: 20, bottom: 100 }}>
								<AppRoutes resetTimer={this.resetTimer} />
							</Padding>
						</DND>
					</div>
					<Footer />
					<ToastContainer />
				</Main>

				<Modal width={540} hasPadding isOpen={this.state.isOpen} style={{ padding: 0, overflow: 'auto' }}>
					<Padding padding={{ top: 20 }}>
						<Flex xs={{ justify: 'center', align: 'center' }}>
							<Text fontWeight={600} fontSize={18}>
								{getTranslatedText("auth.sessionWarning", "Your working session is about to expire.")}
							</Text>
						</Flex>
						<br />
						<br />
						<Flex xs={{ justify: 'center', align: 'center' }}>
							<Text fontSize={11}>{getTranslatedText("auth.sessionWarning2", "You will be signed out in")}...</Text>
						</Flex>
						<br />
						<Flex xs={{ justify: 'center', align: 'center' }}>
							<Text fontWeight={900} fontSize={20}>
								{this.state.secs} {getTranslatedText("common.seconds", "SECONDS")}
							</Text>
						</Flex>
						<br />
					</Padding>
					<Padding padding={{ top: 0 }}>
						<Flex xs={{ justify: 'center', align: 'center' }}>
							<Col xs={{ size: 6 }}>
								<Button danger onClick={this.logOut} style={{ borderRadius: 0, height: 40, fontWeight: 'normal', background: '#fc7169' }}>
									{getTranslatedText("common.signOut", "Sign out")}
								</Button>
							</Col>
							<Col xs={{ size: 6 }}>
								<Button primary onClick={() => this.setState({ isOpen: false })} style={{ borderRadius: 0, height: 40, fontWeight: 'normal', background: '#b6bece' }}>
									{getTranslatedText("auth.sessionWarning3", "Stay signed in")}
								</Button>
							</Col>
						</Flex>
					</Padding>
				</Modal>
				<ReactTooltip effect="solid" />
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	const libraries = state.library
		.get('libraries')
		.get('data')
		.filter((i) => i.isActive);

	return {
		isLoaded: state.user.get('user').get('isLoaded'),
		menu: configureMenu(state),
		widgetItems: state.dashboard.get('widgets').toJS(),
		user: state.user.get('user'),
		token: state.auth.get('token'),
		libraries,
		sidebarIsClose: state.ui.get('sidebarIsClose'),
		notificationIsOpen: state.ui.get('notificationIsOpen'),
		filterWidgetIsOpen: state.ui.get('filterWidgetIsOpen'),
		notifications: state.notification.get('notifications').get('items'),
		companyInfo: state.company.get('info'),
		isAdmin: state.roles.get('currentUserPermissions').get('isAdmin'),
		isSuperAdmin: state.roles.get('currentUserPermissions').get('isSuperAdmin'),
	};
};

const mapDispatchToProps = (dispatch, ownProps) => ({
	onToggleSidebarIsClose: () => {
		dispatch(toggleParam('sidebarIsClose'));
	},
	onToggleNotificationIsOpen: () => {
		dispatch(toggleParam('notificationIsOpen'));
	},
	onToggleFilterWidgetIsOpen: () => {
		dispatch(toggleParam('filterWidgetIsOpen'));
	},
	closeFilterWidgetIfOpen: () => {
		dispatch(setParam('filterWidgetIsOpen', false));
	},
	fetchUser: () => {
		dispatch(fetchUser());
	},
	fetchUserPermissions: () => {
		dispatch(fetchUserPermissions());
	},
	clearUser: () => {
		dispatch(clearToken());
		dispatch(clearUser());
	},
	fetchLibraries: () => {
		dispatch(fetchLibraries());
	},
	fetchDashboardWidgets: () => {
		dispatch(fetchDashboardWidgets());
	},
	fetchNotifications: () => {
		dispatch(fetchNotifications());
	},
	onHideNotification: (id) => {
		dispatch(hideNotification(id));
	},
	getCompanyInfo: () => {
		dispatch(getCompanyInfo());
	},
	toggleDashboardWidget: (id, type) => {
		dispatch(toggleLibraryAsWidget(id, type));
	},
});

const AppWithDimentions = windowDimensions({
	take: () => ({ windowWidth: window.innerWidth }),
	debounce: (onResize) => debounce(onResize, 500),
})(App);

export default connect(mapStateToProps, mapDispatchToProps)(AppWithDimentions);
