import { List, Map } from 'immutable';

import * as actionTypes from '../actions/approval-history';

const initialState = new Map({
	isLoaded: false,
	items: new List(),
});

export default (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.IN_PROGRESS: {
			return state.set('isLoaded', action.payload);
		}

		case actionTypes.FETCH_APPROVAL_HISTORY_LOADED: {
			const { loaded = false } = action.payload;

			state = state.update('isLoaded', () => loaded);
			return state.update('items', () => new List(action.payload.records));
		}

		default:
			return state;
	}
};
