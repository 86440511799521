import React from 'react';
import PropTypes from 'prop-types';
import styles from './component.module.scss';
import windowDimensions from 'react-window-dimensions';
import debounce from 'lodash.debounce';
import { wDimensions } from './../../constants';

const RenderIf = ({ windowWidth, view, ...props }) => {
	const style = {
		...props.style,
	};

	if (view === 'mobile' && windowWidth > wDimensions.mobileBreakPoint) {
		return null;
	}

	if (view === 'desktop' && windowWidth < wDimensions.desktopBreakPoint) {
		return null;
	}

	return <React.Fragment>{props.children}</React.Fragment>;
};

RenderIf.propTypes = {
	children: PropTypes.node,
	style: PropTypes.shape({}),
	view: PropTypes.string.isRequired,
};

RenderIf.defaultProps = {
	view: 'desktop',
	style: {},
};

export default windowDimensions({
	take: () => ({ windowWidth: window.innerWidth }),
	debounce: (onResize) => debounce(onResize, 500),
})(RenderIf);
