import { Map, List } from 'immutable';
import * as actionTypes from '../actions/role';
import storage from '../utils/storage';
import config from '../utils/config';

const initialState = new Map({
	roles: new Map({
		isLoaded: false,
		items: new List(),
	}),
	currentUserPermissions: new Map({
		isLoaded: false,
		items: new List(),
	}),
	fields: storage.get('rolesFields') || config('roles.fields'),
});

export default (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.FETCH_ROLES_LOADED: {
			const { loaded = false } = action.payload;

			return state.updateIn(['roles', 'isLoaded'], () => loaded);
		}
		case actionTypes.FETCH_ROLES: {
			return state.update(
				'roles',
				() =>
					new Map({
						isLoaded: true,
						items: new List(action.payload.records),
						total: action.payload.total,
					}),
			);
		}
		case actionTypes.FETCH_CURRENT_USER_PERMISSIONS_LOADED: {
			const { loaded = false } = action.payload;

			return state.updateIn(['currentUserPermissions', 'isLoaded'], () => loaded);
		}
		case actionTypes.FETCH_CURRENT_USER_PERMISSIONS: {
			return state.update(
				'currentUserPermissions',
				() =>
					new Map({
						isLoaded: true,
						items: new List(action.payload.permissions),
						isAdmin: action.payload.isAdmin,
						isSuperAdmin: action.payload.isSuperAdmin,
					}),
			);
		}
		case actionTypes.SET_ROLES_FIELDS: {
			return state.set('fields', action.payload);
		}
		default:
			return state;
	}
};
