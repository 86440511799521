import React, { forwardRef, memo, useState } from 'react';
import styles from './FullCalendar.module.scss';
import _ from 'lodash';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { createOrSaveLibraryRecord } from '../../api/records';
import { Calendar, Views, momentLocalizer } from 'react-big-calendar';
import Modal from '../Modal';
import { Button, Group, Input } from '../Form';
import Flex, { Col } from '../Flex';
import { getTranslatedText } from 'utils/common';

const FullCalendarComponent = forwardRef((props: any, ref) => {
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [selectedDateTime, setSelectedDateTime] = useState({
		startDate: '',
		startTime: '',
		dueDate: '',
		dueTime: '',
	});
	const [eventName, setEventName] = useState('');
	const history = useHistory();
	const localizer = momentLocalizer(moment);

	const onSelectEvent = (params) => {
		history.push(`/library/demo-calendar/update/${params?.id}`);
	};
	const handleSelectSlot = ({ start, end }) => {
		setIsModalOpen(true);
		setSelectedDateTime({
			startDate: moment(start).format('YYYY-MM-DD'),
			startTime: moment(start).format('HH:mm:ss'),
			dueDate: moment(end).format('YYYY-MM-DD'),
			dueTime: moment(end).format('HH:mm:ss'),
		});
	};

	const handleEventName = (value: any) => {
		setEventName(value);
	};

	const handleSubmitEvent = (e: any) => {
		e.preventDefault();
		if (eventName.trim() === '') {
			alert(getTranslatedText('error.name', 'Name is Required.'));
			return '';
		}
		createOrSaveLibraryRecord(props?.libraryId, {
			...selectedDateTime,
			name: eventName,
		}).then((response) => {
			setIsModalOpen(false);
			history.push(`/library/demo-calendar/update/${response?.payload?.data}`);
		});
	};

	const CalendarToolbar = (props) => {
		const { label, view, views, onView, onNavigate } = props;
		return (
			<div className="rbc-toolbar" style={{ display: 'flex', justifyContent: 'space-between' }}>
				<div className="rbc-btn-group">
					<button onClick={() => onNavigate('TODAY')}>{getTranslatedText('common.today', 'Today')}</button>
					<button onClick={() => onNavigate('PREV')}>{getTranslatedText('common.back', 'Back')}</button>
					<button onClick={() => onNavigate('NEXT')}>{getTranslatedText('common.next', 'Next')}</button>
				</div>
				{label}
				<div className="rbc-btn-group">
					<button type="button" onClick={() => history.push(`/library/demo-calendar/create`)}>
						{getTranslatedText('common.addItem', 'Add Item')}
					</button>
					{views?.map((item) => (
						<button
							onClick={() => {
								onView(item);
							}}
							key={item}
							className={view === item ? 'rbc-active' : ''}
						>
							{_.capitalize(item)}
						</button>
					))}
				</div>
			</div>
		);
	};

	const statusColorMap = {
		'In Progress': '',
		Complete: 'green',
		'On Hold': '#FFBA00',
	};

	return (
		<div className={styles.root}>
			<Calendar
				localizer={localizer}
				startAccessor="start"
				endAccessor="end"
				initialView="dayGridMonth"
				{...props}
				defaultView={Views.WEEK}
				onSelectEvent={onSelectEvent}
				onSelectSlot={handleSelectSlot}
				components={{
					toolbar: CalendarToolbar,
				}}
				eventPropGetter={(calEvent) => {
					const backgroundColor = statusColorMap[calEvent.status];
					return { style: { backgroundColor, border: '0px' } };
				}}
			/>
			<Modal maxWidth={450} title={'Add Event'} isOpen={isModalOpen} style={{ zIndex: 1 }}>
				<Group langLabel="common.name" label="Name" required>
					<Input bordered value={eventName} onChange={handleEventName} />
				</Group>
				<Group langLabel="common.startDate" label="Start Date">
					<Input type={'date'} value={selectedDateTime?.startDate ?? ''} onChange={(val) => setSelectedDateTime({ ...selectedDateTime, startDate: val })} />
				</Group>
				<Group langLabel="common.startTime" label="Start Time">
					<Input type={'time'} value={selectedDateTime?.startTime ?? ''} onChange={(val) => setSelectedDateTime({ ...selectedDateTime, startTime: val })} />
				</Group>
				<Group langLabel="common.endDate" label="End Date">
					<Input type={'date'} value={selectedDateTime?.dueDate ?? ''} onChange={(val) => setSelectedDateTime({ ...selectedDateTime, dueDate: val })} />
				</Group>
				<Group langLabel="common.endTime" label="End Time">
					<Input type={'time'} value={selectedDateTime?.dueTime ?? ''} onChange={(val) => setSelectedDateTime({ ...selectedDateTime, dueTime: val })} />
				</Group>
				<Flex>
					<Col xs={{ size: 3, right: 10 }}>
						<Button onClick={() => setIsModalOpen(false)}>{getTranslatedText('common.cancel', 'Cancel')}</Button>
					</Col>
					<Col xs={4}>
						<Button primary onClick={handleSubmitEvent}>
							{getTranslatedText('common.save', 'Save')}
						</Button>
					</Col>
				</Flex>
			</Modal>
		</div>
	);
});

export default memo(FullCalendarComponent);
