import React from 'react';
import PropTypes from 'prop-types';
// import RichTextEditor from 'react-rte';
import { Editor } from '@tinymce/tinymce-react';
// import ReactQuill from 'react-quill';
// import 'react-quill/dist/quill.snow.css';

class TextEditor extends React.Component {
	static propTypes = {
		field: PropTypes.shape({
			name: PropTypes.string,
			type: PropTypes.string,
		}).isRequired,
		placeholder: PropTypes.string,
		onChange: PropTypes.func,
	};

	static defaultProps = {
		placeholder: '',
		onChange: () => false,
	};

	constructor(props) {
		super(props);

		this.state = {
			value: props.value || props.defaultValue,
		};
	}

	componentWillReceiveProps(nextProps) {
		//console.log("richtext: -> ",nextProps);
		if (
			typeof this.props.value !== 'undefined' &&
			typeof nextProps.value !== 'undefined' &&
			this.props.value !== nextProps.value &&
			this.props.value.trim() == ''
		) {
			this.setState({
				value: nextProps.value || this.props.defaultValue,
			});
		}
	}

	onChange = (value) => {
		this.setState({ value });
		this.props.onChange(value, this.props.field.name);
	};

	render() {
		// console.log("wysiwyg editor ", this.props.field);

		return (
			<div style={{ wordBreak: 'break-word', zIndex: 0, position: 'relative' }}>
				<Editor
					placeholder={this.props.placeholder}
					apiKey="hn1eldphgohpvxo74efaste3jc5mftncz5hin764an1r42on"
					//initialValue="<p>This is the initial content of the editor</p>"
					value={this.state.value}
					//  tinymceScriptSrc="https://cdn.tiny.cloud/1/no-api-key/tinymce/5/tinymce.min.js"
					init={{
						height: 400,
						menubar: false,
						//  plugins: [
						//    'advlist autolink lists link image charmap print preview anchor',
						//    'searchreplace visualblocks code fullscreen',
						//    'insertdatetime media table paste code help wordcount'
						//  ],
						plugins: ['paste lists link wordcount'],
						paste_as_text: true,
						toolbar:
							'undo redo | formatselect | bold italic | backcolor forecolor link | \
             alignleft aligncenter alignright | \
             bullist numlist outdent indent | paste removeformat', // formatselect alignjustify backcolor | help
					}}
					onEditorChange={this.onChange}
				/>
				{/* <ReactQuill placeholder={this.props.placeholder} theme="snow" value={this.state.value} onChange={this.onChange} /> */}
			</div>
		);
	}
}

export default TextEditor;
