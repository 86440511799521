import React from 'react';
import Icon from 'react-fa';
import ClickOutside from 'react-click-outside';

import classNames from 'classnames';
import styles from './component.module.scss';
import NotificationTypeText from './types/Text';
import NotificationTypeRecordWaitingForApproval from './types/RecordWaitingForApproval';

class Notifications extends React.Component {
	render() {
		const { items, isOpen, closeNotificationsWindow } = this.props;

		if (!items || items.size == 0) {
			return null;
		}

		this.hideNotification = (item) => {
			this.props.hideNotification(item);
		};

		return (
			<ClickOutside onClickOutside={closeNotificationsWindow}>
				<ul
					className={classNames(styles.root, {
						[styles.open]: isOpen,
					})}
					style={{ zIndex: 1 }}
				>
					{items.map((item, key) => (
						<li key={key} className={styles.items}>
							{item.type === 'text' && (
								<NotificationTypeText item={item} hideNotification={this.hideNotification.bind(this)} closeNotificationsWindow={closeNotificationsWindow} />
							)}
							{(item.type === 'recordWaitingForApproval' ||
								item.type === 'recordApproved' ||
								item.type === 'recordRejected' ||
								item.type === 'recordRequiresReview') && (
								<NotificationTypeRecordWaitingForApproval
									item={item}
									hideNotification={this.hideNotification.bind(this)}
									closeNotificationsWindow={closeNotificationsWindow}
								/>
							)}
						</li>
					))}
				</ul>
			</ClickOutside>
		);
	}
}

export default Notifications;
