import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { List } from 'immutable';
import Validator from '../../utils/form-validator';
import config from '../../utils/config';
import toast from '../../utils/toast';

import FormView from '../../lib/FormView';
import { Input, Group, Button } from '../../components/Form';
import { Flex, Col, Padding, ToastContainer } from '../../components';
import Logo from '../../components/Layout/Logo';

import { signUp } from '../../actions/auth';
import storage from '../../utils/storage';

class Register extends FormView {
	static propTypes = {
		responseAlerts: PropTypes.instanceOf(List),
		signUp: PropTypes.func.isRequired,
	};

	static defaultProps = {
		responseAlerts: new List(),
	};

	state = {
		form: {
			firstName: '',
			lastName: '',
			username: '',
			phone: '',
			email: '',
			alternateEmail: '',
			avatar: '',
		},
		reCaptcha: true,
		errors: {},
		submitInProgress: false,
	};

	validator = new Validator({
		firstName: {
			required: true,
			message: 'First Name is required',
		},
		lastName: {
			required: true,
			message: 'Last Name is required',
		},
		username: {
			required: true,
			message: 'Username is required',
		},
		email: {
			required: true,
			message: 'Email is required',
		},
		// password: {
		//   required: true,
		//   message: 'Email is required',
		// },
		// confirmPassword: {
		//   required: true,
		//   message: 'Email is required',
		// },
	});

	isValid() {
		const [isValid, errors] = this.validator.isValid(this.state.form);
		this.setState({
			errors,
		});
		return isValid;
	}

	static getDerivedStateFromProps(props) {
		storage.set('companyAlias', props.match.params.companyAlias);
		return {};
	}

	onRegister = (e) => {
		e.preventDefault();
		this.setState({
			submitInProgress: true,
		});

		if (this.state.reCaptcha) {
			if (this.state.form.password === this.state.form.confirmPassword) {
				this.props.signUp(this.isValid() && this.state.form).then(() => {
					toast('Thank you for registering! Your account is pending for approval.', 'success');

					setTimeout(() => {
						this.onBack();
					}, 2500);
				});
			} else {
				this.setState({
					submitInProgress: false,
				});
				toast('Password does not match the confirm password', 'error');
			}
		} else {
			this.setState({
				submitInProgress: false,
			});
			window.location.href = config('reCaptcha.redirectUrl');
		}
	};

	onBack = () => {
		let companyAlias = storage.get('companyAlias');
		this.props.history.push(`/${companyAlias}`);
	};

	onChangeReCaptcha = () => {
		this.setState({
			reCaptcha: true,
		});
	};

	render() {
		return (
			<Padding padding={{ top: 50, bottom: 50 }}>
				<Flex xs={{ align: 'center', justify: 'center' }}>
					<Col xs={{ size: 3 }}>
						<Padding padding={{ bottom: 20 }}>
							<Logo />
						</Padding>
						<Group langLabel='common.firstName' label="First Name">
							<Input bordered value={this.getField('firstName')} onChange={this.onChange('firstName')} />
						</Group>
						<Group langLabel='common.lastName' label="Last Name">
							<Input bordered value={this.getField('lastName')} onChange={this.onChange('lastName')} />
						</Group>
						<Group langLabel='common.username' label="Username">
							<Input bordered value={this.getField('username')} onChange={this.onChange('username')} />
						</Group>
						<Group langLabel='common.email' label="Email">
							<Input bordered value={this.getField('email')} onChange={this.onChange('email')} />
						</Group>
						{/* <Group langLabel='common.password' label="Password">
              <Input
                bordered
                type="password"
                value={this.getField('password')}
                onChange={this.onChange('password')}
              />
            </Group>
            <Group langLabel='common.confirmPassword' label="Confirm Password">
              <Input
                bordered
                type="password"
                value={this.getField('confirmPassword')}
                onChange={this.onChange('confirmPassword')}
              />
            </Group>
            <Group langLabel='common.alternateEmail' label="Alternate Email">
              <Input
                bordered
                value={this.getField('alternateEmail')}
                onChange={this.onChange('alternateEmail')}
              />
            </Group>
            <Group langLabel='common.phone' label="Phone">
              <Input
                bordered
                value={this.getField('phone')}
                onChange={this.onChange('phone')}
              />
            </Group>*/}
						<Button isLoading={this.state.submitInProgress} readonly={this.state.submitInProgress} primary onClick={this.onRegister}>
							Register
						</Button>
					</Col>
				</Flex>
				<Padding padding={{ top: 20 }}>
					<Flex xs={{ align: 'center', justify: 'center' }}>
						<Col xs={{ size: 2 }}>
							<Button primary onClick={this.onBack}>
								Back to Login
							</Button>
						</Col>
					</Flex>
				</Padding>
				<ToastContainer />
			</Padding>
		);
	}
}

const mapStateToProps = (state) => ({
	responseAlerts: state.alerts.get('errors'),
});

const mapDispatchToProps = (dispatch) => ({
	signUp: (data) => dispatch(signUp(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Register);
