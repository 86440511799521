import React from 'react';
import { ToastContainer as Toaster, style } from 'react-toastify';

style({
	colorDefault: '#fff',
	colorInfo: '#0052CC',
	colorSuccess: '#14892c',
	colorWarning: '#f1c40f',
	colorError: '#d9534f',
});

const ToastContainer = () => (
	<div>
		<Toaster hideProgressBar newestOnTop autoClose={4000} />
	</div>
);

export default ToastContainer;
