import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Flex from '../../Flex';

import classNames from 'classnames';
import cs from './component.module.scss';

class ButtonLink extends React.PureComponent {
	render() {
		const { fullWidth, primary, danger, lg, style: propsStyle, linkProps, isExternal, readonly } = this.props;

		const style = {
			width: fullWidth ? '100%' : 220,
			padding: fullWidth ? '' : '0 53px',
			fontWeight: 700,
			...propsStyle,
		};

		if (isExternal) {
			return (
				<a
					{...linkProps}
					className={classNames(cs.root, {
						[cs.lg]: lg,
						[cs.primary]: primary,
						[cs.danger]: danger,
						[cs.readonly]: readonly,
					})}
					style={style}
				>
					<Flex xs={{ justify: 'center', align: 'center', height: '100%' }}>
						<div style={{ paddingLeft: '5px', paddingRight: '5px' }}>{this.props.children}</div>
					</Flex>
				</a>
			);
		}

		return (
			<Link
				{...linkProps}
				className={classNames(cs.root, {
					[cs.lg]: lg,
					[cs.primary]: primary,
					[cs.danger]: danger,
					[cs.readonly]: readonly,
				})}
				style={style}
			>
				<Flex xs={{ justify: 'center', align: 'center', height: '100%' }}>
					<div style={{ paddingLeft: '5px', paddingRight: '5px' }}>{this.props.children}</div>
				</Flex>
			</Link>
		);
	}
}

ButtonLink.propTypes = {
	children: PropTypes.node.isRequired,
	primary: PropTypes.bool,
	fullWidth: PropTypes.bool,
	danger: PropTypes.bool,
	lg: PropTypes.bool,
	isExternal: PropTypes.bool,
	style: PropTypes.shape({}),
	linkProps: PropTypes.shape({}),
};

ButtonLink.defaultProps = {
	fullWidth: true,
	primary: false,
	danger: false,
	lg: false,
	isExternal: false,
	linkProps: {},
	style: {},
};

export default ButtonLink;
