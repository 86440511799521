import { store } from '../store/store';

export const hasPerm = (perm) => {
	const state = store.getState();
	const isAdmin = state.roles.get('currentUserPermissions').get('isAdmin');
	const isSuperAdmin = state.roles.get('currentUserPermissions').get('isSuperAdmin');

	if (isSuperAdmin) {
		return true;
	}

	const permissions = state.roles.get('currentUserPermissions').get('items');

	return permissions.some((item) => item.name === perm);
};

/**
 * Super Admin
 */
export const isSuperAdmin = () => hasPerm('perm_superadmin_view');

/**
 * Users
 */
export const canViewUsers = () => hasPerm('perm_users_view');
export const canCreateUsers = () => hasPerm('perm_users_create');
export const canUpdateUsers = () => hasPerm('perm_users_update');
export const canDeleteUsers = () => hasPerm('perm_users_delete');
export const canExportUsers = () => hasPerm('perm_users_export');

/**
 * Roles
 */
export const canViewRoles = () => hasPerm('perm_roles_view');
export const canCreateRoles = () => hasPerm('perm_roles_create');
export const canUpdateRoles = () => hasPerm('perm_roles_update');
export const canDeleteRoles = () => hasPerm('perm_roles_delete');
export const canExportRoles = () => hasPerm('perm_roles_export');

/**
 * Company
 */
export const canUpdateCompanySettings = () => hasPerm('perm_company_settings_update');

/**
 * Help Center
 */
export const canViewHelpQuestions = () => hasPerm('perm_help_questions_view');
export const canCreateHelpQuestions = () => hasPerm('perm_help_questions_create');
export const canUpdateHelpQuestions = () => hasPerm('perm_help_questions_update');
export const canDeleteHelpQuestions = () => hasPerm('perm_help_questions_delete');
export const canExportHelpQuestions = () => hasPerm('perm_help_questions_export');

export const canViewHelpCategory = () => hasPerm('perm_help_category_view');
export const canCreateHelpCategory = () => hasPerm('perm_help_category_create');
export const canUpdateHelpCategory = () => hasPerm('perm_help_category_update');
export const canDeleteHelpCategory = () => hasPerm('perm_help_category_delete');
export const canExportHelpCategory = () => hasPerm('perm_help_category_export');

export const canViewPlanTemplate = () => hasPerm('perm_plantemplate_view');
export const canCreatePlanTemplate = () => hasPerm('perm_plantemplate_create');
export const canUpdatePlanTemplate = () => hasPerm('perm_plantemplate_update');
export const canDeletePlanTemplate = () => hasPerm('perm_plantemplate_delete');
export const canExportPlanTemplate = () => hasPerm('perm_plantemplate_export');

export const canViewManageTemplate = () => hasPerm('perm_manage_template_view');
export const canCreateManageTemplate = () => hasPerm('perm_manage_template_create');
export const canUpdateManageTemplate = () => hasPerm('perm_manage_template_update');
export const canDeleteManageTemplate = () => hasPerm('perm_manage_template_delete');
export const canExportManageTemplate = () => hasPerm('perm_manage_template_export');

export const canViewHelpCenter = () => hasPerm('perm_help_center_view');
export const canCreateHelpCenter = () => hasPerm('perm_help_center_create');
export const canUpdateHelpCenter = () => hasPerm('perm_help_center_update');
export const canDeleteHelpCenter = () => hasPerm('perm_help_center_delete');
export const canExportHelpCenter = () => hasPerm('perm_help_center_export');

/**
 * Library management
 */
export const canViewLibraryManagement = () => hasPerm('perm_library_management_view');
export const canCreateLibraryManagement = () => hasPerm('perm_library_management_create');
export const canUpdateLibraryManagement = () => hasPerm('perm_library_management_update');
export const canDeleteLibraryManagement = () => hasPerm('perm_library_management_delete');
export const canExportLibraryManagement = () => hasPerm('perm_library_management_export');

/**
 * Login history
 */
export const canViewLoginHistory = () => hasPerm('perm_login_history_view');
export const canDeleteLoginHistory = () => hasPerm('perm_login_history_delete');
export const canExportLoginHistory = () => hasPerm('perm_login_history_export');

/**
 * Risk Assessment
 */
export const canViewRiskAssessment = () => hasPerm('perm_risk_assessment_view');
export const canCreateRiskAssessment = () => hasPerm('perm_risk_assessment_create');
export const canUpdateRiskAssessment = () => hasPerm('perm_risk_assessment_update');
export const canDeleteRiskAssessment = () => hasPerm('perm_risk_assessment_delete');
export const canExportRiskAssessment = () => hasPerm('perm_risk_assessment_export');

/**
 * Risk Assessment
 */
export const canViewReports = () => hasPerm('perm_reports_view');

/**
 * Tasks
 */
export const canViewTask = () => hasPerm('perm_task_view');
export const canCreateTask = () => hasPerm('perm_task_create');
export const canUpdateTask = () => hasPerm('perm_task_update');
export const canDeleteTask = () => hasPerm('perm_task_delete');
export const canExportTask = () => hasPerm('perm_task_export');

/**
 * Threat
 */
export const canViewThreat = () => hasPerm('perm_threat_view');
export const canCreateThreat = () => hasPerm('perm_threat_create');
export const canUpdateThreat = () => hasPerm('perm_threat_update');
export const canDeleteThreat = () => hasPerm('perm_threat_delete');
export const canExportThreat = () => hasPerm('perm_threat_export');

/**
 * Manage Approvals
 */
export const canManageApprovals = () => hasPerm('perm_manage_approval_view');
