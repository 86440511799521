import _ from 'lodash';
import createAction from '../utils/createAction';
import internalApi from '../utils/internalApi';
import { clearAlerts } from './alerts';
import { arrayMove } from '../utils/arrayHelpers';
import storage from '../utils/storage';

export const FETCH_TODO_LOADED = 'FETCH_TODO_LOADED';
export const FETCH_TODO = 'FETCH_TODO';
export const FETCH_CHECKLIST_LOADED = 'FETCH_CHECKLIST_LOADED';
export const FETCH_CHECKLIST = 'FETCH_CHECKLIST';
export const SET_TODO_FIELDS = 'SET_TODO_FIELDS';

const sortChecklists = (dragIndex, hoverIndex) => (dispatch, getState) => {
	const state = getState();

	const sorted = arrayMove(state.todo.get('checklist').get('items').toJS(), dragIndex, hoverIndex);

	return internalApi.post('todo/checklist/sort', { checklist: sorted.map((i) => i.id) }).then(() => {
		dispatch(createAction(FETCH_CHECKLIST, sorted));
	});
};

const fetchTodo =
	({ order = {}, limit, offset, title } = {}) =>
	(dispatch) => {
		dispatch(createAction(FETCH_TODO_LOADED));

		const queryParams = {
			orderBy: order.key,
			sortBy: order.in,
			limit,
			offset,
			title,
		};

		return internalApi.get('todo/list', { params: queryParams }).then((res) => {
			dispatch(createAction(FETCH_TODO, res.payload.items));
		});
	};

const fetchTodoById = (id) => (dispatch) => {
	dispatch(clearAlerts());

	return internalApi.get(`todo/one/${id}`);
};

const doneCount = (id) => (dispatch) => {
	dispatch(clearAlerts());

	return internalApi.get(`todo/checklist/count/${id}`);
};

const fetchCheckList = (id) => (dispatch) => {
	dispatch(createAction(FETCH_CHECKLIST_LOADED));

	return internalApi.get(`todo/checklist/${id}`).then((res) => {
		dispatch(createAction(FETCH_CHECKLIST, res.payload.items));
	});
};

const fetchCheckListById = (id) => (dispatch) => {
	dispatch(clearAlerts());

	return internalApi.get(`todo/checklist/one/${id}`);
};

const saveTodo = (data) => (dispatch) => {
	dispatch(clearAlerts());

	return internalApi.post(data.id ? `todo/update/${data.id}` : `todo/create`, _.omit(data, ['id', 'search']));
};

const saveCheckList = (data) => (dispatch) => {
	dispatch(clearAlerts());

	return internalApi.post(data.id ? `todo/checklist/update/${data.id}` : `todo/checklist/create`, _.omit(data, ['name', 'id', 'todoStatus']));
};

const deleteTodo = (ids) => () =>
	internalApi.post('todo/delete-multiple', {
		ids,
	});

const deleteCheckList = (ids) => (dispatch) => {
	dispatch(clearAlerts());

	return internalApi.post('todo/checklist/delete-multiple', {
		ids,
	});
};

const deleteCheckListByTodoId = (ids) => (dispatch) => {
	dispatch(clearAlerts());

	return internalApi.post('todo/checklist/delete-by-todo-id', {
		todoId: ids,
	});
};

const onColumnToggleTodo = (fieldId, value) => (dispatch, getState) => {
	const state = getState();
	const fields = state.todo.get('fields');

	const newFields = fields.map((field) => {
		if (field.id === fieldId) {
			return { ...field, hidden: !value };
		}
		return { ...field };
	});

	storage.set('todoFields', newFields);

	dispatch(createAction(SET_TODO_FIELDS, newFields));
};

export {
	fetchTodo,
	fetchTodoById,
	saveTodo,
	deleteTodo,
	fetchCheckList,
	saveCheckList,
	deleteCheckList,
	sortChecklists,
	fetchCheckListById,
	doneCount,
	deleteCheckListByTodoId,
	onColumnToggleTodo,
};
