import React, { Fragment, memo, useEffect, useState } from 'react';
// import { connect } from 'react-redux';
import qs from 'query-string';
import { Switch, Route, BrowserRouter } from 'react-router-dom';
import { PrivateRoute } from '../../components/Routing';
import { Loading } from './../../components';

import App from '../app';
import SignIn from '../auth';
import Register from '../auth/register';
import ForgotPassword from '../auth/forgot-password';
import ResetPassword from '../auth/reset-password';
import LogoutPage from '../auth/logout';
import AutoLoginByToken from '../auth/auto-login-by-token';
import { useSelector, useDispatch } from 'react-redux';
import storage from '../../utils/storage';
import { timeBasedSignIn } from '../../actions/auth';
import createAction from '../../utils/createAction';
import { AUTHENTICATE } from '../../actions/auth';

const MainRoutes = (props) => {
	const token = useSelector((state) => state?.auth?.get?.('token'));
	const dispatch = useDispatch();
	const [isCheckingDone, setIsCheckingDone] = useState(false);
	// return (visible) => dispatch(setAction({isSidebarOpen:visible}));
	const companyInQuery = qs.parse(top?.location?.search)?.company;
	const finalToken = token ?? storage?.get?.('token');

	const done = () => {
		if (!isCheckingDone) {
			// timeout of 1 sec is added to give time for session to be loaded before we check again.
			setTimeout(() => {
				setIsCheckingDone(true);
			},2000);
		}
	}

	useEffect(() => {
		const url = top?.location?.pathname;

		if(companyInQuery && isCheckingDone){
			storage.set('companyAlias', companyInQuery);
		
			if(url){
				const whitelistDomains = /^\/([^\/]+)\/(register|login|forgot-password|reset-password|logout|token)$/;
				const match = url.match(whitelistDomains);
				// Url is part of private link.
				if(!match && !finalToken){
					localStorage?.setItem?.("fromUrl",JSON.stringify(url));
					top.location = `/${companyInQuery}`;
					return;
				}
			}
		}

		if (!token && storage.get('token')) {
			dispatch(createAction(AUTHENTICATE, { token: storage.get('token') }));
		}		
	}, [finalToken,token,isCheckingDone]);

	useEffect(() => {

		// transfers sessionStorage from one tab to another
		var sessionStorage_transfer = function (event) {
			if (!event) { event = window.event; } // ie suq
			if (!event.newValue) { return; };          // do nothing if no value to work with
			if (event.key == 'getSessionStorage') {
				// another tab asked for the sessionStorage -> send it
				localStorage.setItem('sessionStorage', JSON.stringify(sessionStorage));
				// the other tab should now have it, so we're done with it.
				localStorage.removeItem('sessionStorage'); // <- could do short timeout as well.
			} else if (event.key == 'sessionStorage' && !sessionStorage.length) {
				// another tab sent data <- get it
				try {
					var data = JSON.parse(event.newValue);
					if (data && typeof data === 'object') {
						for (var key in data) {
							sessionStorage.setItem(key, data[key]);
						}
					}
				} catch (e) {
					console.log('error->', e);
				}
			}

			if (!token && storage.get('token')) {
				// auth.loadUserFromSession();
				dispatch(createAction(AUTHENTICATE, { token: storage.get('token') }));
			}
		};

		// timeBasedSignIn(tokenInQuery).then(out => {
		// 	let item = out?.payload?.item;
		// 	if(item?.companyAlias && item?.token){
		// 		// dispatch(clearAlerts());
		// 		storage.set('companyAlias', item?.companyAlias);
		// 		storage.set('token', item?.token);
		// 		dispatch(createAction(AUTHENTICATE, { token: item?.token }));
		// 		top?.location?.replace?.(top?.location?.pathname);
		// 	}
		// 	done();
		// }).catch(e => {
		// 	done();
		// });

			// listen for changes to localStorage
			if (window.addEventListener) {
				window.addEventListener("storage", sessionStorage_transfer, false);
			}

			// Ask other tabs for session storage (this is ONLY to trigger event)
			if (!sessionStorage.length) {
				localStorage.setItem('getSessionStorage', 'init');
				localStorage.removeItem('getSessionStorage');
			};
			done();

	}, []);

	if(!isCheckingDone){
		return <Loading fixed={false} size="sm" type="ball-pulse-sync" />;
	}

	return (
		<BrowserRouter>
			<Switch>
				{!token && (
					<Fragment>
						<Route path="/" exact component={SignIn} />
						<Route path="/:companyAlias/register" exact component={Register} />
						<Route path="/:companyAlias/forgot-password" exact component={ForgotPassword} />
						<Route path="/:companyAlias/reset-password" exact component={ResetPassword} />
						<Route path="/:companyAlias/logout" exact component={LogoutPage} />
						<Route path="/:companyAlias/token" exact component={AutoLoginByToken} />
						<Route path="/:companyAlias" exact component={SignIn} />
					</Fragment>
				)}
				{token && <PrivateRoute component={App} />}
			</Switch>
		</BrowserRouter>
	);
};

export default memo(MainRoutes);
