import createAction from '../utils/createAction';
import internalApi from '../utils/internalApi';
import { clearAlerts } from './alerts';

export const FETCH_GROUPS_LOADED = 'FETCH_GROUPS_LOADED';
export const FETCH_GROUPS = 'FETCH_GROUPS';

const fetchGroups =
	({ search = '', page = 1, limit = 1000, parentId = null } = {}) =>
	(dispatch) => {
		dispatch(createAction(FETCH_GROUPS_LOADED));
		const queryParams = {
			search,
			page,
			limit,
			parentId,
		};
		internalApi.get('group/list', { params: queryParams }).then((res) => {
			dispatch(createAction(FETCH_GROUPS, res.payload.items));
		});
	};

const getGroups =
	({ search = '', page = 1, limit = 50, parentId = null } = {}) =>
	(dispatch) => {
		const queryParams = {
			search,
			page,
			limit,
			parentId,
		};
		return internalApi.get('group/list', { params: queryParams });
	};

const getGroupById = (id) => () => internalApi.get(`group/one/${id}`);

const saveGroup = (data) => (dispatch) => {
	dispatch(clearAlerts());

	return internalApi.post(data.id ? `group/update/${data.id}` : `group/create`, data);
};

const deleteGroups = (ids) => () =>
	internalApi.post('group/delete-multiple', {
		ids,
	});

const deleteGroup = (id) => () => internalApi.post(`group/delete/${id}`);

export { fetchGroups, getGroups, getGroupById, saveGroup, deleteGroups, deleteGroup };
