import React, { memo, useEffect, useState } from 'react';
import { getRecordsForLookup } from '../../../../actions/library';
import { parseLookupKeys } from '../../../../utils/common';
import internalApi from '../../../../utils/internalApi';

const LookupItem = ({ field, value }) => {
	let [_val, setVal] = useState('');

	useEffect(() => {
		// if(field.)
		if (field?.type === 'libraryLookup' && value) {
			if (field.libraryId === 'users') {
				let keys = parseLookupKeys(value);

				internalApi.get('user/list', { params: { search: keys } }).then((res) => {
					if (res.payload && res.payload.items && res.payload.items.records && Array.isArray(res.payload.items.records)) {
						let users = res.payload.items.records.map((u) => {
							const label = u.fullName;
							return label;
						});
						setVal(users.join(', '));
					}
				});
			} else {
				if (field.fieldId) {
					let keys = parseLookupKeys(value);
					getRecordsForLookup({ libraryId: field.libraryId, lookupFields: field.fieldId.split(','), selectedItems: keys }).then((res) => {
						// console.log("res.payload.items-> ",res);
						if (res && res.payload && res.payload.items && res.payload.items[0] && res.payload.items[0].label) {
							setVal(res.payload.items[0].label);
						} else {
							// if no results came back it means it has been deleted so make it empty
							setVal('');
						}
						// return resolve(res.payload.items);
					});
				} else {
					setVal(value);
				}
			}
		} else {
			setVal(value);
		}
	}, [value, field]);

	return <React.Fragment>{_val}</React.Fragment>;
};

export default memo(LookupItem);
