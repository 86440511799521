import React from 'react';

import toast from '../../utils/toast';
import storage from '../../utils/storage';

import { Link } from 'react-router-dom';
import FormView from '../../lib/FormView';
import { Input, Group, Button } from '../../components/Form';
import { Flex, Col, Padding, ToastContainer, Image, Text } from '../../components';

import imgLogo from '../../assets/images/logo.png';

class LogoutPage extends FormView {
	state = {
		errors: {},
	};

	static getDerivedStateFromProps(props) {
		storage.set('companyAlias', props.match.params.companyAlias);
		return {};
	}

	render() {
		return (
			<Flex xs={{ justify: 'center', align: 'center', height: '100vh' }}>
				<Col xs={{ size: 3 }}>
					<ToastContainer />
					<Flex xs={{ align: 'center', justify: 'center' }}>
						<Image src={imgLogo} />
					</Flex>
					<Padding padding={{ top: 15 }}>
						<Flex xs={{ align: 'center', justify: 'center' }}>
							<Text fontSize={16} align="center" color="#0052CC">
								You have been successfully logged out.
							</Text>
						</Flex>
						<Flex xs={{ align: 'center', justify: 'center' }}>
							<Link to={`/${this.props.match.params.companyAlias || storage.get('companyAlias')}`}>
								<Text fontSize={16} align="center" color="#0052CC">
									Back to Login
								</Text>
							</Link>
						</Flex>
					</Padding>
				</Col>
			</Flex>
		);
	}
}

export default LogoutPage;
