import React from 'react';
import PropTypes from 'prop-types';

import cs from './component.module.scss';

const THead = (props) => <thead className={cs.root}>{props.children}</thead>;

THead.propTypes = {
	children: PropTypes.node.isRequired,
};

THead.defaultProps = {};

export default THead;
